import simpleColorConverter from 'simple-color-converter';
import palette from '../styles/palette';

export const formatBytes = (bytes) => {
	if (bytes >= 1000000000) {
		return (bytes / 1000000000).toFixed(2) + ' GB';
	} else if (bytes >= 1000000) {
		return (bytes / 1000000).toFixed(2) + ' MB';
	} else if (bytes >= 1000) {
		return (bytes / 1000).toFixed(2) + ' KB';
	} else {
		return bytes + ' Bytes';
	}
};

export const colorConvert = (color, from, output) => {
	return new simpleColorConverter({ [`${from}`]: color, to: output })?.color;
};

export const getLatestDate = (date1, date2) => {
	const parsedDate1 = new Date(date1);
	const parsedDate2 = new Date(date2);

	// Check if either date is invalid
	if (isNaN(parsedDate1.getTime())) {
		throw new Error('Invalid date format for date1');
	}
	if (isNaN(parsedDate2.getTime())) {
		throw new Error('Invalid date format for date2');
	}

	// Compare the dates
	if (parsedDate1 > parsedDate2) {
		return parsedDate1.toLocaleDateString('he-IL');
	} else {
		return parsedDate2.toLocaleDateString('he-IL');
	}
};

export const areFilesEqual = (fileA, fileB) => {
	// Check if both objects are defined
	if (!fileA || !fileB) return false;

	// Compare relevant properties
	return (
		fileA.name === fileB.name &&
		fileA.size === fileB.size &&
		fileA.type === fileB.type &&
		fileA.lastModified === fileB.lastModified
	);
};

export const getTotalFilesInFolder = (folder) => {
	return (
		folder.Asset.filter((asset) => !asset.hiddenFile).length +
		(folder?.children
			? folder?.children.reduce(
					(prev, child) =>
						prev +
						(child.Asset?.filter((asset) => !asset.hiddenFile)
							.length || 0),
					0,
			  )
			: 0)
	);
};

// Function to convert hex to RGB
function hexToRgb(hex) {
	let r = 0,
		g = 0,
		b = 0;
	if (hex.length === 4) {
		r = parseInt(hex[1] + hex[1], 16);
		g = parseInt(hex[2] + hex[2], 16);
		b = parseInt(hex[3] + hex[3], 16);
	} else if (hex.length === 7) {
		r = parseInt(hex.slice(1, 3), 16);
		g = parseInt(hex.slice(3, 5), 16);
		b = parseInt(hex.slice(5, 7), 16);
	}
	return [r, g, b];
}

// Function to calculate luminance
function luminance(r, g, b) {
	const a = [r / 255, g / 255, b / 255].map(function (v) {
		return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
	});
	return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

// Function to calculate contrast ratio
function contrastRatio(hex1, hex2) {
	const rgb1 = hexToRgb(hex1);
	const rgb2 = hexToRgb(hex2);
	const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
	const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
	const brightest = Math.max(lum1, lum2);
	const darkest = Math.min(lum1, lum2);
	return (brightest + 0.05) / (darkest + 0.05);
}

export const getTextColor = (bgHex) => {
	console.log(bgHex);
	try {
		const darkText = '#4a4a4a';
		const lightText = '#fff';
		const darkContrast = contrastRatio(darkText, bgHex);
		const lightContrast = contrastRatio(lightText, bgHex);

		return darkContrast > lightContrast ? darkText : lightText;
	} catch (error) {
		return null;
	}
};
