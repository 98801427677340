import {
	Box,
	Grid,
	IconButton,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { StyledAdminSearch } from '../../styles/inputs';
import { useUser } from '../../contexts/UserContext';
import palette from '../../styles/palette';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomAvatar from '../Cards/CustomAvatar';
import { GLOBAL_URL } from '../../api/global';
import { useRef, useState } from 'react';
import UserDrawer from '../Drawers/UserDrawer';
import { useDataContext } from '../../contexts/DataContext';
import SearchResults from '../Lists/SearchResults';
import useClickOutside from '../../hooks/useClickOutside';

const AdminHeader = () => {
	const [searchQuery, setSearchQuery] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const { setCurrentItem, setIsEditing } = useDataContext();
	const [showSearchResults, setShowSearchResults] = useState(false);
	const searchResultsRef = useRef(null);

	useClickOutside(searchResultsRef, () => {
		setSearchQuery('');
		searchResultsRef && setShowSearchResults(false);
	});
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { user, logout } = useUser();

	return (
		<>
			<Grid
				container
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<Box position={'relative'}>
					<StyledAdminSearch
						id='admin-search'
						startAdornment={
							<InputAdornment position='start'>
								<SearchOutlinedIcon />
							</InputAdornment>
						}
						placeholder='Search...'
						value={searchQuery}
						onChange={({ target: { value } }) => {
							if (value?.length < 3) setShowSearchResults(false);
							if (value?.length >= 3) setShowSearchResults(true);
							setSearchQuery(value);
						}}
					/>
					{showSearchResults && (
						<Box
							ref={searchResultsRef}
							position={'absolute'}
							bgcolor={'background.paper'}
							p={2}
							mt={1}
							borderRadius={6}
							width={'200%'}
							zIndex={11}
							sx={{ boxShadow: '0 3px 6px #0000000f' }}
						>
							<SearchResults
								query={searchQuery}
								setQuery={setSearchQuery}
							/>
						</Box>
					)}
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<CustomAvatar
						name={user?.name || 'Admin'}
						image={GLOBAL_URL + user?.image}
						size={56}
						sx={{ border: `4px solid ${palette.background.paper}` }}
					/>
					<IconButton
						aria-label='Profile actions'
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id='profile-menu'
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						elevation={0}
						sx={{
							'& .MuiPaper-root': {
								boxShadow: '0 3px 6px #25232342',
								borderRadius: '16px',
							},
						}}
						anchorOrigin={{
							vertical: 50,
							horizontal: 30,
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuItem
							onClick={() => {
								setIsEditing(true);
								setCurrentItem(user);
								setIsDrawerOpen(true);
								handleClose();
							}}
						>
							<ListItemIcon>
								<ModeEditOutlineOutlinedIcon fontSize='small' />
							</ListItemIcon>
							<ListItemText>Edit profile</ListItemText>
						</MenuItem>
						<MenuItem
							onClick={() => {
								logout();
								handleClose();
							}}
						>
							<ListItemIcon>
								<LogoutOutlinedIcon fontSize='small' />
							</ListItemIcon>
							<ListItemText>Logout</ListItemText>
						</MenuItem>
					</Menu>
				</Box>
			</Grid>
			{isDrawerOpen && (
				<UserDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
			)}
		</>
	);
};

export default AdminHeader;
