import { GLOBAL_URL } from '../api/global';

export const GENERAL_LINK_BRANDBOOK = '/brandbook/';

export const validateForm = (currentItem, isCreating) => {
	const { email, name, password } = currentItem;
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	if (!email || !name || (!password && isCreating))
		return {
			isValidated: false,
			message: 'Please fill out all of the above fields.',
		};
	if (!emailRegex.test(email))
		return {
			isValidated: false,
			message: 'Please enter a valid email address.',
		};
	return {
		isValidated: true,
		message: '',
	};
};

export const compareFields = (currentItem, original) => {
	const changedFields = {};

	Object.keys(currentItem).forEach((key) => {
		if (key in original) {
			if (
				Array.isArray(currentItem[key]) &&
				Array.isArray(original[key])
			) {
				if (!arraysEqual(currentItem[key], original[key])) {
					changedFields[key] = currentItem[key];
				}
			} else if (currentItem[key] !== original[key]) {
				changedFields[key] = currentItem[key];
			}
		}
	});

	return changedFields;
};

const arraysEqual = (arr1, arr2) => {
	if (arr1.length !== arr2.length) return false;
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}
	return true;
};

export const capitalizeFirstLetter = (str) => {
	if (str.length === 0) return ''; // Return empty string if input is empty

	// Convert the entire string to lowercase, then capitalize the first letter
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const isValidURL = (url) => {
	// Regular expression pattern for URL validation
	const urlPattern =
		/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,})([/\w.-]*)*\/?$/;

	// Test if the URL matches the pattern
	return urlPattern.test(url);
};

export const isValidImageURL = (url) => {
	// Regular expression pattern for validating image URLs
	const imagePattern = /\.(jpeg|jpg|gif|png|svg)$/i;

	// Test if the URL matches the image pattern
	return imagePattern.test(url);
};

const formatLink = (link) => {
	return link
		.replace('www.dropbox.com', 'dl.dropboxusercontent.com')
		.replace('?dl=0', '?raw=1');
};

export const getImageSource = (asset) => {
	if (asset?.externalLink) {
		return {
			srcSet: formatLink(asset.externalLink),
			src: formatLink(asset.externalLink),
		};
	}

	const imageUrl = GLOBAL_URL + asset?.localPath;

	if (isValidImageURL(imageUrl)) {
		return {
			srcSet: imageUrl,
			src: imageUrl,
		};
	}

	if (!asset)
		return {
			srcSet: '',
			src: '',
		};
	return null;
};

export const getFileExtension = (filename) => {
	const match = filename?.match(/\.([^.]+)$/);
	return match ? match[1] : '';
};

export const isImageFile = (file) => {
	const imageMimeTypePattern = /^image\/.*/;
	return file && imageMimeTypePattern.test(file.type);
};
