import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '../../styles/palette';
import {
	formatSpacingValues,
	renderComponent,
	renderDisplayComponent,
} from '../../utils/fields';

const ColumnsDisplay = ({ field }) => {
	const renderColumns = () => {
		return Array.from({ length: field?.total }, (_, key) => {
			return (
				<Grid item key={key} xs={+field?.width[key]}>
					{renderDisplayComponent(
						field.value[key],
						field.value[key]?.type,
					)}
				</Grid>
			);
		});
	};

	return (
		<Grid
			container
			sx={{
				// minHeight: "30vh",
				margin: formatSpacingValues(field?.margin),
				padding: formatSpacingValues(field?.padding),
			}}
			wrap='nowrap'
			alignItems={'start'}
			gap={3}
		>
			{renderColumns().map((col, index) => col)}
		</Grid>
	);
};
ColumnsDisplay.propTypes = {
	field: PropTypes.object,
};

export default ColumnsDisplay;
