import axios from 'axios';
import {
	fetchDelete,
	fetchGet,
	fetchPost,
	fetchPostFiles,
	fetchPut,
	GLOBAL_URL,
} from './global';

export const uploadAssetsBrand = async (data) => {
	return await fetchPost(
		`${GLOBAL_URL}assets/uploadAssetsBrand`,
		data,
		'multipart/form-data',
	);
};
export const createColorAsset = async (data) => {
	return await fetchPost(`${GLOBAL_URL}assets/createColorAsset`, data);
};

export const getAssets = async (folderId) => {
	return await fetchGet(`${GLOBAL_URL}assets/getAssets?folder=${folderId}`);
};
export const updateAsset = async (data) => {
	return await fetchPut(`${GLOBAL_URL}assets/updateAsset`, data);
};
export const createAsset = async (data) => {
	return await fetchPost(`${GLOBAL_URL}assets/createAsset`, data);
};
export const createRelatedFile = async (data) => {
	return await fetchPost(
		`${GLOBAL_URL}assets/createRelatedFile`,
		data,
		'multipart/form-data',
	);
};
export const createThumbnail = async (data) => {
	return await fetchPost(
		`${GLOBAL_URL}assets/createThumbnail`,
		data,
		'multipart/form-data',
	);
};
export const uploadAssetFile = async (data) => {
	return await fetchPost(
		`${GLOBAL_URL}assets/uploadAssetFile`,
		data,
		'multipart/form-data',
	);
};
export const uploadFilesWithProgress = async (data, onUploadProgress) => {
	return await fetchPostFiles(
		`${GLOBAL_URL}assets/uploadAssetFile`,
		data,
		onUploadProgress,
	);
};
export const deleteAssets = async (data) => {
	return await fetchDelete(`${GLOBAL_URL}assets/deleteAssets`, data);
};

export const downloadFile = async (data) => {
	return await fetchPost(`${GLOBAL_URL}dbx/downloadFile`, data);
};

export const getImageProxy = async (url) => {
	return await axios.get(`${GLOBAL_URL}assets/getImageProxy?url=${url}`, {});
};
export const uploadFileToDbx = async (data) => {
	return await fetchPost(`${GLOBAL_URL}assets/uploadFileToDbx?`, data);
};
//FOLDERS
export const getFolders = async (data) => {
	return await fetchGet(`${GLOBAL_URL}folders/getFolders`);
};
export const createFolder = async (data) => {
	return await fetchPost(`${GLOBAL_URL}folders/createFolder`, data);
};
export const deleteFolder = async (data) => {
	return await fetchDelete(`${GLOBAL_URL}folders/deleteFolder`, data);
};
export const getFoldersByBrand = async (brandId, search) => {
	return await fetchGet(
		`${GLOBAL_URL}folders/getFoldersByBrand?brandId=${brandId}&search=${search}`,
	);
};

//CATEGORIES
export const getCategories = async (data) => {
	return await fetchGet(`${GLOBAL_URL}categories/getCategories`);
};

export const createCategory = async (data) => {
	return await fetchPost(`${GLOBAL_URL}categories/createCategory`, data);
};
