import { useQuery } from '@tanstack/react-query';

import { getAssets } from '../../files';
import { useEffect } from 'react';

const useGetFiles = (folderId) => {
	const QUERY_KEY = ['getFiles', folderId];

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getAssets(folderId),
		enabled: folderId !== null && folderId !== undefined,
	});

	useEffect(() => {
		// Watch for changes in folderId and refetch data if it changes
		if (folderId !== null && folderId !== undefined) {
			refetch();
		}
	}, [folderId, refetch]);

	return { data, isLoading, isError, error, refetch };
};

export default useGetFiles;
