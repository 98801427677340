import PropTypes from 'prop-types';
import { Button, FormControl, InputBase, InputLabel } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
const CustomInput = ({
	value,
	defaultValue,
	label,
	icon,
	action,
	id,
	mt,
	inputType,
	multiline,
	rows,
	name,
}) => {
	const [isPassShowing, setIsPassShowing] = useState(false);

	return (
		<FormControl variant='standard' fullWidth sx={{ mt }}>
			{label && <InputLabel htmlFor={id}>{label}</InputLabel>}
			{inputType == 'password' && (
				<Button
					disableRipple
					variant='text'
					onClick={() => {
						setIsPassShowing(!isPassShowing);
					}}
					startIcon={
						isPassShowing ? (
							<VisibilityOffOutlinedIcon />
						) : (
							<VisibilityOutlinedIcon />
						)
					}
					sx={{
						position: 'absolute',
						right: 0,
						top: -6,
					}}
				>
					{isPassShowing ? 'Hide' : 'Show'}
				</Button>
			)}
			<InputBase
				defaultValue={defaultValue}
				id={id}
				type={isPassShowing ? 'text' : inputType}
				multiline={multiline}
				rows={rows}
				name={name}
				value={value}
				onChange={action}
			/>
		</FormControl>
	);
};

CustomInput.propTypes = {
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	label: PropTypes.string,
	icon: PropTypes.element,
	action: PropTypes.func,
	id: PropTypes.string,
	mt: PropTypes.number,
	inputType: PropTypes.string,
	multiline: PropTypes.bool,
	rows: PropTypes.number,
	name: PropTypes.string.isRequired,
};

export default CustomInput;
