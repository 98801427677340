import { Input, InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PropTypes from 'prop-types';
import { StyledSimpleInput } from '../../styles/inputs';

const DashboardSearch = ({ id, placeholder, searchAction, sx }) => {
	return (
		<StyledSimpleInput
			id={id}
			startAdornment={
				<InputAdornment position='start'>
					<SearchOutlinedIcon />
				</InputAdornment>
			}
			placeholder={placeholder}
			sx={{
				...sx,
				borderColor: 'transparent',
				bgcolor: 'background.default',
				borderRadius: '100vw',
				'&:before': { content: 'unset' },
				'&:after': { content: 'unset' },
				px: 2,
				minHeight: '48px',
			}}
			onChange={searchAction}
		/>
	);
};
DashboardSearch.propTypes = {
	id: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	searchAction: PropTypes.func.isRequired,
	sx: PropTypes.object,
};
export default DashboardSearch;
