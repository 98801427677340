import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as Logo } from '../../assets/images/icon.svg';
import { circleBox } from '../../styles/layouts';
import { Link, Outlet } from 'react-router-dom';
import { useState } from 'react';
import AdminNavBar from '../NavBars/AdminNavBar';
import AdminHeader from '../Headers/AdminHeader';

const DashboardLayout = ({ children }) => {
	const COL_SIZES = {
		NAV: 1,
		BODY: 11,
	};

	return (
		<Grid
			container
			spacing={2}
			sx={{ minHeight: '100vh', p: 4 }}
			flexWrap={'nowrap'}
		>
			<Grid
				item
				xs={COL_SIZES.NAV}
				alignItems={'center'}
				sx={{
					flexDirection: 'column',
					transition: 'all 0.2s ease',
					position: 'sticky',
					height: ' max-content',
					top: '16px',
				}}
			>
				<Box
					sx={{
						...circleBox,
						background: '#000',
						width: '120px',
						mb: 4,
					}}
				>
					<Link to={'/'}>
						<Logo
							style={{
								width: '100%',
								marginInlineStart: '8px',
								filter: 'invert(1)',
							}}
						/>
					</Link>
				</Box>
				<AdminNavBar />
			</Grid>
			<Grid
				item
				xs={COL_SIZES.BODY}
				sx={{
					flexDirection: 'column',
				}}
			>
				<AdminHeader />
				<Outlet />
			</Grid>
		</Grid>
	);
};
DashboardLayout.propTypes = {
	children: PropTypes.element.isRequired,
};
export default DashboardLayout;
