import { useMutation } from '@tanstack/react-query';
import { sendHelpEmail } from '../../users';

import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

const useSendHelp = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleSendHelp, isPending } = useMutation({
		mutationFn: ({ clientEmail, emailMsg, fromDetails }) =>
			sendHelpEmail({ clientEmail, emailMsg, fromDetails }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleSendHelp, isPending };
};

export default useSendHelp;
