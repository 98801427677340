import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { formatBytes } from '../../utils/calculations';

const AssetDetails = ({ asset }) => {
	function createData(name, value) {
		return { name, value };
	}

	const rows = [
		createData('File size', formatBytes(asset?.size)),

		createData(
			'Upload date',
			new Date(asset?.createdAt).toLocaleDateString('he-IL'),
		),
	];

	if (asset?.height || asset?.width) {
		rows.push(
			asset?.width && createData('Width', asset?.width + ' px'),
			asset?.height && createData('Height', asset?.height + ' px'),
		);
	}
	return (
		<Table>
			<TableBody>
				{rows.map((row) => (
					<TableRow key={row?.name}>
						<TableCell
							component='th'
							scope='row'
							sx={{ padding: '6px' }}
						>
							{row?.name}
						</TableCell>
						<TableCell
							style={{ width: 160 }}
							align='right'
							sx={{ padding: '6px' }}
						>
							{row?.value}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

AssetDetails.propTypes = {
	asset: PropTypes.object.isRequired,
};
export default AssetDetails;
