import { useMutation } from '@tanstack/react-query';

import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { uploadAssetsBrand } from '../../files';

const useUploadAssetsBrand = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const {
		mutate: handleUploadFiles,
		isPending,
		isError,
	} = useMutation({
		mutationFn: (data) => uploadAssetsBrand(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleUploadFiles, isPending, isError };
};

export default useUploadAssetsBrand;
