import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Icon } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useIsMutating } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import BrandLoader from '../components/General/BrandLoader';
export const FORM_STEPS = {
	LOGIN: 'LOGIN',
	AUTH_CODE: 'AUTH_CODE',
	FORGOT_PASS: 'FORGOT_PASS',
	NEW_PASS: 'NEW_PASS',
	HELP: 'HELP',
};

const LoginContext = createContext({
	isLoading: false,
	currentStep: 'LOGIN',
	setIsLoading: () => {},
	setCurrentStep: () => {},
});

export const useLogin = () => useContext(LoginContext);

export const LoginProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState(FORM_STEPS.LOGIN);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [email, setEmail] = useState(null);
	const isMutating = useIsMutating();

	useEffect(() => {
		setIsLoading(!!isMutating);
	}, [isMutating]);

	useEffect(() => {
		if (Cookies.get('userEmail')) {
			setEmail(Cookies.get('userEmail'));
		}
	}, []);

	return (
		<LoginContext.Provider
			value={{
				isLoading,
				setIsLoading,
				currentStep,
				setCurrentStep,
				isEmailValid,
				setIsEmailValid,
				email,
				setEmail,
			}}
		>
			{isLoading ? (
				<BrandLoader />
			) : (
				<>
					{currentStep !== FORM_STEPS.LOGIN && (
						<ArrowBackIosIcon
							sx={{
								position: 'absolute',
								left: 20,
								top: 20,
								cursor: 'pointer',
							}}
							onClick={() => {
								Cookies.remove('userEmail');
								setCurrentStep(FORM_STEPS.LOGIN);
								setIsEmailValid(false);
								setEmail(null);
							}}
						/>
					)}
					{children}
				</>
			)}
		</LoginContext.Provider>
	);
};

LoginProvider.propTypes = {
	children: PropTypes.any.isRequired,
};
