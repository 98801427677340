import BasicDialog from '../Layouts/BasicDialog';
import PropTypes from 'prop-types';
import { useAlert } from '../../contexts/AlertContext';

import { Grid, Typography } from '@mui/material';

import ImageCropper from '../Inputs/ImageCropper';
import { useState } from 'react';
import { useFileContext } from '../../contexts/FilesContext';

const CropperDialog = ({ isOpen, setIsOpen, thumbnail, setThumbnail }) => {
	const { handleAlert } = useAlert();
	const [croppedArea, setCroppedArea] = useState({});
	const { setCurrentFile } = useFileContext();

	const handleClose = () => {
		setIsOpen(false);
	};

	const actions = [
		{
			actionHandler: handleClose,
			isMain: false,
		},
		{
			actionHandler: () => {
				setThumbnail((prev) => ({ ...prev, crop: croppedArea }));
				setCurrentFile((prev) => ({
					...prev,
					thumbnail: {
						file: thumbnail.file,
						crop: croppedArea,
					},
				}));
				handleClose();
			},
			isMain: true,
		},
	];

	return (
		<BasicDialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={`Crop Image`}
			actions={actions}
			// isError={isError}
			handleClose={handleClose}
			maxWidth={700}
		>
			<Grid
				container
				direction={'column'}
				gap={2}
				height={500}
				position={'relative'}
				mb={1}
			>
				<ImageCropper
					imageUrl={thumbnail?.asset}
					aspect={370 / 230}
					onCropComplete={(croppedArea, croppedAreaPixels) => {
						setCroppedArea(croppedAreaPixels);
					}}
				/>
			</Grid>
			<Typography>
				Aspect ration is set to 370/230 to match card dimensions in
				brand book.
			</Typography>
		</BasicDialog>
	);
};
CropperDialog.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	setThumbnail: PropTypes.func,
	thumbnail: PropTypes.object,
};
export default CropperDialog;
