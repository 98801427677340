import { useQuery } from '@tanstack/react-query';
import { verifyUser } from '../../users';

const QUERY_KEY = ['verifyUser'];

const useVerifyUser = () => {
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => verifyUser(),
		retry: false,
	});
};

export default useVerifyUser;
