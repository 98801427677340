import { useState } from 'react';
import useCreateCategory from '../../api/hooks/files/useCreateCategory';
import ContentEditable from '../Inputs/ContentEditable';
import BasicDialog from '../Layouts/BasicDialog';
import PropTypes from 'prop-types';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';

const CategoryDialog = ({ isOpen, setIsOpen, setAllChips }) => {
	const { handleAlert } = useAlert();
	const [catName, setCatName] = useState('');
	const handleClose = () => {
		setIsOpen(false);
		// setClient(null);
	};
	const { handleCreateCategory } = useCreateCategory((data) => {
		setAllChips((prev) => [...prev, data.data]);
		setIsOpen(false);
	});

	const actions = [
		{
			actionHandler: handleClose,
			isMain: false,
		},
		{
			actionHandler: () => {
				if (!catName)
					return handleAlert(
						ALERT_STATUS.ERROR,
						'Category must have a name.',
					);
				handleCreateCategory({ name: catName });
			},
			isMain: true,
		},
	];

	return (
		<BasicDialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={'Create Tag'}
			actions={actions}
			// isError={isError}
			handleClose={handleClose}
			maxWidth={400}
		>
			<ContentEditable
				type='text'
				label='Tag Name'
				canEdit={true}
				value={catName}
				setValue={({ target: { value } }) => {
					setCatName(value);
				}}
			/>
		</BasicDialog>
	);
};
CategoryDialog.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	setAllChips: PropTypes.func,
};
export default CategoryDialog;
