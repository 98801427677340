import { Box, Grid, Input, ToggleButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../../utils/globals';
import GroupToggleButtons from '../Inputs/GroupToggleButtons';
import SmallInput from '../Inputs/SmallInput';
import TextColorPicker from '../Inputs/TextColorPicker';
import SpacingInput from '../Inputs/SpacingInput';
import {
	percentageToColumns,
	populateDefaultFieldData,
} from '../../utils/fields';
import ReactQuill from 'react-quill';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FieldType, useFields } from '../../contexts/FieldsContext';
import { useState, useEffect } from 'react';

const toolbarOptions = [
	['bold', 'italic', 'underline'],
	['blockquote'],
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ header: [2, 3, false] }],
	[{ align: [] }],
];

const SideBarOptions = () => {
	const [sidebarOptions, setSidebarOptions] = useState({});
	const { currentField, setCurrentField } = useFields();

	const handleSpacingInputChange = (property, updatedValues) => {
		setCurrentField((prev) => ({ ...prev, [property]: updatedValues }));
	};

	useEffect(() => {
		currentField && setSidebarOptions(currentField);
	}, [currentField]);

	return currentField ? (
		<Grid container gap={2} direction={'column'}>
			<Typography variant='h6' fontSize={'95%'}>
				{capitalizeFirstLetter(currentField?.type || '')} Options
			</Typography>
			{sidebarOptions?.total && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Total columns
					</Typography>
					<GroupToggleButtons
						buttons={[2, 3, 4].map((v) => ({
							value: v,
							label: v + '',
						}))}
						value={currentField?.total}
						selectAction={(value) => {
							setCurrentField((prev) => ({
								...prev,
								total: value,
								width: Array(value).fill(
									percentageToColumns(
										Math.floor(100 / value),
									),
								),
								value: prev.value.slice(0, value),
							}));
						}}
					/>
				</Grid>
			)}
			{sidebarOptions?.fontSize && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Root Font Size
					</Typography>
					<GroupToggleButtons
						buttons={[14, 16, 18, 20].map((v) => ({
							value: v,
							label: v + '',
						}))}
						value={currentField?.fontSize}
						selectAction={(value) => {
							setCurrentField((prev) => ({
								...prev,
								fontSize: value,
							}));
						}}
					/>
				</Grid>
			)}
			{sidebarOptions?.imageWidth && (
				<Grid container gap={2} alignItems={'end'}>
					<Typography variant='p' fontSize={'95%'}>
						Width
					</Typography>
					<Grid
						container
						alignItems={'center'}
						wrap='nowrap'
						width={'fit-content'}
					>
						<SmallInput
							type='number'
							smallText={''}
							value={currentField?.imageWidth}
							onChange={({ target: { value } }) => {
								setCurrentField((prev) => ({
									...prev,
									imageWidth: value,
								}));
							}}
							maxWidth={70}
						/>
						%
					</Grid>
				</Grid>
			)}
			{Object.hasOwn(sidebarOptions, 'imageHeight') && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Height
					</Typography>
					<Grid
						container
						alignItems={'center'}
						wrap='nowrap'
						width={'fit-content'}
					>
						<SmallInput
							type='number'
							smallText={''}
							value={currentField?.imageHeight}
							onChange={({ target: { value } }) => {
								setCurrentField((prev) => ({
									...prev,
									imageHeight: value,
								}));
							}}
							maxWidth={70}
						/>
						%
					</Grid>
				</Grid>
			)}
			{sidebarOptions?.width && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Columns Width
					</Typography>
					<Box sx={{ maxWidth: '60%' }}>
						<SpacingInput
							values={currentField?.width || []}
							property={'width'}
							onChange={handleSpacingInputChange}
							marking={false}
						/>
					</Box>
				</Grid>
			)}
			{sidebarOptions?.variant && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Type
					</Typography>
					<GroupToggleButtons
						buttons={['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map(
							(v) => ({
								value: v,
							}),
						)}
						value={currentField?.variant}
						selectAction={(value) => {
							setCurrentField((prev) => ({
								...prev,
								variant: value,
							}));
						}}
					/>
				</Grid>
			)}
			{sidebarOptions?.fontWeight && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Weight
					</Typography>
					<GroupToggleButtons
						buttons={[300, 400, 500, 600, 700].map((fw) => ({
							value: fw,
							label: fw + '',
						}))}
						value={currentField?.fontWeight}
						selectAction={(value) => {
							setCurrentField((prev) => ({
								...prev,
								fontWeight: value,
							}));
						}}
					/>
				</Grid>
			)}
			{sidebarOptions?.margin && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Margin
					</Typography>
					<SpacingInput
						values={currentField?.margin || []}
						property={'margin'}
						onChange={handleSpacingInputChange}
						marking={['T', 'R', 'B', 'L']}
					/>
				</Grid>
			)}
			{sidebarOptions?.padding && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Padding
					</Typography>
					<SpacingInput
						values={currentField?.padding || []}
						property={'padding'}
						onChange={handleSpacingInputChange}
						marking={['T', 'R', 'B', 'L']}
					/>
				</Grid>
			)}
			{sidebarOptions?.borderRadius && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						borderRadius
					</Typography>
					<SpacingInput
						values={currentField?.borderRadius || []}
						property={'borderRadius'}
						onChange={handleSpacingInputChange}
						marking={['TL', 'TR', 'BR', 'BL']}
					/>
				</Grid>
			)}
			{sidebarOptions && Object.hasOwn(sidebarOptions, 'color') && (
				<TextColorPicker
					handleChange={(color) => {
						setCurrentField((prev) => ({
							...prev,
							color,
						}));
					}}
				/>
			)}
			{sidebarOptions?.hover && (
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='p' fontSize={'95%'}>
						Add Hover Text
					</Typography>

					<ToggleButton
						value='check'
						selected={currentField?.hasHover}
						onChange={() => {
							setCurrentField((prev) => ({
								...prev,
								hasHover: !prev?.hasHover,
								hover: {
									...prev.hover,
									text: prev?.asset?.excerpt,
								},
							}));
						}}
					>
						<EditOutlinedIcon />
					</ToggleButton>
					{currentField?.hasHover && (
						<Grid item xs={12}>
							<ReactQuill
								className='side-bar-hover'
								style={{ height: 100 }}
								theme='snow'
								value={currentField?.hover?.text}
								onChange={(value) => {
									setCurrentField((prev) => ({
										...prev,
										hover: {
											...prev.hover,
											text: value,
										},
									}));
								}}
								modules={{
									toolbar: toolbarOptions,
								}}
							/>
						</Grid>
					)}
				</Grid>
			)}
			{currentField?.type === FieldType.CARD && (
				<Grid
					container
					gap={2}
					alignItems={'center'}
					mt={currentField?.hasHover ? 2 : 0}
				>
					<Typography variant='p' fontSize={'95%'}>
						Brand Personality Card
					</Typography>

					<ToggleButton
						value={!currentField?.brandPersonality}
						selected={!!currentField?.brandPersonality}
						onChange={(e, checked) => {
							setCurrentField((prev) => ({
								...prev,
								brandPersonality: checked ? {} : null,
							}));
						}}
					>
						<EditOutlinedIcon />
					</ToggleButton>
					{!!currentField?.brandPersonality && (
						<Grid
							item
							xs={12}
							display={'flex'}
							direction={'column'}
							gap={2}
						>
							<Input
								value={currentField?.brandPersonality?.title}
								onChange={(e) => {
									setCurrentField((prev) => ({
										...prev,
										brandPersonality: {
											...prev.brandPersonality,
											title: e.target.value,
										},
									}));
								}}
								sx={{
									minHeight: 0,
									'&:hover': {
										bgcolor: 'unset',
									},
									border: 'unset !important',
								}}
								placeholder='Brand Personality Title'
							/>
							<ReactQuill
								className='side-bar-hover'
								theme='snow'
								value={currentField?.brandPersonality?.text}
								onChange={(value) => {
									setCurrentField((prev) => ({
										...prev,
										brandPersonality: {
											...prev.brandPersonality,
											text: value,
										},
									}));
								}}
								modules={{
									toolbar: toolbarOptions,
								}}
							/>
						</Grid>
					)}
				</Grid>
			)}
		</Grid>
	) : (
		<Grid container justifyContent={'center'}>
			<Typography
				variant='h6'
				textAlign={'center'}
				maxWidth={'70%'}
				mt={4}
			>
				Select a block to edit or add a new one
			</Typography>
		</Grid>
	);
};

export default SideBarOptions;
