import PropTypes from 'prop-types';
import DrawerLayout from '../Layouts/DrawerLayout';
import { Grid, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDataContext } from '../../contexts/DataContext';
import DrawerFileUploader from '../Inputs/DrawerFileUploader';
import ContentEditable from '../Inputs/ContentEditable';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import useCreateBrand from '../../api/hooks/brands/useCreateBrand';
import { useUser } from '../../contexts/UserContext';
import ClientList from '../Lists/ClientList';
import { BASE_FOLDERS } from '../../utils/enums';

import CustomAutocomplete from '../Inputs/CustomAutocomplete';
import { compareFields, getImageSource } from '../../utils/globals';
import useUpdateBrand from '../../api/hooks/brands/useUpdateBrand';
import useUploadAssetsBrand from '../../api/hooks/files/useUploadAssetsBrand';
import useGetBrandClients from '../../api/hooks/brands/useGetBrandClients';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import Helper from '../General/Helper';

const BrandDrawer = ({ isOpen, setIsOpen, clientsList }) => {
	const { handleAlert } = useAlert();
	const {
		isCreating,
		isEditing,
		setIsCreating,
		setIsEditing,
		currentItem,
		setCurrentItem,
		updateData,
	} = useDataContext();
	const [logo, setLogo] = useState(null);
	const [featuredImage, setFeaturedImage] = useState(null);
	const [originalBrand, setOriginalBrand] = useState(null);
	const [assignedClients, setAssignedClients] = useState([]);

	const { user } = useUser();

	const handleSuccess = (data) => {
		if (logo || featuredImage) {
			const formData = new FormData();

			formData.append('brandId', data.data?.id);

			featuredImage &&
				formData.append(
					BASE_FOLDERS.IMAGERY,
					currentItem?.featuredImage || '',
				);
			logo &&
				formData.append(BASE_FOLDERS.LOGOS, currentItem?.logo || '');
			return handleUploadFiles(formData);
		}
		handleUploaded(data);
	};

	const handleUploaded = (data) => {
		updateData(isCreating ? 'add' : 'edit', data.data);
		setIsOpen(false);
	};
	const { handleCreateBrand } = useCreateBrand(handleSuccess);
	const { handleUpdateBrand } = useUpdateBrand(handleSuccess);
	const { handleUploadFiles } = useUploadAssetsBrand(handleUploaded);

	const handleCloseDrawer = () => {
		setLogo(null);
		setAssignedClients([]);
		setCurrentItem(null);
		setFeaturedImage(null);
		setIsOpen(false);
	};
	const handleOnEdit = () => {
		const updatedClient = {
			...currentItem,
			BrandClients: assignedClients.map((client) => ({
				clientId: client?.id,
			})),
		};

		setCurrentItem(updatedClient);
		const changedFields = compareFields(updatedClient, originalBrand);

		handleUpdateBrand({
			id: currentItem?.id,
			...changedFields,
			BrandClients: assignedClients.map((client) => ({
				clientId: client?.id,
			})),
		});
	};

	const handleOnCreate = () => {
		if (!currentItem.name)
			return handleAlert(ALERT_STATUS.ERROR, 'Brand must have a name.');
		if (!logo)
			return handleAlert(ALERT_STATUS.ERROR, 'Brand must have a logo.');
		if (!featuredImage)
			return handleAlert(ALERT_STATUS.ERROR, 'Brand must have an image.');

		handleCreateBrand({
			name: currentItem.name,
			authorId: user?.id,
			clients: assignedClients.map((client) => ({
				clientId: client?.id,
			})),
		});
	};

	useEffect(() => {
		if (currentItem?.BrandClients && currentItem?.BrandClients.length) {
			setAssignedClients(
				clientsList
					? [
							...clientsList.filter((client) => {
								return currentItem?.BrandClients.some(
									(brandClient) =>
										brandClient.clientId === client.id,
								);
							}),
					  ]
					: [],
			);
		}
	}, [currentItem, isOpen]);

	useEffect(() => {
		if (originalBrand === null) setOriginalBrand(currentItem);
	}, [currentItem]);

	return (
		<DrawerLayout
			title={'Brand Details'}
			isOpen={isOpen}
			handleCloseDrawer={handleCloseDrawer}
			handleSave={isCreating ? handleOnCreate : handleOnEdit}
		>
			<Grid
				container
				justifyContent={'space-between'}
				gap={2}
				alignItems={'end'}
				sx={{ borderBottom: 'unset' }}
				wrap='no-wrap'
			>
				<Grid
					container
					alignItems={'flex-end'}
					gap={2}
					sx={{
						border: 'unset !important',
						paddingBottom: 'unset !important',
					}}
					justifyContent={'center'}
					width={'fit-content'}
					position={'relative'}
				>
					<Helper
						helperText={'This logo will show in brand book header.'}
						inset={'0 -2px auto auto'}
					/>
					<DrawerFileUploader
						displayEl='avatar'
						buttonText='Logo'
						fieldName={'logo'}
						inputName='logo'
						inputId='brand-logo-upload'
						file={logo}
						setFile={setLogo}
						currentItem={currentItem}
						setCurrentItem={setCurrentItem}
						pathToImage={getImageSource(
							currentItem?.logo,
						)?.src.replace(/\\/g, '/')}
					/>
				</Grid>
				<Grid
					container
					justifyContent={'space-between'}
					gap={2}
					height={'fit-content'}
				>
					<ContentEditable
						label='Brand Name'
						type={'text'}
						value={currentItem?.name}
						setValue={({ target: { value } }) => {
							setCurrentItem((prev) => ({
								...prev,
								name: value,
							}));
						}}
						canEdit={isEditing || isCreating}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				alignItems={'flex-end'}
				gap={2}
				position={'relative'}
			>
				<Helper
					helperText={
						'This image will show in login screen for user.'
					}
					inset={'auto 0 20px auto'}
				/>
				<DrawerFileUploader
					displayEl='box'
					buttonText='Brand Image'
					fieldName={'featuredImage'}
					inputName='featuredImage'
					inputId='brand-image-upload'
					file={featuredImage}
					setFile={setFeaturedImage}
					currentItem={currentItem}
					setCurrentItem={setCurrentItem}
					pathToImage={
						currentItem?.featuredImage
							? getImageSource(currentItem?.featuredImage)?.src
							: null
					}
					isImage={featuredImage || true}
				/>
			</Grid>
			<Grid
				container
				direction={'column'}
				gap={2}
				wrap='no-wrap'
				sx={{
					maxHeight: '30vh',
					paddingInlineEnd: 2,
					overflowY: 'scroll',
					border: 'unset !important',
				}}
			>
				<Grid
					container
					justifyContent={'space-between'}
					gap={2}
					direction={'column'}
					sx={{ border: 'unset !important' }}
				>
					<Typography variant='h6' component='h6'>
						Assigned clients
					</Typography>

					<CustomAutocomplete
						options={clientsList}
						disabledOptions={
							clientsList
								? clientsList?.filter(
										(client) =>
											currentItem?.BrandClients &&
											currentItem?.BrandClients?.some(
												(brandClient) =>
													brandClient.clientId ===
													client.id,
											),
								  )
								: []
						}
						label='Search clients by name...'
						titleField={'name'}
						onSelect={(client) => {
							setAssignedClients((prev) => [...prev, client]);

							//add to associated clients
						}}
						icon={true}
					/>
				</Grid>

				<ClientList
					isAssignedList={true}
					clients={assignedClients}
					handleRemoveItem={(client) => {
						setAssignedClients((prev) => [
							...prev.filter((item) => item.id !== client.id),
						]);
					}}
				/>
			</Grid>
		</DrawerLayout>
	);
};
BrandDrawer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	clientsList: PropTypes.array,
};
export default BrandDrawer;
