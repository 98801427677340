import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { deleteFolder } from '../../files';

const useDeleteFolder = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutateAsync: handleDeleteFolder, isPending } = useMutation({
		mutationFn: (data) => deleteFolder(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response?.data.error);
		},
	});

	return { handleDeleteFolder, isPending };
};

export default useDeleteFolder;
