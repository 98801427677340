import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '../../styles/palette';

const ActionBtn = ({ text, icon, action, sx }) => {
	return (
		<Box
			onClick={action}
			sx={{
				...sx,
				borderRadius: '12px',
				px: 1,
				py: 0.5,
				mt: 0.5,
				// '&.MuiSvgIcon-root': {
				// 	fill: palette.text.link,
				// },
			}}
			alignItems={'center'}
			display={'flex'}
			gap={1}
			// flexDirection={'column'}
		>
			{icon && icon}
			{text && (
				<Typography fontSize={14} fontWeight={500}>
					{text}
				</Typography>
			)}
		</Box>
	);
};

ActionBtn.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
	action: PropTypes.func.isRequired,
	sx: PropTypes.object,
};

export default ActionBtn;
