import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const PageHeader = ({ title, button, mt = 6 }) => {
  return (
    <Grid
      container
      justifyContent={"space-between"}
      mb={3}
      mt={mt}
      alignItems={"center"}
    >
      <Typography variant="h5">{title}</Typography>

      {button && button}
    </Grid>
  );
};
PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.any,
  mt: PropTypes.number,
};
export default PageHeader;
