import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import BlockActions from '../Inputs/BlockActions';
import { Fragment, useEffect, useRef, useState } from 'react';
import { formatSpacingValues } from '../../utils/fields';
import { useFields } from '../../contexts/FieldsContext';

const HeadingField = ({
	variant,
	fontWeight,
	margin,
	padding,
	color,
	value,
	index,
	obj,
}) => {
	const { isEditing, isCreating, setIsCreating, setIsEditing } =
		useBrandbooksContext();
	const { currentField, handleSaveField, handleEditField, setCurrentField } =
		useFields();

	const field = currentField?.index === index ? currentField : obj;

	const contentRef = useRef(null);

	const handleInput = () => {
		const newText = contentRef.current.textContent;
		setCurrentField((prev) => ({
			...prev,
			value: newText,
		}));
		moveCursorToEnd();
	};
	const moveCursorToEnd = () => {
		if (contentRef.current) {
			const range = document.createRange();
			const sel = window.getSelection();
			range.selectNodeContents(contentRef.current);
			range.collapse(false); // Collapse to the end of the range
			sel.removeAllRanges();
			sel.addRange(range);
		}
	};

	return (
		<BlockActions
			index={index}
			isEditing={
				(isCreating && currentField?.index === index) ||
				(isEditing && currentField?.index === index)
			}
			saveAction={() => {
				handleSaveField(currentField);
				setIsCreating(false);
				setIsEditing(false);
			}}
			editAction={() => {
				handleEditField(obj);
				setIsEditing(true);
			}}
			isInColumn={field?.isInColumn}
			columnIndex={field?.columnIndex || null}
			field={obj}
		>
			<Box sx={{ padding: '2vmin' }}>
				<Typography
					ref={contentRef}
					variant={field?.variant || variant[0]}
					fontWeight={field?.fontWeight || fontWeight}
					color={field?.color || color}
					sx={{
						padding: formatSpacingValues(field?.padding || padding),
						margin: formatSpacingValues(field?.margin || margin),
						position: 'relative',
						zIndex: 1,
						outline: 'none !important',
					}}
					contentEditable={
						(isCreating || isEditing) &&
						currentField?.index === index
					}
					suppressContentEditableWarning
					onInput={handleInput}
				>
					{typeof field?.value === 'string' &&
						(field?.value || value || 'Type your title here')}
				</Typography>
			</Box>
		</BlockActions>
	);
};
HeadingField.propTypes = {
	variant: PropTypes.string,
	fontWeight: PropTypes.number,
	index: PropTypes.number,
	margin: PropTypes.array,
	padding: PropTypes.array,
	color: PropTypes.string,
	value: PropTypes.string,
	obj: PropTypes.object,
};
export default HeadingField;
