import { useQuery } from '@tanstack/react-query';

import { getCategories } from '../../files';

const useGetCategories = () => {
	const QUERY_KEY = ['getCategories'];

	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getCategories(),
	});
};

export default useGetCategories;
