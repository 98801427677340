import {
	CardContent,
	CardMedia,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { StyledBrandCard } from '../../styles/layouts';
import { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { GENERAL_LINK_BRANDBOOK, getImageSource } from '../../utils/globals';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PlaceholderImage from '../../assets/images/placeholder.jpg';
import CustomAvatar from '../Cards/CustomAvatar';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from 'react-router-dom';
import { DISPLAY, useDisplayContext } from '../../contexts/DisplayContext';
import { FieldType, useFields } from '../../contexts/FieldsContext';

const BrandBookCard = ({ brandbook, setPendingDelete }) => {
	const navigate = useNavigate();
	const { handleEditBrandbook } = useBrandbooksContext();
	const { setDisplayMode } = useDisplayContext();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<StyledBrandCard>
			<CardMedia
				image={
					getImageSource(brandbook?.bannerAsset)?.src.replace(
						/\\/g,
						'/',
					) || PlaceholderImage
				}
				title={brandbook?.brand?.name}
			>
				<MoreVertIcon onClick={handleClick} />
				<Menu
					id={`book-menu${brandbook?.id}`}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
					elevation={0}
					sx={{
						'& .MuiPaper-root': {
							boxShadow: '0 3px 6px #25232342',
							borderRadius: '16px',
						},
					}}
					anchorOrigin={{
						vertical: 0,
						horizontal: 30,
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<MenuItem
						onClick={() => {
							handleEditBrandbook(brandbook);

							setDisplayMode(DISPLAY.BUILDER);
						}}
					>
						<ListItemIcon>
							<EditOutlinedIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Edit Brandbook</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleEditBrandbook(brandbook);
							navigate(
								GENERAL_LINK_BRANDBOOK + brandbook?.brand?.name,
								{
									state: { preview: true },
								},
							);
						}}
					>
						<ListItemIcon>
							<RemoveRedEyeOutlinedIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Preview Brandbook</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setPendingDelete(brandbook?.id);
							handleClose();
						}}
					>
						<ListItemIcon>
							<DeleteOutlinedIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Delete Brandbook</ListItemText>
					</MenuItem>
				</Menu>
				<CustomAvatar
					image={getImageSource(brandbook?.brand?.logo)?.src}
					name={brandbook?.brand?.name}
					size={80}
				/>
			</CardMedia>
			<CardContent sx={{ position: 'relative' }}>
				<Typography variant='h6' align='center' mt={1}>
					{brandbook?.brand?.name}
				</Typography>
				<Typography variant='small' fontSize={14}>
					{`Latest update was on
${new Date(brandbook?.updatedAt).toLocaleDateString('he-IL')}`}
				</Typography>
			</CardContent>
		</StyledBrandCard>
	);
};

BrandBookCard.propTypes = {
	brandbook: PropTypes.object,
	setPendingDelete: PropTypes.func,
};
export default BrandBookCard;
