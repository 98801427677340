import { useState, useEffect, useRef } from 'react';

const useIsInViewport = (options) => {
	const [isInView, setIsInView] = useState(false);
	const observerRef = useRef(null);

	useEffect(() => {
		if (observerRef.current) {
			observerRef.current.disconnect();
		}

		observerRef.current = new IntersectionObserver(([entry]) => {
			setIsInView(entry.isIntersecting);
		}, options);

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, [options]);

	const observe = (element) => {
		if (observerRef.current && element) {
			observerRef.current.observe(element);
		}
	};

	const unobserve = (element) => {
		if (observerRef.current && element) {
			observerRef.current.unobserve(element);
		}
	};

	return { isInView, observe, unobserve };
};

export default useIsInViewport;
