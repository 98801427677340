import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { StyledBlockActions } from '../../styles/inputs';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import { FieldType, useFields } from '../../contexts/FieldsContext';

const BlockActions = ({
	children,
	editAction,
	field,
	index,
	isEditing,
	saveAction,
	isInColumn,
	switchAction,
	sx,
}) => {
	const [showBlockActions, setShowBlockActions] = useState(false);
	const {
		handleDeleteField,
		setCurrentField,
		setNewBlockIndex,
		handleSaveField,
		currentField,
	} = useFields();
	const { setIsCreating, setIsEditing } = useBrandbooksContext();

	return (
		<Box
			sx={{
				...sx,
				width: '100%',
				position: 'relative',
			}}
			onMouseEnter={() => setShowBlockActions(true)}
			onMouseLeave={() => !isEditing && setShowBlockActions(false)}
		>
			{showBlockActions && (
				<StyledBlockActions>
					{index && (
						<Grid container>
							<Tooltip title='Delete' placement='top' arrow>
								<IconButton
									aria-label='delete'
									onClick={() => {
										handleDeleteField(field);
										setIsEditing(false);
										setIsCreating(false);
									}}
								>
									<ClearIcon />
								</IconButton>
							</Tooltip>
							{!isInColumn && (
								<Tooltip
									title='Add Block'
									placement='top'
									arrow
								>
									<IconButton
										aria-label='add'
										onClick={() => {
											handleSaveField(currentField);

											setNewBlockIndex(index);
										}}
									>
										<AddIcon />
									</IconButton>
								</Tooltip>
							)}
						</Grid>
					)}
					{isEditing ? (
						<Tooltip title='Save' placement='right' arrow>
							<IconButton
								aria-label='save'
								onClick={() => {
									saveAction();
									setCurrentField(null);
								}}
							>
								<SaveOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : (
						<Tooltip title='Edit Block' placement='right' arrow>
							<IconButton aria-label='edit' onClick={editAction}>
								<ModeEditOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}
					{!index && switchAction !== undefined && (
						<Tooltip title='Replace Banner' placement='right' arrow>
							<IconButton
								aria-label='replace'
								onClick={switchAction}
							>
								<RefreshOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}
				</StyledBlockActions>
			)}
			{children}
		</Box>
	);
};

BlockActions.propTypes = {
	children: PropTypes.any,
	field: PropTypes.object,
	index: PropTypes.number,
	editAction: PropTypes.func,
	saveAction: PropTypes.func,
	switchAction: PropTypes.func,
	isEditing: PropTypes.bool,
	isInColumn: PropTypes.bool,
	columnIndex: PropTypes.number,
	sx: PropTypes.object,
};
export default BlockActions;
