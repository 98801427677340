import { Button, Collapse, Divider, List } from '@mui/material';

import ClientStrip from '../Cards/ClientStrip';
import NoResults from '../Layouts/NoResults';
import PropTypes from 'prop-types';

import { TransitionGroup } from 'react-transition-group';

const ClientList = ({
	filter = '',
	isAssignedList,
	clients,
	handleEditItem,
	handleCreateItem,
	handleRemoveItem,
}) => {
	const AddButton = () => {
		return (
			<Button
				variant='outlined'
				sx={{ mt: 2 }}
				onClick={() => {
					const client = {
						email: '',
						name: '',
					};
					handleCreateItem(client);
				}}
				disableRipple
			>
				Add Client
			</Button>
		);
	};

	return (
		<>
			{clients.length ? (
				<>
					<List sx={{ marginInline: '-16px', overflow: 'scroll' }}>
						<TransitionGroup>
							{clients
								.filter(
									(item) =>
										item?.name?.includes(filter) ||
										item?.email?.includes(filter),
								)
								.map((client, index) => (
									<Collapse key={index}>
										<ClientStrip
											client={client}
											index={index}
											key={index}
											isAssignedList={isAssignedList}
											handleEditItem={handleEditItem}
											handleRemoveItem={handleRemoveItem}
										/>
										{index + 1 !== clients.length && (
											<Divider
												variant='inset'
												component='hr'
											/>
										)}
									</Collapse>
								))}
						</TransitionGroup>
					</List>
					{!isAssignedList && AddButton()}
				</>
			) : isAssignedList ? (
				''
			) : (
				<NoResults
					userType={'clients'}
					button={AddButton()}
					fontVariant={'p'}
				/>
			)}
		</>
	);
};
ClientList.propTypes = {
	filter: PropTypes.string,
	isAssignedList: PropTypes.bool,
	setIsDialogOpen: PropTypes.func,
	setCurrClient: PropTypes.func,
	handleEditItem: PropTypes.func,
	handleCreateItem: PropTypes.func,
	handleRemoveItem: PropTypes.func,
	clients: PropTypes.array,
};
export default ClientList;
