import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { deleteAssets } from '../../files';

const useDeleteAssets = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutateAsync: handleDeleteAssets, isPending } = useMutation({
		mutationFn: (data) => deleteAssets(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response?.data.error);
		},
	});

	return { handleDeleteAssets, isPending };
};

export default useDeleteAssets;
