import PropTypes from 'prop-types';
import { Collapse, ListItemText, ListItemButton, List } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Fragment, useEffect, useState } from 'react';
import { useFileContext } from '../../contexts/FilesContext';
import CustomListItem from './CustomListItem';

export default function FoldersTree({ folders, filter, sx }) {
	const [open, setOpen] = useState(false);
	const { setBrand, brand, currentFolder, setCurrentFolder } =
		useFileContext();

	useEffect(() => {
		setOpen(filter);
	}, [filter]);

	function renderFolders(folders, filter, level) {
		return folders
			.filter((f) => f?.name.toLowerCase().includes(filter))
			.map((folder) => (
				<div
					key={folder.id}
					onClick={() => {
						setOpen(folder.id);
					}}
				>
					<CustomListItem
						folder={folder}
						level={level}
						hasChildren={
							folder.children && folder?.children?.length > 0
						}
					/>
					{folder.children && folder.children.length > 0 && (
						<Collapse
							in={open === folder?.id}
							timeout='auto'
							unmountOnExit
						>
							<List component='div'>
								{renderFolders(
									folder.children,
									filter,
									level + 1,
								)}
							</List>
						</Collapse>
					)}
				</div>
			));
	}

	return (
		<List
			sx={{
				...sx,
				bgcolor: 'background.paper',
			}}
			component='nav'
			aria-labelledby='nested-list-subheader'
		>
			{folders?.map((folder) => (
				<Fragment key={folder?.brand?.id}>
					<ListItemButton
						onClick={() => {
							setCurrentFolder(null);
							setOpen(
								open === folder?.brand?.id
									? null
									: folder?.brand?.id,
							);
							const brandToSet =
								brand && brand.id === folder?.brand?.id
									? null
									: folder?.brand || null;
							setBrand(brandToSet);
						}}
						sx={{
							color:
								open === folder?.brand?.id ||
								brand?.id === folder?.brand?.id
									? 'primary.main'
									: 'inherit',
							textDecoration:
								open === folder?.brand?.id
									? 'underline'
									: 'none',
						}}
					>
						{open === folder?.brand?.id ? (
							<ExpandLess />
						) : (
							<KeyboardArrowRightOutlinedIcon
								sx={{ color: 'background.grey' }}
							/>
						)}

						<ListItemText
							primary={folder?.brand?.name}
							sx={{ marginInlineStart: '12px' }}
						/>
					</ListItemButton>
					<Collapse
						in={brand?.id === folder?.brand?.id} // or == to child of brand
						timeout='auto'
						unmountOnExit
					>
						<List component='div' disablePadding>
							{renderFolders(folder.folders, filter, 0)}
						</List>
					</Collapse>
				</Fragment>
			))}
		</List>
	);
}

FoldersTree.propTypes = {
	folders: PropTypes.array,
	sx: PropTypes.object,
	filter: PropTypes.string,
};
