import { useMutation } from '@tanstack/react-query';
import { verifyAuthCode } from '../../users';

import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

const useVerifyAuthCode = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: verifyCode, isPending } = useMutation({
		mutationFn: ({ email, authCode }) =>
			verifyAuthCode({ email, authCode }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { verifyCode, isPending };
};

export default useVerifyAuthCode;
