import { useQuery } from '@tanstack/react-query';

import { getFieldsByBrandbook } from '../../brandbooks';
import { useEffect } from 'react';

const useGetFieldsByBrandbook = (brandbookId) => {
	const QUERY_KEY = ['getFieldsByBrandbook', brandbookId];

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getFieldsByBrandbook(brandbookId),
		enabled: !!brandbookId,
	});

	useEffect(() => {
		if (brandbookId !== null) {
			refetch();
		}
	}, [brandbookId, refetch]);

	return { data, isLoading, isError, error };
};

export default useGetFieldsByBrandbook;
