import { Grid } from '@mui/material';
import BrandbookLayout from '../../components/Layouts/BrandbookLayout';
import BrandbookSidebar from '../../components/SideBars/BrandbookSidebar';
import BrandbookBuilder from '../../components/Screens/BrandbookBuilder';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import { useEffect, useRef, useState } from 'react';
import BrandDialog from '../../components/Dialogs/BrandDialog';
import AssetSelectDialog from '../../components/Dialogs/AssetSelectDialog';
import BrandBooksDisplay from '../../components/Screens/BrandBooksDisplay';
import { useLocation } from 'react-router-dom';
import useIsInViewport from '../../hooks/useIsInViewport';
import { DISPLAY, useDisplayContext } from '../../contexts/DisplayContext';

const Brandbooks = () => {
	const { isCreating, currentBrand, handleEditBrandbook, brandBooks } =
		useBrandbooksContext();
	const { displayMode, setDisplayMode } = useDisplayContext();
	const [isShowDialog, setIsShowDialog] = useState(false);
	const [isChoosingAsset, setIsChoosingAsset] = useState(false);
	const location = useLocation();
	const builderRef = useRef();
	const { isInView: layoutInView, observe: observeLayout } = useIsInViewport(
		{},
	);

	useEffect(() => {
		if (displayMode === DISPLAY.BUILDER && !currentBrand) {
			setIsShowDialog(!isCreating);
		}
	}, [displayMode, currentBrand]);

	useEffect(() => {
		//this causes changinh in displaymode when clicking on save and brandbooks change, and if brandbooks isn't in dependencies the
		if (brandBooks?.length && location?.state) {
			const currBrandBook = brandBooks.find(
				(book) => book.brandId === location?.state?.brandId,
			);
			handleEditBrandbook(currBrandBook);
			location.state?.display && setDisplayMode(location.state?.display);
		}
	}, [location?.state, brandBooks]);

	useEffect(() => {
		if (builderRef.current) {
			observeLayout(builderRef.current);
		}
	}, [observeLayout]);

	return (
		<>
			<BrandbookLayout builderRef={builderRef}>
				{displayMode === DISPLAY.CARDS && <BrandBooksDisplay />}
				{/* <div>brandbooks</div> */}
				{displayMode === DISPLAY.BUILDER && (
					<Grid container gap={4} sx={{ position: 'relative' }}>
						<Grid item xs={2.5}>
							<BrandbookSidebar />
						</Grid>
						<Grid item xs={8.7}>
							<BrandbookBuilder
								layoutInView={layoutInView}
								setIsChoosingAsset={setIsChoosingAsset}
							/>
						</Grid>
					</Grid>
				)}
			</BrandbookLayout>
			{displayMode === DISPLAY.BUILDER && (
				<>
					<BrandDialog
						isOpen={isShowDialog}
						setIsOpen={setIsShowDialog}
					/>
					<AssetSelectDialog
						isOpen={isChoosingAsset}
						setIsOpen={setIsChoosingAsset}
					/>
				</>
			)}
		</>
	);
};

export default Brandbooks;
