import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { createFolder } from '../../files';

const useCreateNewFolder = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleCreateFolder, isPending } = useMutation({
		mutationFn: (data) => createFolder(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleCreateFolder, isPending };
};

export default useCreateNewFolder;
