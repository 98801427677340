import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { resendCode } from '../../users';

const useResendCode = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleResendCode, isPending } = useMutation({
		mutationFn: ({ email }) => resendCode({ email }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleResendCode, isPending };
};

export default useResendCode;
