import { useCallback, useEffect, useState } from 'react';
import BlockActions from '../Inputs/BlockActions';
import { Box, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { formatSpacingValues } from '../../utils/fields';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import placeholderImage from '../../assets/images/placeholder.jpg';

import { handleCroppedImage } from '../../utils/cropImage';
import { getImageSource } from '../../utils/globals';
import { useAlert } from '../../contexts/AlertContext';
import { useFields } from '../../contexts/FieldsContext';

const ImageField = ({
	index,
	setIsChoosingAsset,

	obj,
}) => {
	const { isEditing, isCreating, setIsCreating, setIsEditing } =
		useBrandbooksContext();
	const { currentField, handleSaveField, handleEditField } = useFields();
	const { handleAlert } = useAlert();
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);

	const [loading, setLoading] = useState(false);
	const [field, setField] = useState(null);

	const handleGetCroppedImage = async () => {
		if (field?.asset?.localPath || field?.asset?.externalLink) {
			const croppedImage = await handleCroppedImage(
				field,
				handleAlert,
				setLoading,
			);

			if (!croppedImage) return;
			setCroppedImageUrl(croppedImage);
		}
	};

	useEffect(() => {
		handleGetCroppedImage();
	}, [field?.asset]);

	useEffect(() => {
		setField(currentField?.index === index ? currentField : obj);
	}, [currentField]);

	return (
		<BlockActions
			index={index}
			isEditing={
				(isCreating && currentField?.index === index) ||
				(isEditing && currentField?.index === index)
			}
			saveAction={() => {
				handleSaveField(currentField);
				setIsCreating(false);
				setIsEditing(false);
			}}
			editAction={() => {
				handleEditField(obj);
				setIsEditing(true);
			}}
			isInColumn={field?.isInColumn}
			columnIndex={field?.columnIndex || null}
			field={obj}
		>
			<Box
				sx={{ margin: '2vmin', paddingBlock: '2vmin', height: '100%' }}
			>
				<Box
					sx={{
						position: 'relative',
						zIndex: 1,
						height: field?.imageHeight
							? `${Math.round(field?.imageHeight) + '%'}`
							: `auto`,
						width: `${Math.round(field?.imageWidth) + '%'}`,
						borderRadius: formatSpacingValues(
							field?.borderRadius || [],
						),
						padding: formatSpacingValues(field?.padding || []),
						margin: formatSpacingValues(field?.margin || []),
						overflow: 'hidden',
					}}
				>
					{loading ? (
						<Skeleton
							variant='rectangular'
							width={'100%'}
							sx={{ aspectRatio: 1, height: 'unset' }}
							onClick={() => {
								if (currentField?.index !== index) return;
								setIsChoosingAsset(true);
							}}
						/>
					) : (
						<img
							src={
								field?.asset
									? field?.asset?.mimetype &&
									  field?.asset?.mimetype?.includes('gif')
										? getImageSource(field?.asset)?.src
										: croppedImageUrl
									: placeholderImage
							}
							alt={field?.asset?.name || 'placeholder'}
							onClick={() => {
								if (currentField?.index !== index) return;
								setIsChoosingAsset(true);
							}}
							style={{ width: '100%', objectFit: 'contain' }}
						/>
					)}
				</Box>
			</Box>
		</BlockActions>
	);
};

ImageField.propTypes = {
	index: PropTypes.number,

	setIsChoosingAsset: PropTypes.func,
	obj: PropTypes.object,
};

export default ImageField;
