import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Slide,
} from '@mui/material';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { CustomBasicDialog } from '../../styles/layouts';
import GeneralButton from '../Inputs/GeneralButton';
import palette from '../../styles/palette';
import { useIsMutating } from '@tanstack/react-query';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});
const BasicDialog = ({
	isOpen,
	setIsOpen,
	title,
	actions,
	children,
	isError,
	handleClose,
	maxWidth,
}) => {
	const isMutating = useIsMutating();

	return (
		<CustomBasicDialog
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			maxWidth={maxWidth || '600'}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{children}</DialogContent>
			<DialogActions>
				{actions.map((action, index) => (
					<Grid key={index}>
						{action.isMain ? (
							<GeneralButton
								onClick={() => {
									action.actionHandler();
									//   !isError && setIsOpen(false);
								}}
								sx={{ minHeight: '40px' }}
							>
								{action?.title || 'Save'}
							</GeneralButton>
						) : (
							<Button
								variant='simple'
								onClick={() => {
									action.actionHandler();
									setIsOpen(false);
								}}
								sx={{ bgcolor: palette.background.grey }}
								disabled={isMutating}
							>
								Cancel
							</Button>
						)}
					</Grid>
				))}
			</DialogActions>
		</CustomBasicDialog>
	);
};
BasicDialog.propTypes = {
	title: PropTypes.string,
	isError: PropTypes.bool,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	setIsOpen: PropTypes.func,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			actionHandler: PropTypes.func,
			isMain: PropTypes.bool,
		}),
	),
	children: PropTypes.array,
	maxWidth: PropTypes.number,
};
export default BasicDialog;
