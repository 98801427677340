import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { deleteBrandbook } from '../../brandbooks';

const useDeleteBrandbook = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleDeleteBrandBook, isPending } = useMutation({
		mutationFn: (data) => deleteBrandbook(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleDeleteBrandBook, isPending };
};

export default useDeleteBrandbook;
