import { IconButton, Tooltip } from "@mui/material";
import SyncLockOutlinedIcon from "@mui/icons-material/SyncLockOutlined";
import PropTypes from "prop-types";

const ClientCredsReset = ({ client }) => {
  const handleResetCredentials = () => {
    //create custom hook (mutate), send email to authanticate client reset password and then send mail to client,
    //notify admin email was sent
  };
  return (
    <Tooltip title="Reset Credentials" placement="top" arrow>
      <IconButton
        edge="end"
        aria-label="resend"
        onClick={handleResetCredentials}
      >
        <SyncLockOutlinedIcon
          sx={{
            color: "text.primary",
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

ClientCredsReset.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
};
export default ClientCredsReset;
