import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatSpacingValues } from '../../utils/fields';

const HeadingDisplay = ({ field }) => {
	return (
		<Typography
			variant={field?.variant}
			fontWeight={field?.fontWeight}
			color={field?.color}
			sx={{
				padding: formatSpacingValues(field?.padding),
				margin: formatSpacingValues(field?.margin),
				position: 'relative',
				zIndex: 1,
				outline: 'none !important',
			}}
		>
			{field?.value}
		</Typography>
	);
};

HeadingDisplay.propTypes = {
	field: PropTypes.object,
};
export default HeadingDisplay;
