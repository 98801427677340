import { useQuery } from '@tanstack/react-query';

import { getBrandbook } from '../../brandbooks';
import { useEffect } from 'react';

const useGetBrandBook = (brandName) => {
	const QUERY_KEY = ['getBrandbook', brandName];

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getBrandbook(brandName),
		enabled:
			brandName != null &&
			brandName != undefined &&
			brandName != 'undefined',
		staleTime: 0,
	});

	useEffect(() => {
		if (
			brandName != null &&
			brandName != undefined &&
			brandName != 'undefined'
		) {
			refetch();
		}
	}, [brandName, refetch]);

	return { data, isLoading, isError, error };
};

export default useGetBrandBook;
