import { useMutation } from '@tanstack/react-query';

import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { deleteClient, editClient } from '../../brands';

const useDeleteClient = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleDeleteClient, isPending } = useMutation({
		mutationFn: ({ id }) => deleteClient({ id }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleDeleteClient, isPending };
};

export default useDeleteClient;
