import { Box, Button } from '@mui/material';
import { useRef } from 'react';
import CustomAvatar from '../Cards/CustomAvatar';
import palette from '../../styles/palette';
import PropTypes from 'prop-types';
import PlaceHolderImage from '../../assets/images/placeholder.jpg';

import { getFileExtension, isImageFile } from '../../utils/globals';
import { FileIcon, defaultStyles } from 'react-file-icon';

// const relativeBtn = { position: 'absolute', bottom: 30, right: 100 };

const DrawerFileUploader = ({
	displayEl,
	buttonText,
	fieldName,
	inputName,
	inputId,
	file,
	setFile,
	pathToImage,
	currentItem,
	setCurrentItem,
	setIsImage,
	isImage,
}) => {
	const fileInputRef = useRef(null);

	const handleFileChange = () => {
		const selectedFile = fileInputRef.current.files[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			setFile(reader.result);
		};

		if (selectedFile) {
			reader.readAsDataURL(selectedFile);
			setIsImage && setIsImage(isImageFile(selectedFile));
		}

		setCurrentItem((prev) => ({ ...prev, [`${fieldName}`]: selectedFile }));
	};

	return (
		<>
			{displayEl === 'avatar' ? (
				<CustomAvatar
					image={file || pathToImage}
					name={currentItem?.name}
					size={85}
					sx={{
						border: `3px solid ${palette.background.grey} `,
					}}
				/>
			) : (
				<Box
					width={'100%'}
					sx={{
						borderRadius: '24px',
						overflow: 'hidden',
					}}
				>
					{!isImage ? (
						// <Grid
						// 	container
						// 	alignItems={'center'}
						// 	// direction={'column'}
						// >

						<Box sx={{ maxWidth: '25%' }} mx={'auto'}>
							<FileIcon
								extension={
									fileInputRef.current?.files &&
									getFileExtension(
										fileInputRef.current?.files[0]?.name,
									)
								}
								{...defaultStyles[
									fileInputRef.current?.files &&
										getFileExtension(
											fileInputRef.current?.files[0]
												?.name,
										)
								]}
							/>
						</Box>
					) : (
						/* <DescriptionOutlinedIcon
								sx={{ color: 'grey', height: 30, width: 30 }}
							/>
							<Typography
								variant='h6'
								textTransform={'uppercase'}
								color={'grey'}
							>
								{fileInputRef.current?.files &&
									fileInputRef.current?.files[0]?.type.split(
										'/',
									)[1]}
							</Typography> */
						/* </Grid> */
						<img
							srcSet={
								file ? file : pathToImage || PlaceHolderImage
							}
							src={file ? file : pathToImage || PlaceHolderImage}
							alt={currentItem?.name}
							loading='lazy'
							style={{
								height: 170,
								width: '100%',
								objectFit: 'cover',
							}}
						/>
					)}
				</Box>
			)}
			<input
				ref={fileInputRef}
				type='file'
				style={{ display: 'none' }}
				onChange={handleFileChange}
				id={inputId}
				name={inputName}
			/>
			<label
				htmlFor={inputId}
				// style={displayEl === 'box' ? { ...relativeBtn } : {}}
			>
				<Button
					variant='simple'
					sx={{ bgcolor: palette.background.grey }}
					component='span'
				>
					{currentItem?.[`${fieldName}`] || file
						? `Change ${buttonText}`
						: `Add ${buttonText}`}
				</Button>
			</label>
		</>
	);
};

DrawerFileUploader.propTypes = {
	displayEl: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired,
	fieldName: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	inputId: PropTypes.string.isRequired,
	file: PropTypes.instanceOf(File),
	setFile: PropTypes.func.isRequired,
	pathToImage: PropTypes.string,
	currentItem: PropTypes.object,
	setCurrentItem: PropTypes.func.isRequired,
	setIsImage: PropTypes.func,
	isImage: PropTypes.bool,
};
export default DrawerFileUploader;
