import styled from '@emotion/styled';
import {
	Autocomplete,
	Badge,
	Box,
	Grid,
	Input,
	Paper,
	ToggleButtonGroup,
	darken,
	lighten,
} from '@mui/material';

export const StyledAdminSearch = styled(Input)(({ theme: { palette } }) => ({
	border: `1px solid ${palette.background.paper}`,
	borderRadius: '100vw',
	padding: '1rem 1.5rem',
	background: palette.background.paper,
	'&::before': {
		content: 'unset',
	},
	'&::after': {
		content: 'unset',
	},
	color: palette.text.primary,
	'&:hover': {
		background: palette.background.paper,
		borderColor: palette.primary.main,
	},
	'&:focus-within': {
		' .MuiSvgIcon-root': {
			fill: palette.primary.main,
		},
	},
	'& .MuiSvgIcon-root': {
		fill: palette.text.primary,
	},
}));

export const StyledBadge = styled(Badge)(({ theme: { palette } }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}));

export const StyledAuthInput = styled(Box)(({ theme: { palette } }) => ({
	width: '100%',
	'& *': {
		fontFamily: 'Poppins',
	},
	'& .vi__container': {
		gap: '1rem',
		width: '100%',
		'& .vi__character': {
			borderRadius: '8px',
			flex: 1,
			color: palette.text.secondary,
			background: darken(palette.background.default, 0.02),
			border: `none`,
			outline: `2px solid transparent`,
		},
		'& .vi__character--selected': {
			outline: `2px solid ${palette.primary.main}`,
			color: palette.text.secondary,
		},
	},
}));

export const StyledSimpleInput = styled(Input)(({ theme: { palette } }) => ({
	borderColor: 'transparent',
	bgcolor: 'background.default',
	borderRadius: '100vw',
	'&:before': { content: 'unset' },
	'&:after': { content: 'unset' },
	paddingBlock: '0.5rem',
	minHeight: '48px',
}));

export const StyledAutoComplete = styled(Autocomplete)(
	({ theme: { palette } }) => ({
		'& .MuiInputBase-root': {
			backgroundColor: lighten(palette.background.grey, 0.5),
			borderRadius: '32px',
			padding: '8px 16px',
			border: 'none !important',
			outline: 'unset !important',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none !important',
		},
		'& .MuiListItemButton-root': {
			color: palette.primary.main,
			'&:hover': {
				'& .MuiListItemIcon-root': {
					fill: palette.primary.main,
				},
			},
		},
		'  .MuiAutocomplete-inputRoot ': {
			// color: 'white',
		},
		'.MuiAutocomplete-clearIndicator': {
			// color: 'white',
		},
		'.MuiAutocomplete-popupIndicator ': {
			// color: 'white',
		},
		'.MuiAutocomplete-popper': {
			marginTop: '30px',
			boxShadow: 'unset !important',
			overflow: 'hidden !important',
		},
	}),
);

export const StyledPopper = styled(Paper)(({ theme: { palette } }) => ({
	// boxShadow: `0px 3px 6px ${lighten(
	// 	palette.background.grey,
	// 	0.5,
	// )} !important`,
	borderRadius: '24px',
	transform: 'translateY(10px)',
	width: '100%',
	background: '#efefef',
	'& .MuiBox-root': {
		maxWidth: '98%',
		maxHeight: '120px',
		overflow: 'scroll',
	},
}));

export const StyledBlockActions = styled(Box)(({ theme: { palette } }) => ({
	position: 'absolute',
	inset: 0,
	border: `2px dashed`,
	borderColor: palette.info.main,
	width: 'calc(100% + 4px)',
	height: 'calc(100% + 4px)',
	margin: '-2px',
	'& .MuiButtonBase-root': {
		background: palette.info.main,
		color: palette.background.paper,
		zIndex: 1,
		borderRadius: 0,
		padding: '5px',
		'& .MuiSvgIcon-root': {
			height: '18px',
			width: '18px',
		},
		'&:hover': {
			color: palette.info.dark,
		},
	},
	'& .MuiGrid-root': {
		position: 'absolute',
		top: 0,
		left: '50%',
		transform: 'translate(-50%,-100%)',
		width: 'fit-content',
	},
}));
export const StyledAddBlock = styled(Grid)(
	({ theme: { palette }, clicked }) => ({
		border: `2px dashed`,
		borderColor: palette.background.grey,
		width: 'min(700px, 100%)',
		maxWidth: '100%',
		paddingBlock: '2vmin',
		marginInline: 'auto',
		transition: 'all 0.25s ease',

		'& .MuiSvgIcon-root': {
			fontSize: 40,
			color: palette.background.grey,
			cursor: 'pointer',
		},
	}),
);
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
	({ theme: { palette } }) => ({
		// border: `1px solid`,
		borderRight: 'unset',
		borderColor: palette.background.grey,
		'& .MuiButtonBase-root': {
			padding: '6px 10px',
			borderRight: `1px solid ${palette.background.grey}`,
		},
	}),
);
