import {
	Card,
	CardContent,
	Link,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { StyledBadge } from '../../styles/inputs';
import { Link as RouterLink } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CustomAvatar from './CustomAvatar';
import { useState } from 'react';
import ActionBtn from './ActionBtn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import { GLOBAL_URL } from '../../api/global';
import useDeleteClient from '../../api/hooks/brands/useDeleteClient';
import { useDataContext } from '../../contexts/DataContext';
import { capitalizeFirstLetter } from '../../utils/globals';

const UserCard = ({ user, editAction }) => {
	const { name, email, image, role } = user;
	const { setData: setUsers } = useDataContext();

	const [isOnline, setIsOnline] = useState();

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const { handleDeleteClient } = useDeleteClient((data) => {
		setUsers((prev) => [
			...prev.filter((user) => user?.id !== data?.data?.id),
		]);
	});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const AvatarWStatus = () => {
		const imagePath = GLOBAL_URL + image;

		return isOnline ? (
			<StyledBadge
				overlap='circular'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				variant='dot'
			>
				<CustomAvatar image={imagePath} name={name} size={70} />
			</StyledBadge>
		) : (
			<CustomAvatar image={imagePath} name={name} size={70} />
		);
	};

	return (
		<Card sx={{ width: '100%', boxShadow: 'none', borderRadius: '32px ' }}>
			<CardContent
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					gap: 1,
					p: 4,
				}}
			>
				{/* <MoreVertIcon
				
					onClick={editAction}
				/> */}

				<MoreVertIcon
					onClick={handleClick}
					sx={{ alignSelf: 'end', cursor: 'pointer' }}
				/>
				<Menu
					id={`brand-item-menu${user?.id}`}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
					elevation={0}
					sx={{
						'& .MuiPaper-root': {
							boxShadow: '0 3px 6px #25232342',
							borderRadius: '16px',
						},
					}}
					anchorOrigin={{
						vertical: 0,
						horizontal: 30,
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<MenuItem
						onClick={() => {
							editAction();
							handleClose();
						}}
					>
						<ListItemIcon>
							<EditOutlinedIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Edit User</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							//add delete functionality
							handleDeleteClient({ id: user?.id });
							handleClose();
						}}
					>
						<ListItemIcon>
							<DeleteOutlinedIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Delete User</ListItemText>
					</MenuItem>
				</Menu>

				<AvatarWStatus />
				<Typography
					variant='h6'
					align='center'
					display={'flex'}
					flexDirection={'column'}
				>
					{name}

					<Typography variant='small' align='center' fontSize={12}>
						{role &&
							(role === 'CLIENT'
								? 'User'
								: capitalizeFirstLetter(role))}
					</Typography>
				</Typography>
				<Link
					color='textSecondary'
					component={RouterLink}
					to={`mailto:${email}`}
					sx={{ textDecoration: 'none' }}
				>
					<ActionBtn
						action={() => {}}
						icon={<LocalPostOfficeOutlinedIcon />}
						text='Send Email'
					/>
				</Link>
			</CardContent>
		</Card>
	);
};

UserCard.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		image: PropTypes.string,
		role: PropTypes.string,
	}).isRequired,
	editAction: PropTypes.func.isRequired,
};

export default UserCard;
