import { useState } from 'react';

import BasicDialog from '../Layouts/BasicDialog';
import PropTypes from 'prop-types';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import useGetBrands from '../../api/hooks/brands/useGetBrands';
import { useFileContext } from '../../contexts/FilesContext';
import {
	Box,
	Grid,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	List,
	Typography,
} from '@mui/material';
import TemplatePlaceholder from '../../assets/images/template.png';
import DashboardSearch from '../Inputs/DashboardSearch';
import GroupToggleButtons from '../Inputs/GroupToggleButtons';
import { BRANDBOOK_STATUS, LANGUAGES } from '../../utils/enums';
import SmallBrandBox from '../Cards/SmallBrandBox';
import useGetAllBrandBooks from '../../api/hooks/brandbooks/useGetAllBrandBooks';
import useCreateBrandBook from '../../api/hooks/brandbooks/useCreateBrandbook';
import { useDisplayContext } from '../../contexts/DisplayContext';
import { useFields } from '../../contexts/FieldsContext';

const BrandDialog = ({ isOpen, setIsOpen }) => {
	const {
		setIsCreating,
		setCurrentBrand,
		language,
		setLanguage,
		handleEditBrandbook,
		setBrandbooks,
	} = useBrandbooksContext();
	const { setBrand, brand } = useFileContext();
	const { handleChangeDisplay } = useDisplayContext();
	const { data: brands } = useGetBrands();
	const { data: templates } = useGetAllBrandBooks(BRANDBOOK_STATUS.TEMPLATE);
	const [search, setSearch] = useState('');
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [chosenTemplate, setChosenTemplate] = useState(null);
	const [activeStep, setActiveStep] = useState(0);

	const { handleCreateBrandbook } = useCreateBrandBook((data) => {
		if (data?.data) {
			setIsOpen(false);
			handleEditBrandbook(data?.data);
			setBrandbooks((prev) => [...prev, data?.data]);
		}
	});

	const handleClose = () => {
		setIsCreating(false);
		handleChangeDisplay();
		setIsOpen(false);
		setActiveStep(0);
	};

	const handleNewBrandbook = () => {
		if (chosenTemplate) {
			const brandBookObj = {
				brand: selectedBrand,
				brandId: selectedBrand?.id,
				bannerPixelCrop: null,
				language,
				fields: [],
				template: chosenTemplate?.id,
			};

			handleCreateBrandbook(brandBookObj);
		} else {
			setBrand(selectedBrand);
			setCurrentBrand(selectedBrand);
			setIsCreating(true);
			setIsOpen(false);
		}
	};

	const nextStep = () => {
		setActiveStep((prev) => prev + 1);
	};

	const actions = [
		{
			actionHandler: handleClose,
			isMain: false,
		},
		{
			actionHandler: activeStep === 0 ? nextStep : handleNewBrandbook,
			isMain: true,
			title: activeStep === 0 ? 'Next' : 'Start Designing',
		},
	];
	console.log(isOpen);
	return (
		<BasicDialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={
				activeStep === 0
					? 'Please Select A Brand'
					: 'Please Select A Template'
			}
			actions={actions}
			// isError={isError}
			handleClose={handleClose}
			maxWidth={600}
		>
			{activeStep === 0 && brands && (
				<Grid container direction={'column'} gap={2}>
					<DashboardSearch
						id='search-brands'
						placeholder='Search brands ...'
						searchAction={({ target: { value } }) => {
							setSearch(value);
						}}
					/>
					<List
						sx={{
							width: '100%',
							display: 'flex',
							gap: '2vmin',
							maxHeight: '300px',
							overflowY: 'scroll',
							flexWrap: 'wrap',
						}}
					>
						{brands.data
							.filter((brand) => brand?.Brandbook.length === 0)
							.filter((b) =>
								b?.name
									.toLowerCase()
									.includes(search.toLowerCase()),
							)
							.map((brandItem) => (
								<SmallBrandBox
									brandItem={brandItem}
									isActive={
										selectedBrand?.id === brandItem?.id
									}
									key={brandItem?.id}
									onClick={(item) => {
										setSelectedBrand(item);
									}}
								/>
							))}
					</List>
					<Grid container alignItems={'center'} gap={2}>
						<Typography variant='p'>
							Brand book language -
						</Typography>
						<GroupToggleButtons
							value={language}
							selectAction={setLanguage}
							buttons={[
								{ value: LANGUAGES.ENG, label: 'English' },
								{ value: LANGUAGES.HEB, label: 'Hebrew' },
							]}
						/>
					</Grid>
				</Grid>
			)}
			{activeStep === 1 && templates && (
				<ImageList cols={3} rowHeight={164} gap={8}>
					<ImageListItem
						sx={{
							border: '1px solid transparent',
							borderColor:
								chosenTemplate == null
									? 'black'
									: 'transparent',
							'&:hover': { borderColor: 'black' },
						}}
						onClick={() => {
							setChosenTemplate(null);
						}}
					>
						<img
							// srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
							// src={`${item.img}?w=248&fit=crop&auto=format`}
							// alt={item.title}
							loading='lazy'
						/>
						<ImageListItemBar
							title={'Blank Template'}
							position='bottom'
						/>
					</ImageListItem>
					{templates.data
						.filter((temp) => temp?.language === language)
						.map((template, index) => (
							<ImageListItem
								key={template?.id}
								sx={{
									filter: 'grayscale(1)',
									border: '1px solid transparent',
									borderColor:
										chosenTemplate?.id == template?.id
											? 'black'
											: 'transparent',
									'&:hover': { borderColor: 'black' },
								}}
								onClick={() => {
									setChosenTemplate(template);
								}}
							>
								<img src={TemplatePlaceholder} loading='lazy' />
								<ImageListItemBar
									title={`Template ${index + 1}`}
									position='bottom'
								/>
							</ImageListItem>
						))}
				</ImageList>
			)}
		</BasicDialog>
	);
};
BrandDialog.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
};
export default BrandDialog;
