import { useEffect } from 'react';

import { useDataContext } from '../../contexts/DataContext';

import { USER_ROLE } from '../../utils/enums';
import { useUser } from '../../contexts/UserContext';

import ManagementLayout from '../../components/Layouts/ManagementLayout';
import useGetBrands from '../../api/hooks/brands/useGetBrands';
import { Grid } from '@mui/material';
import BrandLoader from '../../components/General/BrandLoader';

const Brands = () => {
	const { data, setData: setBrands } = useDataContext();
	const { user } = useUser();
	const { data: brandsRes, isError, error, isLoading } = useGetBrands();

	useEffect(() => {
		if (brandsRes) return setBrands(brandsRes?.data);
		if (error) return; // redirect to error page?? / display error
		// fetchUsers();
	}, [brandsRes, error]);

	if (isLoading) {
		return (
			<Grid
				alignItems={'center'}
				justifyContent={'center'}
				container
				sx={{ height: '100%', width: '100%' }}
			>
				<BrandLoader />
			</Grid>
		);
	}
	return <ManagementLayout userType={USER_ROLE.BRAND} />;
};

export default Brands;
