import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { Box, List } from '@mui/material';

import { generalBox } from '../../styles/layouts';
import { Link } from 'react-router-dom';
import AdminNavItem from './AdminNavItem';
import { useUser } from '../../contexts/UserContext';
import { USER_ROLE } from '../../utils/enums';
import useGetDropboxStatus from '../../api/hooks/general/useGetDropboxStatus';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import { useEffect } from 'react';

const MENU_ITEMS = [
	{ text: 'Home', link: '/', icon: <WindowOutlinedIcon />, role: [] },
	{
		text: 'Team',
		link: '/team',
		icon: <WorkspacesOutlinedIcon />,
		role: [USER_ROLE.ADMIN],
	},
	{ text: 'Users', link: '/users', icon: <GroupsOutlinedIcon />, role: [] },
	{ text: 'Brands', link: '/brands', icon: <StyleOutlinedIcon />, role: [] },
	{
		text: 'File Manager',
		link: '/files',
		icon: <FolderOutlinedIcon />,
		role: [],
	},
	{
		text: 'Brand Books',
		link: '/brandbooks',
		icon: <AutoStoriesOutlinedIcon />,
		role: [],
	},
	{
		text: 'Settings',
		link: '/settings',
		icon: <SettingsSuggestOutlinedIcon />,
		role: [],
	},
];

const AdminNavBar = () => {
	const { user } = useUser();
	const { handleAlert } = useAlert();
	const {
		data: dbxData,
		isError,
		error,
		failureReason,
	} = useGetDropboxStatus();

	useEffect(() => {
		if (isError) {
			handleAlert(ALERT_STATUS.WARNING, error?.response?.data?.error);
		}
	}, [error]);

	return (
		<Box sx={{ ...generalBox, p: 0 }}>
			<List>
				{MENU_ITEMS.filter(
					(item) =>
						item?.role?.length === 0 ||
						item?.role?.includes(user?.role),
				).map((item, index) => (
					<AdminNavItem
						index={index}
						component={Link}
						key={index}
						action={item.link}
						icon={item.icon}
						text={item.text}
						isMinimized={true}
						flag={error && item?.link === '/settings'}
					/>
				))}
			</List>
		</Box>
	);
};

// AdminNavBar.propTypes = {
// 	isMinimized: PropTypes.bool.isRequired,
// 	setIsMinimized: PropTypes.func.isRequired,
// };

export default AdminNavBar;
