import { Box, Grid, Input, Typography } from '@mui/material';

import PropTypes from 'prop-types';

const ContentEditable = ({
	type,
	label,
	value,
	setValue,
	canEdit,
	multiline,
}) => {
	return (
		<Box>
			<Grid
				container
				justifyContent={'space-between'}
				sx={{ border: 'unset !important' }}
			>
				<Typography fontWeight={500}>{label}</Typography>
			</Grid>

			{canEdit ? (
				<Input
					value={value}
					type={type}
					onChange={setValue}
					sx={{
						minHeight: 0,
						'&:hover': {
							bgcolor: 'unset',
						},
						border: 'unset !important',
					}}
					autoComplete='new-password'
					fullWidth
					multiline={multiline}
					maxRows={4}
				/>
			) : (
				<Typography>{value}</Typography>
			)}
		</Box>
	);
};
ContentEditable.propTypes = {
	type: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	setValue: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
	multiline: PropTypes.bool,
};
export default ContentEditable;
