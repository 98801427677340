import { Grid, Typography } from '@mui/material';
import CustomInput from '../../components/Inputs/CustomInput';
import useSendHelp from '../../api/hooks/users/useSendHelp';
import { useState } from 'react';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import GeneralButton from '../../components/Inputs/GeneralButton';

import { validateForm } from '../../utils/globals';

const Support = () => {
	const [formData, setFormData] = useState({
		fromDetails: ['', ''],
		emailMsg: '',
		clientEmail: '',
	});
	const [mailSent, setMailSent] = useState(false);

	const { handleAlert } = useAlert();

	const { handleSendHelp } = useSendHelp((data) => {
		setMailSent(true);

	});

	const handleSubmit = (e) => {
		e.preventDefault();

		if (mailSent) {
			setFormData({
				fromDetails: ['', ''],
				emailMsg: '',
				clientEmail: '',
			});
			return e.target.reset();
		}
		if (
			!formData.fromDetails.length ||
			!formData.fromDetails[0] ||
			!formData.fromDetails[1]
		)
			return handleAlert(ALERT_STATUS.ERROR, 'Missing name / number.');

		if (!formData.emailMsg)
			return handleAlert(ALERT_STATUS.ERROR, 'Missing message.');
		if (!formData.clientEmail)
			return handleAlert(ALERT_STATUS.ERROR, 'Missing email address.');
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!emailRegex.test(formData.clientEmail))
			return handleAlert(
				ALERT_STATUS.ERROR,
				'Please enter a valid email address.',
			);

		const dataToSend = {
			...formData,
			fromDetails: formData.fromDetails.join(' - '),
		};
		// if (client) dataToSend.clientEmail = client.email;


		handleSendHelp(dataToSend);
	};
	return (
		<Grid
			container
			flexDirection={'column'}
			sx={{
				width: 'min(774px, 95%)',
				'.MuiFormLabel-root': {
					fontSize: 16,
					color: 'rgba(0, 0, 0, 0.5)',
				},
				'.MuiInputBase-root': {
					background: 'rgba(255, 255, 255, 1)',
					border: '0.5px solid rgba(153, 153, 153, 1)',
					minHeight: 51,
				},
			}}
		>
			<Typography variant='h1' fontSize={24} color='rgba(0, 0, 0, 0.5)'>
				How can we help?
			</Typography>

			<form
				onSubmit={handleSubmit}
				style={{ width: '100%' }}
				noValidate={true}
			>

				<CustomInput
					label='Please summarize your request *'
					inputType='text'
					multiline={true}
					rows={5}

					mt={2.5}


					name={'emailMsg'}
					value={formData.emailMsg}
					action={({ target: { value } }) => {
						setFormData((prev) => ({
							...prev,
							emailMsg: value,
						}));
					}}
				/>
				<Grid container flexWrap={'nowrap'} gap={3}>
					<CustomInput
						label='Your name*'
						inputType='text'
						name='name'

						mt={2.5}
						value={formData.fromDetails[0]}
						action={({ target: { value } }) => {
							setFormData((prev) => ({
								...prev,
								fromDetails: [value, prev.fromDetails[1]],

							}));
						}}
					/>
					<CustomInput
						label='Your phone number *'
						inputType='tel'
						name='phoneNumber'

						mt={2.5}
						value={formData.fromDetails[1]}
						action={({ target: { value } }) => {
							setFormData((prev) => ({
								...prev,
								fromDetails: [prev.fromDetails[0], value],

							}));
						}}
					/>
				</Grid>
				<CustomInput
					label='Your login email *'
					inputType='email'
					name='email'

					mt={2.5}
					value={formData.clientEmail}
					action={({ target: { value } }) => {
						setFormData((prev) => ({
							...prev,
							clientEmail: value,
						}));
					}}
				/>
				<GeneralButton
					mt={'24px'}
					type='submit'
					sx={{ width: 'fit-content', minWidth: 232 }}
				>
					{mailSent ? 'Done!' : 'SUBMIT'}
				</GeneralButton>
			</form>

			{mailSent && (
				<>
					<Typography variant='h1' fontSize={24} mt={4}>
						Thank you.
					</Typography>
					<Typography>
						Support will be following up with you shortly.
					</Typography>
				</>
			)}

		</Grid>
	);
};

export default Support;
