import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { addBrandBook } from '../../brandbooks';

const useCreateBrandBook = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleCreateBrandbook, isPending } = useMutation({
		mutationFn: (data) => addBrandBook(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleCreateBrandbook, isPending };
};

export default useCreateBrandBook;
