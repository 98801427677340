import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { CLIENT_DOMAIN } from '../api/global';

export const ALERT_STATUS = {
	ERROR: 'error',
	INFO: 'info',
	SUCCESS: 'success',
	WARNING: 'warning',
};

export const AlertContext = createContext({
	alerts: [],
	handleAlert: (status, message) => {},
	closeAlert: (id) => {},
});
export const useAlert = () => useContext(AlertContext);

const AlertContextProvider = ({ children }) => {
	const queryClient = useQueryClient();
	const [alerts, setAlerts] = useState([]);

	const handleAlert = (status, message) => {
		const id = new Date().getTime(); // unique id for each alert
		setAlerts([...alerts, { id, status, message }]);
	};

	const closeAlert = (id) => {
		setAlerts(alerts.filter((alert) => alert.id !== id));
	};
	const handleNetworkError = (err) => {
		if (err.code === 'ERR_NETWORK') {
			handleAlert(ALERT_STATUS.ERROR, err?.message);
			if (window.location.href.includes('login')) return;
			window.location.href = CLIENT_DOMAIN + 'login';
		}
	};

	queryClient.setDefaultOptions({
		queries: {
			refetchOnWindowFocus: false,
			// retry: false,
			throwOnError: handleNetworkError,
		},
		mutations: {
			throwOnError: handleNetworkError,
		},
	});
	return (
		<AlertContext.Provider value={{ alerts, handleAlert, closeAlert }}>
			{children}
			{alerts.map((alert) => (
				<Snackbar
					key={alert.id}
					open={true}
					autoHideDuration={7000}
					onClose={() => closeAlert(alert.id)}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<MuiAlert
						onClose={() => closeAlert(alert.id)}
						severity={alert.status}
						variant='filled'
						sx={{
							width: 'min(300px, 75vw)',
						}}
					>
						{alert.message}
					</MuiAlert>
				</Snackbar>
			))}
		</AlertContext.Provider>
	);
};

AlertContextProvider.propTypes = {
	children: PropTypes.any.isRequired,
};

export default AlertContextProvider;
