import { Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import useVerifyAuthCode from '../../api/hooks/users/useVerifyAuthCode';

import { useLogin, FORM_STEPS } from '../../contexts/LoginContext';
import CustomVerificationInput from '../Inputs/CustomVerificationInput';
import useCheckRemainingTime from '../../api/hooks/users/useCheckRemainingTime';

import useResendCode from '../../api/hooks/users/useResendCode';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';

const StepAuth = ({ handleNextStep, resend, setUserCred, userCred }) => {
	//move to custom hook
	const { handleAlert } = useAlert();
	const {
		data,
		isLoading: timerLoading,
		refetch: refetchTimeRemaining,
	} = useCheckRemainingTime(userCred);
	const [remainingTime, setRemainingTime] = useState(0);
	const [lastSetCode, setLastSetCode] = useState(data?.timeRemaining || null);
	const minutes = Math.floor(remainingTime / (1000 * 60));
	const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
	const { setCurrentStep, setIsLoading, email } = useLogin();

	const { handleResendCode } = useResendCode((data) => {
		handleAlert(ALERT_STATUS.SUCCESS, data.message);
		refetchTimeRemaining();
	});

	const handleAfterVerify = ({ data }) => {
		setLastSetCode(null);
		setIsLoading(true);
		if (data.data.isFirstTime) {
			setTimeout(() => {
				setCurrentStep(FORM_STEPS.NEW_PASS);
				setIsLoading(false);
			}, 1000);
		} else {
			handleNextStep(data.data);
		}
	};

	const { verifyCode } = useVerifyAuthCode(handleAfterVerify);

	function calculateRemainingTime() {
		const updatedAtTime = new Date(lastSetCode).getTime();
		const currentTime = new Date().getTime();
		const difference = currentTime - updatedAtTime;
		const fiveMinutesInMillis = 5 * 60 * 1000;
		return Math.max(fiveMinutesInMillis - difference, 0);
	}

	useEffect(() => {
		if (!lastSetCode) return;
		const timerInterval = setInterval(() => {
			const newRemainingTime = calculateRemainingTime();
			setRemainingTime(newRemainingTime);
		}, 1000);

		return () => clearInterval(timerInterval);
	}, [lastSetCode]);

	useEffect(() => {
		if (!email) return;

		setUserCred((prev) => ({ ...prev, email }));
	}, [email]);

	useEffect(() => {
		setLastSetCode(data?.timeRemaining);
	}, [data]);

	return (
		<>
			<Typography variant='h1' align='center'>
				Two-Factor Authentication
			</Typography>
			<Typography
				variant='p'
				mb={'auto !important'}
				align='center'
				fontWeight={500}
			>
				A six-digit authentication code was sent to {userCred?.email}
			</Typography>
			<CustomVerificationInput
				email={userCred?.email}
				verifyCode={verifyCode}
			/>

			<Typography
				variant='p'
				mt={'auto !important'}
				align='center'
				fontWeight={500}
			>
				Didn&apos;t receive a code?&nbsp;
				<Link
					component='button'
					sx={{ pointerEvents: remainingTime !== 0 ? 'none' : 'all' }}
					onClick={() => {
						// need to add functionality to resend code
						handleResendCode({ email: userCred?.email });
					}}
				>
					Resend
					{remainingTime === 0
						? null
						: ` in ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
				</Link>
			</Typography>
		</>
	);
};

StepAuth.propTypes = {
	handleNextStep: PropTypes.func,
	resend: PropTypes.func,
	setUserCred: PropTypes.func,
	userCred: PropTypes.object,
};
export default StepAuth;
