// DisplayContext.js
import { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const DISPLAY = {
	CARDS: 'CARDS',
	BUILDER: 'BUILDER',
};
const DisplayContext = createContext();

export const useDisplayContext = () => useContext(DisplayContext);

export const DisplayProvider = ({ children }) => {
	const [displayMode, setDisplayMode] = useState(DISPLAY.CARDS);
	const [isPreviewing, setIsPreviewing] = useState(false);

	const handleChangeDisplay = () => {
		setDisplayMode(
			displayMode === DISPLAY.CARDS ? DISPLAY.BUILDER : DISPLAY.CARDS,
		);
		setIsPreviewing(false);
	};

	const contextValue = {
		displayMode,
		setDisplayMode,
		isPreviewing,
		setIsPreviewing,
		handleChangeDisplay,
	};

	return (
		<DisplayContext.Provider value={contextValue}>
			{children}
		</DisplayContext.Provider>
	);
};
DisplayProvider.propTypes = {
	children: PropTypes.any.isRequired,
};
