import { createTheme, darken, lighten } from '@mui/material/styles';
import '@fontsource-variable/rubik';
import '@fontsource/heebo';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import palette from './palette';

// A custom theme for this app
const theme = createTheme({
	palette,
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					color: palette.text.link,
					fontWeight: 500,
					textDecorationColor: 'inherit',
					cursor: 'pointer',
					'&:hover': {
						color: darken(palette.text.link, 0.2),
					},
				},
			},
			underline: 'always',
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 8,
					textTransform: 'capitalize',
					color: palette.text.secondary,
				},
				contained: {
					boxShadow: 'unset',
					minHeight: 43,
					minWidth: 165,
					'&:hover': {
						boxShadow: '0px 9px 14px 0px rgba(74, 74, 74, 0.50)',
					},
				},
				outlined: {
					boxShadow: 'unset',
				},
				outlined_black: {
					border: `1px solid ${palette.text.primary}`,
					fontWeight: 500,
					textTransform: 'uppercase',
					'&:hover': {
						background: '#BBBABA',
					},
				},
			},
		},

		MuiInputBase: {
			styleOverrides: {
				root: {
					marginTop: 6,
					borderRadius: 5,
					background: 'transparent',
					border: '0.5px solid #777777',
					fontSize: 16,
					minHeight: 56,
					paddingInline: 8,
					'&.Mui-focused': {
						borderColor: palette.primary.main,
					},
					'&:hover': {
						background: lighten(palette.primary.light, 0.8),
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					position: 'static',
					transform: 'unset',
					color: palette.text.primary,
					fontWeight: 500,
					fontSize: 14,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				h1: {
					fontSize: 32,
				},
			},
		},
		MuiGrid: {
			styleOverrides: {
				item: {
					display: 'flex',
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltipArrow: {
					background: palette.primary.main,
					color: palette.text.primary,
				},
				arrow: {
					color: palette.primary.main,
				},
			},
		},
		MuiTextField: {
			variants: [
				{
					props: { variant: 'mini' },
					style: {
						// Define custom styles for the 'custom' variant
						border: '2px solid green',
						borderRadius: '12px',
						padding: '12px',
						// Add any other custom styles you need
					},
				},
			],
		},
	},
	typography: {
		fontFamily: ['Poppins', 'Heebo', 'Rubik Variable'].join(','),
	},
});

export default theme;
