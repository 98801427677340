import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import useGetFoldersByBrand from '../../api/hooks/files/useGetFoldersByBrand';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AssetsStrip from './AssetsStrip';

const AssetsDisplay = ({ brand, setCurrentAsset, dir }) => {
	const { data: folders } = useGetFoldersByBrand(brand?.id);
	const [filterFolder, setFilterFolder] = useState('');

	let [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (location.search?.includes('type')) {
			setFilterFolder(searchParams.get('type'));
		} else {
			setFilterFolder('');
		}
	}, [location, searchParams]);

	return (
		<Grid
			container
			direction={'column'}
			pb={4}
			sx={{ scrollMarginBlockStart: '40px' }}
		>
			{folders?.data
				.filter((item) => item?.Asset.length)
				.filter((item) =>
					item?.name
						.toLowerCase()
						?.includes(
							location.search?.includes('type')
								? filterFolder
								: '',
						),
				)
				.map((folder) => {
					return (
						<AssetsStrip
							key={folder?.id}
							setCurrentAsset={setCurrentAsset}
							folder={folder}
							filterFolder={filterFolder}
							setSearchParams={setSearchParams}
						/>
					);
				})}
		</Grid>
	);
};
AssetsDisplay.propTypes = {
	brand: PropTypes.object,
	setCurrentAsset: PropTypes.func,
	dir: PropTypes.string,
};
export default AssetsDisplay;
