import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';

import PropTypes from 'prop-types';
import { percentageToColumns, populateDefaultFieldData } from '../utils/fields';
import { ASSET_TYPES } from '../utils/enums';
import useFlatFields from '../hooks/useFlatFields';

export const FieldsContext = createContext({});

export const FieldType = {
	TEXT: 'TEXT',
	IMAGE: 'IMAGE',
	CARD: 'CARD',
	HEADING: 'HEADING',
	COLUMNS: 'COLUMNS',
	SLIDER: 'SLIDER',
};
export const useFields = () => useContext(FieldsContext);

const FieldsContextProvider = ({ children }) => {
	const [currentField, setCurrentField] = useState(null);
	const [newBlockIndex, setNewBlockIndex] = useState(null);
	const [deletedFields, setDeletedFields] = useState([]);
	const [updatedFields, setUpdatedFields] = useState([]);
	const [newFields, setNewFields] = useState([]);
	const [brandBookFields, setBrandBookFields] = useState([]);

	const {
		flatFields,
		findField,
		lastFieldIndex,
		newFieldIndex,
		updateFields,
	} = useFlatFields(brandBookFields);

	const handleAddField = (fieldType, isInColumn, columnIndex = null) => {
		const field = populateDefaultFieldData(fieldType);
		field.isInColumn = isInColumn;
		field.columnIndex = columnIndex;

		return field;
	};

	const handleIndexingFields = (
		fieldType,
		index,
		isInColumn,
		columnIndex,
		positionInColumn = null,
	) => {
		const newField = handleAddField(fieldType, isInColumn, columnIndex);

		if (index && !isInColumn) {
			newField.index = index;
			const newFieldsIndexed = [
				...updateFields(
					[...brandBookFields, ...newFields].sort(
						(a, b) => a.index - b.index,
					),
					index,
				),
			];
			const updated = [...updatedFields];
			updated.forEach((field) => {
				const fieldInAll = newFieldsIndexed.find(
					(f) => f?.id === field?.id,
				);
				if (fieldInAll) {
					field.index = fieldInAll?.index;
				}
			});
			const copyNewFields = [...newFieldsIndexed.filter((f) => !f?.id)];
			copyNewFields.push(newField);
			setNewFields(copyNewFields);
			setBrandBookFields(newFieldsIndexed.filter((f) => f?.id));
			setUpdatedFields(updated);
		} else if (isInColumn && !index) {
			console.log(findField(columnIndex));
			const columnField = findField(columnIndex)
				? { ...findField(columnIndex) }
				: { ...newFields.find((f) => f.index === columnIndex) };

			if (positionInColumn !== undefined) {
				console.log({ ...columnField });
				columnField.value[positionInColumn] = newField;

				columnField.total = columnField.value.length;
				columnField.width = Array(columnField.total).fill(
					percentageToColumns(Math.floor(100 / columnField.total)),
				);
				let key = 0;
				for (const child of columnField.value) {
					if (!child) continue;
					const newIndex =
						key === 0
							? columnIndex
							: columnField.value[key - 1]
							? columnField.value[key - 1]?.index
							: Math.max(
									...columnField.value
										.map((field) => field?.index)
										.filter((item) => item),
							  );

					child.index = newIndex + 1;
					key++;
				}
			}

			const newMaxIndex = Math.max(
				...columnField.value
					.map((field) => field?.index)
					.filter((item) => item),
			);

			const newFieldsIndexed = [
				...updateFields(
					[...brandBookFields, ...newFields].sort(
						(a, b) => a?.index - b?.index,
					),
					newMaxIndex,
				),
			];
			const updated = [...updatedFields];
			updated.forEach((field) => {
				const fieldInAll = newFieldsIndexed.find(
					(f) => f?.id === field?.id,
				);
				if (fieldInAll) {
					field.index = fieldInAll?.index;
				}
			});

			if (columnField?.id) {
				const columnInUpdated = updated.findIndex(
					(field) => field?.id === columnField?.id,
				);
				if (columnInUpdated !== -1) {
					updated[columnInUpdated] = columnField;
				} else {
					updated.push(columnField);
				}
				setUpdatedFields(updated);
			} else {
				const copyNewFields = [
					...newFieldsIndexed.filter((f) => !f?.id),
				];
				const columnInNew = copyNewFields.findIndex(
					(field) => field?.index === columnField?.index,
				);
				if (columnInNew !== -1) {
					copyNewFields[columnInNew] = columnField;
				} else {
					copyNewFields.push(columnField);
				}
				setNewFields(copyNewFields);
			}

			setBrandBookFields(newFieldsIndexed.filter((f) => f?.id));
		} else {
			const lastIndex = lastFieldIndex(
				[...flatFields, ...newFields].sort(
					(a, b) => a?.index - b?.index,
				),
			);
			newField.index = lastIndex + 1;

			setNewFields((prev) => [...prev, newField]);
		}

		setCurrentField(newField);
		setNewBlockIndex(null);
	};

	const renderProp = (index, key) => {
		return currentField?.index === index ? currentField[key] : key;
	};

	const handleSaveField = (updatedField) => {
		const updatedArray = [...updatedFields];
		const created = [...newFields];

		if (updatedField?.isInColumn) {
			const columnIndex = updatedArray.findIndex(
				(item) => item?.index === updatedField?.columnIndex,
			);
			const newColumnIndex = created.findIndex(
				(item) => item?.index === updatedField?.columnIndex,
			);

			const columnField = { ...findField(updatedField?.columnIndex) };

			if (newColumnIndex !== -1) {
				const fieldIndex = created[newColumnIndex]?.value.findIndex(
					(child) => child?.index === updatedField?.index,
				);
				if (fieldIndex !== -1) {
					created[newColumnIndex].value[fieldIndex] = updatedField;
					setNewFields(created);
				}
			} else if (columnIndex !== -1) {
				const valueIndex = updatedArray[columnIndex].value.findIndex(
					(child) => child?.index === updatedField?.index,
				);
				if (valueIndex !== -1) {
					updatedArray[columnIndex].value[valueIndex] = updatedField;
					setUpdatedFields(updatedArray);
				}
			} else if (columnField?.id) {
				const fieldIndex = columnField.value.findIndex(
					(child) => child?.index === updatedField?.index,
				);
				if (fieldIndex !== -1) {
					columnField.value[fieldIndex] = updatedField;
					updatedArray.push(columnField);
					setUpdatedFields(updatedArray);
				}
			}
		} else if (updatedField?.id && typeof updatedField?.id === 'string') {
			const isInArray = updatedArray.findIndex(
				(item) => item?.id === updatedField?.id,
			);
			if (isInArray !== -1) {
				updatedArray[isInArray] = updatedField;
			} else {
				updatedArray.push(updatedField);
			}

			setUpdatedFields(updatedArray);
		} else {
			const isInNewArray = created.findIndex(
				(f) => f?.index === updatedField?.index,
			);

			if (isInNewArray !== -1) {
				created[isInNewArray] = updatedField;
				setNewFields(created);
			}
		}
		setCurrentField(null);
	};

	const handleEditField = useCallback(
		(field) => {
			currentField !== null && handleSaveField(currentField);
			setCurrentField(field);
		},
		[currentField],
	);

	const handleDeleteField = useCallback(
		(field) => {
			currentField !== null && handleSaveField(currentField);
			const updated = [...updatedFields];
			const created = [...newFields];
			if (field?.isInColumn) {
				const column = findField(field?.columnIndex);

				if (column && column?.id) {
					const columnInUpdated = updated.findIndex(
						(f) => f?.index === field?.columnIndex,
					);
					if (columnInUpdated !== -1) {
						const fieldIndex = updated[
							columnInUpdated
						].value.findIndex(
							(child) => child?.index === field?.index,
						);
						if (fieldIndex !== -1) {
							updated[columnInUpdated].value[fieldIndex] = null;
							setUpdatedFields(updated);
						}
					} else {
						const fieldIndex = column.value.findIndex(
							(child) => child?.index === field?.index,
						);
						if (fieldIndex !== -1) {
							column.value[fieldIndex] = null;
							setUpdatedFields((prev) => [...prev, column]);
						}
					}
					//check if in updated, if not add to updated
				} else {
					const columnInCreated = created.findIndex(
						(f) => f?.index === field?.columnIndex,
					);
					if (columnInCreated !== -1) {
						const fieldIndex = created[
							columnInCreated
						].value.findIndex(
							(child) => child?.index === field?.index,
						);
						if (fieldIndex !== -1) {
							created[columnInCreated].value[fieldIndex] = null;
							setNewFields(created);
						}
					}
				}
			} else if (field?.id && typeof field?.id === 'string') {
				setDeletedFields((prev) => [...prev, field?.id]);

				const wasUpdated = updated.findIndex(
					(f) => f?.id === field?.id,
				);
				if (wasUpdated !== -1) {
					updated.filter((f) => f?.id !== field?.id);
					setUpdatedFields(updated);
				}
			} else {
				setNewFields(
					created.filter((item) => item?.index !== field?.index),
				);
			}
		},
		[currentField, flatFields],
	);

	const resetFieldsContext = () => {
		setCurrentField(null);
		setDeletedFields([]);
		setUpdatedFields([]);
		setNewFields([]);
	};

	const contextValue = useMemo(
		() => ({
			FieldType,
			currentField,
			setCurrentField,
			handleSaveField,
			handleEditField,
			handleDeleteField,
			newBlockIndex,
			setNewBlockIndex,
			resetFieldsContext,
			deletedFields,
			setDeletedFields,
			updatedFields,
			setUpdatedFields,
			newFields,
			setNewFields,
			renderProp,
			handleIndexingFields,
			brandBookFields,
			setBrandBookFields,
		}),
		[
			currentField,
			newBlockIndex,
			updatedFields,
			deletedFields,
			newFields,
			brandBookFields,
			flatFields,
		],
	);

	return (
		<FieldsContext.Provider value={contextValue}>
			{children}
		</FieldsContext.Provider>
	);
};

FieldsContextProvider.propTypes = {
	children: PropTypes.any.isRequired,
};

export default FieldsContextProvider;
