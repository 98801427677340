import { useQuery } from '@tanstack/react-query';

import { getFoldersByBrand } from '../../files';
import { useEffect } from 'react';

const useGetFoldersByBrand = (brandId, searchTerm = '') => {
	const QUERY_KEY = ['getFolders', brandId, searchTerm];

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getFoldersByBrand(brandId, searchTerm),
		enabled: brandId !== undefined && brandId !== null,
	});

	useEffect(() => {
		if (brandId !== undefined && brandId !== null) {
			refetch();
		}
	}, [brandId, refetch, searchTerm]);

	return { data, isLoading, isError, error };
};

export default useGetFoldersByBrand;
