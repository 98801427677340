import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { updateBrand } from '../../brands';

const useUpdateBrand = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleUpdateBrand, isPending } = useMutation({
		mutationFn: ({ id, ...changedFields }) =>
			updateBrand({ ...changedFields, id }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleUpdateBrand, isPending };
};

export default useUpdateBrand;
