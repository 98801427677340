import { Grid, IconButton, Paper, Slide } from '@mui/material';
import BannerBrandbook from '../Headers/BannerBrandbook';
import AddBlock from '../Inputs/AddBlock';
import PropTypes from 'prop-types';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import { Fragment, useEffect, useRef, useState } from 'react';
import { handleSetBrandBookFields, renderComponent } from '../../utils/fields';
import { useFields } from '../../contexts/FieldsContext';
import BrandLoader from '../General/BrandLoader';
import useGetFieldsByBrandbook from '../../api/hooks/brandbooks/useGetBrandbookFields';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const BrandbookBuilder = ({ setIsChoosingAsset, layoutInView }) => {
	const { currentBrandBook } = useBrandbooksContext();
	const {
		newBlockIndex,
		deletedFields,
		updatedFields,
		newFields,
		brandBookFields,
		currentField,
		setBrandBookFields,
		handleIndexingFields,
	} = useFields();

	const [loading, setLoading] = useState(false);
	const { data, isLoading, isError } = useGetFieldsByBrandbook(
		currentBrandBook?.id,
	);

	const handleRemoveDeleted = (item) => {
		return !deletedFields.find((f) => f === item?.id);
	};
	const handleGetUpdatedField = (id) => {
		return updatedFields.find((field) => field?.id === id);
	};

	useEffect(() => {
		setLoading(true);

		const timeoutId = setTimeout(() => {
			setLoading(false);
		}, 2500);

		return () => clearTimeout(timeoutId);
	}, [currentBrandBook]);

	useEffect(() => {
		if (data?.data)
			setBrandBookFields(handleSetBrandBookFields(data?.data));
	}, [data]);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2,
				width: '100%',
				borderRadius: '32px',
				minHeight: '70vh',
				direction: currentBrandBook?.language === 'ENG' ? 'ltr' : 'rtl',
			}}
		>
			{loading ? (
				<Grid
					container
					alignItems={'center'}
					justifyContent={'center'}
					minHeight={'70vh'}
				>
					<BrandLoader />
				</Grid>
			) : (
				<>
					<BannerBrandbook setIsChoosingAsset={setIsChoosingAsset} />
					{[...brandBookFields, ...newFields]
						?.filter(handleRemoveDeleted)
						.sort((a, b) => a.index - b.index)
						.map((obj, index) => {
							const updatedField = obj?.id
								? handleGetUpdatedField(obj?.id)
								: null;
							const objToRender = updatedField || obj;
							return (
								<Fragment key={index}>
									{newBlockIndex === objToRender.index &&
										currentField == null && <AddBlock />}
									{renderComponent(
										objToRender,
										setIsChoosingAsset,
										handleIndexingFields,
									)}
								</Fragment>
							);
						})}
				</>
			)}
			{!loading && <AddBlock />}
			{
				<Slide
					direction='up'
					in={!layoutInView}
					mountOnEnter
					unmountOnExit
				>
					<Grid
						position={'fixed'}
						right={'3vmin'}
						bottom={'2vmin'}
						container
						alignItems={'center'}
						gap={1}
						width={'fit-content'}
						sx={{ cursor: 'pointer' }}
						onClick={() => {
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}}
					>
						<IconButton
							disableRipple
							sx={{ border: '1px solid #4a4a4a' }}
						>
							<ArrowUpwardIcon />
						</IconButton>
					</Grid>
				</Slide>
			}
		</Paper>
	);
};

BrandbookBuilder.propTypes = {
	setIsChoosingAsset: PropTypes.func,
	layoutInView: PropTypes.bool,
};
export default BrandbookBuilder;
