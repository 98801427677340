import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { formatSpacingValues } from '../../utils/fields';

const TextDisplay = ({ field }) => {
	return (
		<Box
			sx={{
				fontSize: '16px',
				padding: formatSpacingValues(field?.padding),
				margin: formatSpacingValues(field?.margin),
				color: '#00000080',
				'& strong': {
					fontWeight: 600,
				},
			}}
		>
			<div
				dangerouslySetInnerHTML={{
					__html: field?.value,
				}}
			/>
		</Box>
	);
};
TextDisplay.propTypes = {
	field: PropTypes.object,
};
export default TextDisplay;
