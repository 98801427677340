import PropTypes from "prop-types";
import BasicMenuWrapper from "../Layouts/BasicMenuWrapper";
import { RgbColorPicker } from "react-colorful";

const ColorPickerMenu = ({ anchorEl, setAnchorEl, color, setColor }) => {
  return (
    <BasicMenuWrapper anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <RgbColorPicker
        color={color}
        onChange={setColor}
        style={{ width: "150px", height: "150px" }}
      />
    </BasicMenuWrapper>
  );
};
ColorPickerMenu.propTypes = {
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func,
  color: PropTypes.any,
  setColor: PropTypes.func,
};

export default ColorPickerMenu;
