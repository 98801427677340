import { useEffect, useState } from 'react';
import PageHeader from '../Headers/PageHeader';
import { Button, Grid, IconButton, Snackbar } from '@mui/material';
import { useDataContext } from '../../contexts/DataContext';
import { USER_ROLE } from '../../utils/enums';
import GeneralButton from '../Inputs/GeneralButton';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PropTypes from 'prop-types';
import NoResults from './NoResults';
import UserDrawer from '../Drawers/UserDrawer';
import UserCard from '../Cards/UserCard';
import BrandDrawer from '../Drawers/BrandDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BrandCard from '../Cards/BrandCard';
import useGetUsers from '../../api/hooks/users/useGetUsers';
import palette from '../../styles/palette';
import useDeleteBrand from '../../api/hooks/brands/useDeleteBrand';
const PENDING_DURATION = 1000 * 30;

const ManagementLayout = ({ userType }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [currClient, setCurrClient] = useState(null);
	const [filter, setFilter] = useState('');
	const [pendingDelete, setPendingDelete] = useState(null);
	const { data: clients } = useGetUsers(
		userType === USER_ROLE.BRAND ? USER_ROLE.CLIENT : userType,
		[],
		userType === USER_ROLE.CLIENT || userType === USER_ROLE.BRAND,
	);
	const [clientsList, setClientsList] = useState([]);
	const {
		data,
		setData,
		handleCreate,
		setCurrentItem,
		setIsCreating,
		setIsEditing,
	} = useDataContext();

	const { handleDeleteBrand } = useDeleteBrand((data) => {
		setData((prev) => [...prev.filter((brand) => brand.id !== data?.data)]);
	});

	const AddUser = (mt) => {
		return (
			<GeneralButton
				icon={<PersonAddAltOutlinedIcon />}
				mt={mt}
				onClick={() => {
					const newUser = {
						email: null,
						password: null,
						image: null,
						name: null,
					};

					handleCreate(newUser);
					setIsDrawerOpen(true);
				}}
			>
				Add{' '}
				{userType === USER_ROLE.TEAM
					? 'Team Member'
					: userType === USER_ROLE.CLIENT
					? 'User'
					: 'Brand'}
			</GeneralButton>
		);
	};

	const handleEdit = (itemId) => {
		setIsCreating(false);
		setIsEditing(true);
		setCurrentItem(data.find((item) => item.id === itemId));
		setIsDrawerOpen(true);
	};

	useEffect(() => {
		if (userType !== USER_ROLE.BRAND) return;
		if (clients === null) return;
		setClientsList(clients?.data);
	}, [userType, clients]);

	useEffect(() => {
		if (isDialogOpen === false) setCurrClient(null);
	}, [isDialogOpen]);

	return (
		<>
			<Grid container wrap='nowrap' gap={5} mt={4}>
				<Grid container direction={'column'}>
					<PageHeader
						title={
							userType === USER_ROLE.TEAM
								? 'Team Management'
								: userType === USER_ROLE.CLIENT
								? 'User Management'
								: 'Brand Management'
						}
						userType={userType}
						button={data.length ? AddUser(0) : undefined}
						mt={5}
					/>
					<Grid container>
						<Grid
							container
							spacing={4}
							sx={{ px: 4 }}
							alignItems={'stretch'}
							flex={1}
						>
							{data.length ? (
								data.map((item) => (
									<Grid
										item
										lg={3}
										md={4}
										sm={6}
										xs={12}
										key={item.id}
										mt={4}
									>
										{userType === USER_ROLE.BRAND ? (
											<BrandCard
												brand={item}
												editAction={() => {
													handleEdit(item.id);
												}}
												deleteAction={(itemId) => {
													setPendingDelete(itemId);
												}}
												pendingDelete={pendingDelete}
											/>
										) : (
											<UserCard
												user={item}
												editAction={() => {
													handleEdit(item.id);
												}}
											/>
										)}
									</Grid>
								))
							) : (
								<NoResults
									button={AddUser(3)}
									userType={
										userType === USER_ROLE.TEAM
											? 'team members'
											: userType === USER_ROLE.CLIENT
											? 'users'
											: 'brands'
									}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{(userType === USER_ROLE.TEAM || userType === USER_ROLE.CLIENT) && (
				<UserDrawer
					isOpen={isDrawerOpen}
					setIsOpen={setIsDrawerOpen}
					userRole={userType}
				/>
			)}
			{userType === USER_ROLE.BRAND && (
				<BrandDrawer
					isOpen={isDrawerOpen}
					setIsOpen={setIsDrawerOpen}
					clientsList={clientsList}
				/>
			)}

			{userType === USER_ROLE.BRAND && (
				<Snackbar
					open={Boolean(pendingDelete)}
					autoHideDuration={PENDING_DURATION}
					onClose={() => {
						if (pendingDelete) {
							handleDeleteBrand(pendingDelete);
						}
					}}
					message='You are deleting a brand and all its related content (assets, brand books, folders).'
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					action={
						<>
							<Button
								size='small'
								color='primary'
								onClick={() => {
									setPendingDelete(null);
								}}
							>
								Undo
							</Button>
							<IconButton
								onClick={() => {
									handleDeleteBrand(pendingDelete);
									setPendingDelete(null);
								}}
							>
								<CloseOutlinedIcon color='text.dark' />
							</IconButton>
						</>
					}
					sx={{
						'& .MuiPaper-root': {
							background: palette.info.light,
							boxShadow: 'unset',
							color: palette.text.primary,
						},
					}}
				/>
			)}
		</>
	);
};

ManagementLayout.propTypes = {
	userType: PropTypes.string,
};
export default ManagementLayout;
