import {
	Box,
	Checkbox,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
} from '@mui/material';
import { StyledAutoComplete, StyledPopper } from '../../styles/inputs';
import PropTypes from 'prop-types';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

const CustomAutocomplete = ({
	options,
	label,
	onSelect,
	disabledOptions,
	titleField,
	icon,
	sx,
}) => {
	return (
		<StyledAutoComplete
			blurOnSelect={true}
			options={options}
			getOptionLabel={(option) => option[`${titleField}`]}
			getOptionKey={(option) => option?.id}
			getOptionDisabled={(option) =>
				disabledOptions?.some((op) => op?.id === option?.id)
			}
			id={'autocomplete'}
			disablePortal={true}
			onChange={(event, value) => {
				onSelect(value);
			}}
			PaperComponent={({ children }) => (
				<StyledPopper>
					<Box>{children}</Box>
				</StyledPopper>
			)}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						placeholder={label}
						variant='outlined'
					/>
				);
			}}
			renderOption={(props, option, { selected }) => (
				<ListItemButton component='li' {...props}>
					{icon && (
						<ListItemIcon>
							<ControlPointOutlinedIcon />
						</ListItemIcon>
					)}
					<ListItemText
						primary={option[`${titleField}`]}
						secondary={option?.email}
					/>
				</ListItemButton>
			)}
			sx={{ ...sx }}
		/>
	);
};
CustomAutocomplete.propTypes = {
	options: PropTypes.array.isRequired,
	disabledOptions: PropTypes.array.isRequired,
	label: PropTypes.string,
	onSelect: PropTypes.func,
	titleField: PropTypes.string,
	icon: PropTypes.bool,
	sx: PropTypes.object,
};
export default CustomAutocomplete;
