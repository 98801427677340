import { Menu } from "@mui/material";
import PropTypes from "prop-types";
const BasicMenuWrapper = ({ children, sx, anchorEl, setAnchorEl }) => {
  const open = Boolean(anchorEl);
  return (
    <Menu
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      elevation={0}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "0 3px 6px #25232342",
          borderRadius: "24px",
          padding: 1,
          overflow: "visible",
        },
        "& .MuiList-root": {
          paddingBlock: 0,
        },
        ...sx,
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        setAnchorEl(null);
      }}
    >
      {children}
    </Menu>
  );
};
BasicMenuWrapper.propTypes = {
  children: PropTypes.any,
  sx: PropTypes.object,
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func,
};
export default BasicMenuWrapper;
