import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useIsMutating } from '@tanstack/react-query';

const GeneralButton = ({
	children,
	mt,
	icon,
	type,
	onClick,
	sx,
	variant,
	component = 'button',
	pathTo = '',
	state,
}) => {
	// How many mutations are fetching?
	const isMutating = useIsMutating();

	return (
		<Button
			variant={variant ? variant : 'contained'}
			disableRipple
			startIcon={icon}
			sx={{ ...sx, mt }}
			type={type}
			onClick={onClick}
			component={component}
			to={pathTo}
			disabled={isMutating}
			state={component !== 'button' ? { ...state } : null}
		>
			{children}
		</Button>
	);
};

GeneralButton.propTypes = {
	children: PropTypes.any.isRequired,
	mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	sx: PropTypes.object,
	icon: PropTypes.element,
	type: PropTypes.string,
	onClick: PropTypes.func,
	variant: PropTypes.string,
	component: PropTypes.any,
	pathTo: PropTypes.string,
	state: PropTypes.object,
};

export default GeneralButton;
