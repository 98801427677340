import { useMutation } from '@tanstack/react-query';
import { userLogin } from '../../users';

import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

const useUserLogin = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleLogin, isPending } = useMutation({
		mutationFn: ({ email, password }) => userLogin({ email, password }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleLogin, isPending };
};

export default useUserLogin;
