import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { createThumbnail } from '../../files';

const useCreateThumbnail = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutateAsync: handleCreateThumbnail, isPending } = useMutation({
		mutationFn: (data) => createThumbnail(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleCreateThumbnail, isPending };
};

export default useCreateThumbnail;
