import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { downloadFile, updateAsset } from '../../files';

const useDownloadAsset = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleDownloadAsset, isPending } = useMutation({
		mutationFn: (data) => downloadFile(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleDownloadAsset, isPending };
};

export default useDownloadAsset;
