import { useQuery } from '@tanstack/react-query';

import { getBrands } from '../../brands';

const useGetBrands = (enabled = true) => {
	const QUERY_KEY = ['getBrands'];
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getBrands(),
		enabled,
	});
};

export default useGetBrands;
