import { Avatar, ListItemAvatar, ListItemText } from "@mui/material";
import { CustomBrandBox } from "../../styles/layouts";
import PropTypes from "prop-types";
import { getImageSource } from "../../utils/globals";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const SmallBrandBox = ({ brandItem, onClick, isActive, inDrawer }) => {
  return (
    <CustomBrandBox
      alignItems="center"
      key={brandItem?.id}
      onClick={() => {
        onClick(brandItem);
      }}
      isActive={isActive}
    >
      <ListItemAvatar
        sx={{
          transition: "all 0.25s ease",
          boxShadow: "0px 3px 6px #0000002b",
          borderRadius: "100%",
          position: "relative",
        }}
      >
        <Avatar
          alt={brandItem?.name}
          src={getImageSource(brandItem?.logo)?.src}
          sx={{
            width: 56,
            height: 56,
          }}
        />
        {inDrawer && (
          <CloseOutlinedIcon
            color="warning"
            sx={{ position: "absolute", top: -8, right: -8 }}
          />
        )}
      </ListItemAvatar>
      <ListItemText primary={brandItem?.name} />
    </CustomBrandBox>
  );
};
SmallBrandBox.propTypes = {
  brandItem: PropTypes.object,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  inDrawer: PropTypes.bool,
};
export default SmallBrandBox;
