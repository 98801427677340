import { useQuery } from '@tanstack/react-query';
import { userLogout } from '../../users';

const QUERY_KEY = ['userLogout'];

const useUserLogout = (doLogout) => {
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => userLogout(),
		retry: false,
		enabled: doLogout,
	});
};

export default useUserLogout;
