import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../../utils/globals';
import { StyledToggleButtonGroup } from '../../styles/inputs';

const GroupToggleButtons = ({ buttons, selectAction, value }) => {
	return (
		<StyledToggleButtonGroup
			color='primary'
			value={value}
			exclusive
			onChange={(event, value) => {
				selectAction(value);
			}}
		>
			{buttons.map((btn) => (
				<ToggleButton value={btn.value} key={btn.value}>
					{!btn.label && typeof btn?.value === 'string'
						? capitalizeFirstLetter(btn?.value)
						: btn?.label}
				</ToggleButton>
			))}
		</StyledToggleButtonGroup>
	);
};
GroupToggleButtons.propTypes = {
	buttons: PropTypes.array,
	selectAction: PropTypes.func,
	value: PropTypes.any,
};
export default GroupToggleButtons;
