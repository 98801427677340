import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { uploadFileToDbx } from '../../files';

const useUploadAssetFile = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleUploadFileToDbx, isPending } = useMutation({
		mutationFn: (data) => uploadFileToDbx(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleUploadFileToDbx, isPending };
};

export default useUploadAssetFile;
