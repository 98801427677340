import { useQuery } from '@tanstack/react-query';
import { authenticateDbx } from '../../general';

const useDropboxAuth = (enabled = true) => {
	const QUERY_KEY = ['authDbx'];
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => authenticateDbx(),
		enabled,
	});
};

export default useDropboxAuth;
