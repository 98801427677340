import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import useGetBrandBook from '../../api/hooks/brandbooks/useGetBrandbook';
import { useCallback, useEffect, useRef, useState } from 'react';
import { renderDisplayComponent } from '../../utils/fields';
import DisplayBanner from '../../components/Headers/DisplayBanner';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import BookNavBar from '../../components/NavBars/BookNavBar';
import AssetsDisplay from '../../components/Layouts/AssetsDisplay';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BrandBookAssetDrawer from '../../components/Drawers/BrandBookAssetDrawer';
import GeneralButton from '../../components/Inputs/GeneralButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SearchResultsBrandbook from '../../components/Screens/SearchResultsBrandbook';
import Support from './Support';
import { DISPLAY, useDisplayContext } from '../../contexts/DisplayContext';
import { useUser } from '../../contexts/UserContext';

const BrandBook = () => {
	const { client, brand } = useParams();

	const location = useLocation();
	const navigate = useNavigate();
	const { isPreviewing, setIsPreviewing } = useDisplayContext();
	const { data: brandBook } = useGetBrandBook(brand);
	const scrollToEl = useRef(null);
	const [brandBookFields, setBrandBookFields] = useState([]);
	const [currentAsset, setCurrentAsset] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	let [searchParams, setSearchParams] = useSearchParams();
	const [bannerData, setBannerData] = useState({
		banner: {},
		crop: {},
	});
	const [logo, setLogo] = useState(null);
	const topElRef = useRef(null);

	const outputFields = useCallback(() => {
		return brandBookFields.map((obj, index) => (
			<Box key={index} ref={index === 0 ? scrollToEl : null}>
				{renderDisplayComponent(
					obj?.value,
					obj?.fieldType,
					brandBook?.data?.language,
				)}
			</Box>
		));
	}, [brandBookFields]);

	useEffect(() => {
		if (!brandBook) return;

		setBrandBookFields(
			brandBook?.data?.BrandbookFields.map((item) => ({
				...item,
				type: item.fieldType,
				index: item?.order,
			})),
		);
		setBannerData({
			banner: brandBook?.data?.bannerAsset,
			crop: brandBook?.data?.bannerPixelCrop,
		});
		setLogo(brandBook?.data?.brand?.logo);
	}, [brandBook]);

	useEffect(() => {
		topElRef.current.scrollIntoView({
			behavior: 'smooth',
		});
		if (location?.state?.preview) {
			setIsPreviewing(true);
		}

		if (!location?.search || !location?.search?.includes('search')) {
			setSearchTerm(null);
		}
	}, [location]);

	return (
		<>
			{isPreviewing && (
				<GeneralButton
					variant={'text'}
					icon={<ArrowBackOutlinedIcon />}
					sx={{
						position: 'absolute',
						zIndex: 1,
						left: '1%',
						top: '1.5%',
						borderRadius: '100%',
						border: '1px solid black',
						minWidth: 'unset',
						width: '35px',
						aspectRatio: 1,
						'& .MuiButton-startIcon': {
							marginRight: 0,
						},
					}}
					onClick={() => {
						setIsPreviewing(false);
						navigate('/brandbooks', {
							state: {
								brandId: brandBook?.data?.brandId,
								display: DISPLAY.BUILDER,
							},
						});
					}}
				></GeneralButton>
			)}
			<Grid
				container
				direction={'column'}
				sx={{
					height: '100vh',
					overflow: 'hidden',
				}}
				wrap='nowrap'
			>
				<DisplayBanner
					crop={bannerData?.crop}
					banner={bannerData?.banner}
					logo={logo}
					brandName={brandBook?.data?.brand?.name}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
				/>

				<Grid
					container
					sx={{
						overflowY: 'scroll',
						position: 'relative',
					}}
				>
					<Grid
						item
						xs={2.1}
						sx={{
							background: '#fff',
							borderRight: '1px solid #84848454',
							position: 'sticky',
							top: 0,
							height: '71vh',
						}}
					>
						<BookNavBar brand={brandBook?.data?.brand} />
					</Grid>
					<Grid
						item
						xs={9.9}
						pt={'75px'}
						sx={{
							bgcolor: 'background.brandBook',
							direction:
								(!location?.search ||
									location?.search?.includes('overview')) &&
								brandBook?.data?.language === 'HEB'
									? 'rtl'
									: 'ltr',
							overflow: 'hidden',
						}}
					>
						<Stack
							sx={{
								maxWidth: 1200,
								marginInlineStart: '10vmin',
								height: 'fit-content',
								scrollMarginBlockStart: '5vmin',
							}}
							spacing={'30px'}
							pb={4}
							ref={topElRef}
							width={'100%'}
						>
							{location?.search?.includes('search') && (
								<SearchResultsBrandbook
									brandId={brandBook?.data?.brand?.id}
									searchTerm={searchTerm}
									setCurrentAsset={setCurrentAsset}
									// dir={brandBook?.data?.language}
									setSearchParams={setSearchParams}
								/>
							)}

							{(!location?.search ||
								location?.search?.includes('overview')) &&
								outputFields()}
							{location?.search?.includes('assets') && (
								<AssetsDisplay
									brand={brandBook?.data?.brand}
									setCurrentAsset={setCurrentAsset}
									// dir={brandBook?.data?.language}
								/>
							)}
							{(!location?.search ||
								location?.search?.includes('overview')) && (
								<Grid
									container
									alignItems={'center'}
									gap={1}
									width={'fit-content'}
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										scrollToEl.current.scrollIntoView({
											behavior: 'smooth',
											marginInlineStart: '20px',
										});
									}}
								>
									<IconButton
										disableRipple
										sx={{ border: '1px solid #4a4a4a' }}
									>
										<ArrowUpwardIcon />
									</IconButton>
									<Typography fontSize={24}>
										{brandBook?.data?.language === 'ENG'
											? 'Back to top'
											: 'בחזרה למעלה'}
									</Typography>
								</Grid>
							)}

							{location?.search?.includes('support') && (
								<Support />
							)}
						</Stack>
					</Grid>

					{currentAsset && (
						<BrandBookAssetDrawer
							asset={currentAsset}
							setCurrentAsset={setCurrentAsset}
							language={brandBook?.data?.language}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default BrandBook;
