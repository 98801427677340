import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { createBrand } from '../../brands';

const useCreateBrand = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleCreateBrand, isPending } = useMutation({
		mutationFn: (data) => createBrand(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleCreateBrand, isPending };
};

export default useCreateBrand;
