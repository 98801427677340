import { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ASSET_TYPES } from '../utils/enums';
import useGetFiles from '../api/hooks/files/useGetFiles';
import { useLocation } from 'react-router-dom';

const FileContext = createContext();

export const useFileContext = () => useContext(FileContext);

export const FileProvider = ({ children }) => {
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [currentFolder, setCurrentFolder] = useState(null);
	const [folderToEdit, setFolderToEdit] = useState(null);
	const [assetType, setAssetType] = useState(ASSET_TYPES.IMAGE);
	const [isUploadOpen, setIsUploadOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [folders, setFolders] = useState([]);
	const [filteredFolders, setFilteredFolders] = useState();
	const [files, setFiles] = useState([]);
	const [brand, setBrand] = useState(null);
	const [currentFile, setCurrentFile] = useState(null);
	const location = useLocation();

	const { data, refetch: refetchFiles } = useGetFiles(currentFolder?.id);

	// Method: Navigate to a specific folder
	const navigateToFolder = (folderId) => {
		let allFolders = flatFolders();

		const folder = allFolders.find((folder) => folder.id == folderId);
		setCurrentFolder(folder);
		setAssetType(folder?.assetType);
	};

	const createAsset = () => {
		const newAsset = {
			folderId: currentFolder?.id,
			type: assetType,
		};
		setCurrentFile(newAsset);
		setIsCreating(true);
		setIsEditing(false);
	};

	const updateFilesInFolder = (newFiles) => {
		setFiles((prev) => [...prev, ...newFiles]);
	};
	// Method: Create a new folder
	const createFolder = (folderObj) => {
		const folderBrand = folderObj.brand;
		const parentId = folderObj?.parentId;
		setFolders((prevFolders) => {
			const brandIndex = prevFolders.findIndex(
				(item) => item.brand.id === folderBrand?.id,
			);

			if (brandIndex !== -1) {
				// If the brand exists, update its folders
				return prevFolders.map((item) => {
					if (item.brand.id !== folderBrand?.id) return item;
					if (parentId === null) {
						return {
							...item,
							folders: [...item.folders, folderObj],
						};
					}
					return {
						...item,
						folders: item.folders.map((folder) => {
							if (folder?.id === parentId) {
								return {
									...folder,
									children: [
										...(folder.children || []),
										folderObj,
									],
								};
							}
							return folder;
						}),
					};
				});
			} else {
				// If the brand does not exist, add it with the new folder
				const newBrandItem = {
					brand: folderBrand,
					folders: [],
				};

				// If parentId is provided, find its parent folder and add the new folder
				if (folderObj.parentId) {
					newBrandItem.folders = prevFolders.map((item) => {
						const updatedFolders = item.folders.map((folder) => {
							if (folder.id === folderObj.parentId) {
								// If parentId matches, add the new folder as a child
								return {
									...folder,
									children: [
										...(folder.children || []),
										folderObj,
									],
								};
							}
							return folder;
						});
						return {
							...item,
							folders: updatedFolders,
						};
					});
				} else {
					// If parentId is not provided, add the new folder directly under the brand
					newBrandItem.folders.push(folderObj);
				}

				return [...prevFolders, newBrandItem];
			}
		});
	};

	// Method: Delete a folder
	const deleteFolder = (deletedFolder) => {
		setFolders((prevFolders) => {
			const filterFolders = (folders) =>
				folders.filter((folder) => {
					if (deletedFolder.parentId) {
						folder.children = folder?.children?.filter(
							(child) => child.id !== deletedFolder.id,
						);
						return folder;
					}
					return folder.id !== deletedFolder.id;
				});

			const filteredItems = prevFolders
				.map((item) => ({
					...item,
					folders: filterFolders(item.folders),
				}))
				.filter((item) => item.folders.length > 0);

			return filteredItems;
		});
	};

	// Method: Rename a folder
	const renameFolder = (folderId, newName) => {
		// Implement folder renaming logic
	};

	// Method: Create breadcrumbs based on the current folder
	const generateBreadcrumbs = () => {
		let allFolders = flatFolders();
		let breadcrumbFolders = [];
		let currentFolderId = currentFolder ? currentFolder.id : null;
		while (currentFolderId) {
			let folder = allFolders.find(
				(folder) => folder.id === currentFolderId,
			);
			if (folder) {
				breadcrumbFolders.unshift({ id: folder.id, name: folder.name }); // Add to the beginning of the array

				currentFolderId = folder.parentId;
			} else {
				currentFolderId = null;
			}
		}
		if (brand)
			breadcrumbFolders.unshift({ id: brand?.id, name: brand?.name });
		return breadcrumbFolders;
	};

	const flatFolders = () => {
		let allFolders = [];

		if (brand) {
			allFolders.push({ id: brand.id, name: brand.name });
		}

		const flatten = (folder) => {
			allFolders.push(folder); // Add the current folder to the result array
			if (folder.children && folder.children.length > 0) {
				folder?.children.forEach((child) => flatten(child)); // Recursively flatten child folders
			}
		};
		// Iterate through top-level folders and flatten each one
		folders
			?.filter((f) => f.brand?.id === brand?.id)
			.flatMap((f) => f.folders)
			.forEach((folder) => {
				flatten(folder);
			});

		return allFolders;
	};

	const handleSetBrand = (brandId) => {
		setBrand(folders.find((folder) => folder.brand.id === brandId)?.brand);
	};

	const handleSelectItemFromSearch = () => {
		setCurrentFile(
			files?.find((file) => file.id === location?.state?.current?.id),
		);
	};

	useEffect(() => {
		setBreadcrumbs(generateBreadcrumbs());
	}, [currentFolder, folders, brand]);

	useEffect(() => {
		if (data !== null) setFiles(data?.data);
	}, [data]);

	useEffect(() => {
		setFilteredFolders(folders);
	}, [folders, setFolders]);

	useEffect(() => {
		if (location?.state?.current) {
			handleSelectItemFromSearch();
		}
	}, [currentFolder, location]);

	return (
		<FileContext.Provider
			value={{
				breadcrumbs,
				setBreadcrumbs,
				currentFolder,
				setCurrentFolder,
				folderToEdit,
				setFolderToEdit,
				assetType,
				setAssetType,
				isUploadOpen,
				setIsUploadOpen,
				folders,
				setFolders,
				filteredFolders,
				setFilteredFolders,
				files,
				setFiles,
				brand,
				setBrand,
				currentFile,
				setCurrentFile,
				navigateToFolder,
				createFolder,
				deleteFolder,
				renameFolder,
				isEditing,
				setIsEditing,
				isCreating,
				setIsCreating,
				createAsset,
				updateFilesInFolder,
				handleSetBrand,
				refetchFiles,
			}}
		>
			{children}
		</FileContext.Provider>
	);
};

FileProvider.propTypes = {
	children: PropTypes.any.isRequired,
};
