import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CustomBg from '../components/Screens/CustomBg';
import theme from '../styles/theme';

import { useUser } from '../contexts/UserContext';
import { LoginProvider } from '../contexts/LoginContext';
import StepSwitch from '../components/LoginSteps/StepSwitch';
import Footer from '../components/NavBars/Footer';
import useGetBrandBook from '../api/hooks/brandbooks/useGetBrandbook';
import { USER_ROLE } from '../utils/enums';

const Login = () => {
	const navigate = useNavigate();
	const { brand } = useParams();
	const { data: brandBook } = useGetBrandBook(brand);
	const { user, isAuthenticated } = useUser();
	const [bgImage, setBgImage] = useState(null);

	useEffect(() => {
		if (brand == undefined) return;
		if (brandBook) {
			setBgImage(brandBook?.data?.brand?.featuredImage);
		}
	}, [brand, brandBook]);

	useEffect(() => {
		if (isAuthenticated && user) {
			if (brand) {
				navigate('../brandbook/' + brand);
			} else if (
				user.role === USER_ROLE.ADMIN ||
				user.role === USER_ROLE.TEAM
			) {
				navigate('/');
			}
		}
	}, [isAuthenticated, user, navigate]);

	return (
		<>
			<CustomBg bgImage={bgImage}>
				<Stack
					justifyContent='space-evenly'
					alignItems='center'
					spacing={2}
					px={'6vmin'}
					py={'7vmin'}
					sx={{
						minHeight: 646,
						borderRadius: '10px',
						backgroundColor: theme.palette.background.paper,
						boxShadow: ' 0px 10px 64px 0px rgba(0, 0, 0, 0.12)',
						position: 'relative',
					}}
				>
					<LoginProvider>
						<StepSwitch />
					</LoginProvider>
				</Stack>
			</CustomBg>
			<Footer />
		</>
	);
};

export default Login;
