import { Grid } from '@mui/material';
import SmallInput from './SmallInput';
import PropTypes from 'prop-types';

const SpacingInput = ({ values, onChange, property, maxWidth, marking }) => {
	const handleChange = (index, value) => {
		const updatedValues = [...values];
		updatedValues[index] = value;
		onChange(property, updatedValues);
	};

	return (
		<Grid container wrap='nowrap' gap={1}>
			{values?.map((value, index) => (
				<SmallInput
					key={index}
					type='number'
					smallText={marking ? marking[index] : ''}
					value={value}
					onChange={({ target: { value } }) =>
						handleChange(index, value)
					}
					maxWidth={maxWidth}
				/>
			))}
		</Grid>
	);
};

SpacingInput.propTypes = {
	values: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	property: PropTypes.string.isRequired,
	maxWidth: PropTypes.string,
	marking: PropTypes.oneOf(PropTypes.array, PropTypes.bool),
};
export default SpacingInput;
