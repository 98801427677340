import { Box, Collapse, Grid, Typography } from '@mui/material';
import { StyledAddBlock } from '../../styles/inputs';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useState } from 'react';
import ElementButton from '../SideBars/ElementButton';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import BentoOutlinedIcon from '@mui/icons-material/BentoOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import PropTypes from 'prop-types';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import { FieldType, useFields } from '../../contexts/FieldsContext';

const ELEMENTS = [
	{
		title: FieldType.HEADING,
		icon: <TitleOutlinedIcon />,
	},
	{
		title: FieldType.TEXT,
		icon: <NotesOutlinedIcon />,
	},
	{
		title: FieldType.CARD,
		icon: <BentoOutlinedIcon />,
	},
	{
		title: FieldType.IMAGE,
		icon: <BrokenImageOutlinedIcon />,
	},
	{
		title: FieldType.COLUMNS,
		icon: <ViewWeekOutlinedIcon />,
	},
];

const AddBlock = ({ isInColumn, columnFieldIndex, positionInColumn }) => {
	const [isAddingBlock, setIsAddingBlock] = useState(false);
	const {
		newBlockIndex,
		currentField,
		handleSaveField,
		handleIndexingFields,
	} = useFields();
	const { setIsCreating } = useBrandbooksContext();

	return (
		<StyledAddBlock
			container
			direction={'column'}
			gap={isAddingBlock ? 2 : 0}
			my={4}
			justifyContent={'center'}
			alignItems={'center'}
		>
			<AddOutlinedIcon
				onClick={() => {
					setIsAddingBlock(!isAddingBlock);
					//add context of adding blocks to array of overview fields
				}}
			/>

			<Collapse
				orientation='vertical'
				in={isAddingBlock}
				sx={{ paddingInline: isInColumn ? 0.5 : 8, width: '100%' }}
			>
				<Typography textAlign={'center'}>
					Please select an element to add.
				</Typography>
				<Grid
					container
					gap={2}
					justifyContent={'space-evenly'}
					mt={1}
					width={'100%'}
				>
					{ELEMENTS.map((el) => {
						if (isInColumn !== undefined && el.title === 'COLUMNS')
							return null;
						return (
							<ElementButton
								key={el.title}
								action={() => {
									setIsCreating(true);
									setIsAddingBlock(false);

									(isInColumn || !newBlockIndex) &&
										currentField !== null &&
										handleSaveField(currentField);
									handleIndexingFields(
										el.title,
										newBlockIndex,
										isInColumn,
										columnFieldIndex,
										positionInColumn,
									);
								}}
								title={el.title}
								icon={el.icon}
							/>
						);
					})}
				</Grid>
			</Collapse>
		</StyledAddBlock>
	);
};
AddBlock.propTypes = {
	isInColumn: PropTypes.number,
	columnFieldIndex: PropTypes.number,
	positionInColumn: PropTypes.number,
};

export default AddBlock;
