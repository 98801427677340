import { Box, Button, Chip, Grid, List, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '../../styles/palette';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useEffect, useState } from 'react';
import useCreateUser from '../../api/hooks/users/useCreateUser';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import { useDataContext } from '../../contexts/DataContext';
import useUpdateUser from '../../api/hooks/users/useUpdateUser';
import { generatePassword } from '../../utils/passwordGenerator';
import ContentEditable from '../Inputs/ContentEditable';
import DrawerFileUploader from '../Inputs/DrawerFileUploader';
import DrawerLayout from '../Layouts/DrawerLayout';
import { compareFields, validateForm } from '../../utils/globals';
import { useUser } from '../../contexts/UserContext';
import { USER_ROLE } from '../../utils/enums';
import useGetBrands from '../../api/hooks/brands/useGetBrands';
import SmallBrandBox from '../Cards/SmallBrandBox';
import CustomAutocomplete from '../Inputs/CustomAutocomplete';
import GroupToggleButtons from '../Inputs/GroupToggleButtons';
import { GLOBAL_URL } from '../../api/global';

const UserDrawer = ({ isOpen, setIsOpen, userRole }) => {
	const {
		isCreating,
		isEditing,
		setIsCreating,
		setIsEditing,
		currentItem,
		setCurrentItem,
		updateData,
	} = useDataContext();

	const { user, setUser } = useUser();
	const { handleAlert } = useAlert();
	const [originalUser, setOriginalUser] = useState(null);
	const [file, setFile] = useState(null);
	const { data: brands } = useGetBrands(userRole === USER_ROLE.CLIENT);
	const [assignedBrands, setAssignedBrands] = useState([]);
	const [isCopied, setIsCopied] = useState(false);

	const handleUserCreated = (data) => {
		handleAlert(ALERT_STATUS.SUCCESS, data.message);
		if (user?.id === currentItem?.id) {
			setUser(data.data);
		} else {
			updateData(isCreating ? 'add' : 'edit', data.data);
		}

		handleCloseDrawer();
	};

	const { handleCreateUser, isPending } = useCreateUser(handleUserCreated);

	const { handleUpdateUser } = useUpdateUser(handleUserCreated);

	const createUser = () => {
		const formValidation = validateForm(currentItem);
		if (!formValidation.isValidated)
			return handleAlert(ALERT_STATUS.ERROR, formValidation.message);

		const formData = new FormData();

		const fields = Object.keys(currentItem).filter((k) => k !== 'image');
		fields.forEach((field) => {
			formData.append(field, currentItem[field] || '');
		});
		formData.append('role', userRole);

		if (currentItem.image instanceof File) {
			formData.append('image', currentItem.image);
		}

		if (userRole === USER_ROLE.CLIENT) {
			formData.append(
				'brands',
				assignedBrands.length ? JSON.stringify(assignedBrands) : null,
			);
		}

		handleCreateUser(formData);
		//in create send email to user
	};

	const editUser = () => {
		const formValidation = validateForm(currentItem);
		if (!formValidation.isValidated)
			return handleAlert(ALERT_STATUS.ERROR, formValidation.message);
		const changedFields = compareFields(currentItem, originalUser);
		handleUpdateUser({
			id: currentItem?.id,
			...changedFields,
			BrandClients:
				assignedBrands.length > 0
					? [...assignedBrands.map((brand) => brand?.id)]
					: [],
		});
	};

	const handleCloseDrawer = () => {
		setFile(null);
		setIsOpen(false);
		setIsEditing(false);
		setIsCreating(false);
		setOriginalUser(null);
		setCurrentItem({
			email: null,
			password: null,
			image: null,
			name: null,
		});
	};

	useEffect(() => {
		if (originalUser === null && currentItem) setOriginalUser(currentItem);
	}, [currentItem]);

	useEffect(() => {
		if (!brands?.data) return;
		setAssignedBrands(
			brands?.data?.filter((brand) =>
				currentItem?.BrandClients?.find(
					(client) => client?.brandId === brand?.id,
				),
			),
		);
	}, [brands, currentItem]);

	return (
		<DrawerLayout
			title={'User Details'}
			isOpen={isOpen}
			handleCloseDrawer={handleCloseDrawer}
			handleSave={() => {
				isCreating
					? createUser()
					: isEditing || file
					? editUser()
					: console.log('first');

				//
			}}
		>
			<Grid container alignItems={'flex-end'} gap={2}>
				<DrawerFileUploader
					displayEl='avatar'
					buttonText='Image'
					fieldName='image'
					inputName='image'
					inputId='user-image-upload'
					currentItem={currentItem}
					setCurrentItem={setCurrentItem}
					file={file}
					setFile={setFile}
					pathToImage={GLOBAL_URL + currentItem?.image}
				/>
			</Grid>

			<Grid container direction={'column'} gap={2}>
				<Grid
					container
					justifyContent={'space-between'}
					gap={2}
					sx={{ border: 'unset !important' }}
				>
					<Typography variant='h6' component='h6'>
						Personal Details
					</Typography>
				</Grid>
				<ContentEditable
					label='Full Name'
					type={'text'}
					value={currentItem?.name || ''}
					canEdit={isEditing || isCreating}
					setValue={({ target: { value } }) => {
						setCurrentItem((prev) => ({ ...prev, name: value }));
					}}
				/>
				<ContentEditable
					label='Email'
					type={'email'}
					value={currentItem?.email || ''}
					setValue={({ target: { value } }) => {
						setCurrentItem((prev) => ({ ...prev, email: value }));
					}}
					canEdit={isEditing || isCreating}
				/>
				{isCreating && userRole !== USER_ROLE.CLIENT && (
					<Box sx={{ position: 'relative' }}>
						<ContentEditable
							label='Password'
							type={'password'}
							value={currentItem?.password || ''}
							setValue={({ target: { value } }) => {
								setCurrentItem((prev) => ({
									...prev,
									password: value,
								}));
							}}
							canEdit={isCreating}
						/>
						<Box
							sx={{
								position: 'absolute',
								right: 0,
								top: '50%',
								cursor: 'pointer',
							}}
						>
							{!isCopied ? (
								<ContentCopyIcon
									onClick={() => {
										navigator.clipboard.writeText(
											currentItem?.password,
										);
										setIsCopied(true);
										setTimeout(() => {
											setIsCopied(false);
										}, 2000);
									}}
								/>
							) : (
								<Typography variant='small' color={'primary'}>
									Copied!
								</Typography>
							)}
						</Box>
					</Box>
				)}
				{(isEditing || isCreating) && userRole !== USER_ROLE.CLIENT && (
					<Button
						type={'button'}
						variant='simple'
						sx={{ mt: 2, bgcolor: palette.background.grey }}
						onClick={({ target }) => {
							const password = generatePassword();
							setCurrentItem((prev) => ({ ...prev, password }));

							//do something if isEditing to notify that new password wa set
						}}
					>
						{isEditing ? 'Reset Password' : 'Generate Password'}
					</Button>
				)}
			</Grid>
			{userRole !== USER_ROLE.CLIENT &&
				user?.role === USER_ROLE.ADMIN &&
				currentItem?.id !== user?.id && (
					<Grid
						container
						sx={{ border: 'unset !important' }}
						direction={'column'}
					>
						<Typography variant='h6' component='h6'>
							Permissions
						</Typography>
						<Grid
							container
							sx={{
								border: 'unset !important',
								mt: 3,
								maxWidth: '70%',
							}}
							wrap={'wrap'}
							gap={2}
						>
							<GroupToggleButtons
								buttons={[
									{ value: USER_ROLE.ADMIN },
									{ value: USER_ROLE.TEAM },
								]}
								selectAction={(value) => {
									setCurrentItem((prev) => ({
										...prev,
										role: value,
									}));
								}}
								value={currentItem?.role || USER_ROLE.TEAM}
							/>
						</Grid>
					</Grid>
				)}
			{userRole === USER_ROLE.CLIENT && (
				<Grid
					container
					sx={{ border: 'unset !important' }}
					direction={'column'}
				>
					<Typography variant='h6' component='h6'>
						Brands
					</Typography>
					<CustomAutocomplete
						options={brands?.data}
						disabledOptions={assignedBrands}
						label='Search brand by name...'
						titleField={'name'}
						onSelect={(client) => {
							setAssignedBrands((prev) => [...prev, client]);
						}}
						icon={true}
					/>
					<Grid
						container
						sx={{
							border: 'unset !important',
							mt: 3,
						}}
						wrap={'wrap'}
						gap={2}
					>
						<List
							sx={{
								width: '100%',
								display: 'flex',
								gap: '0.5vmin',
								maxHeight: '140px',
								overflowY: 'scroll',
								flexWrap: 'wrap',
							}}
						>
							{brands &&
								assignedBrands.map((brandItem) => (
									<SmallBrandBox
										brandItem={brandItem}
										isActive={false}
										key={brandItem?.id}
										inDrawer={true}
										onClick={(item) => {
											//add on hover in this case an x button
											setAssignedBrands((prev) =>
												prev.filter(
													(i) => i?.id !== item?.id,
												),
											);
										}}
									/>
								))}
						</List>
					</Grid>
				</Grid>
			)}
		</DrawerLayout>
	);
};

UserDrawer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	userRole: PropTypes.string.isRequired,
};
export default UserDrawer;
