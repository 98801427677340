import { useQuery } from '@tanstack/react-query';

import { getBrandClients } from '../../brands';

const useGetBrandClients = (brand, enabled = true) => {
	const QUERY_KEY = ['getClients', brand];
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getBrandClients(brand),
		enabled,
	});
};

export default useGetBrandClients;
