import styled from '@emotion/styled';
import { ListItemIcon } from '@mui/material';

export const StyledAdminNavIcon = styled(ListItemIcon, {
	shouldForwardProp: (prop) =>
		prop !== 'isMinimized' && prop !== 'isActive' && prop !== 'isBold',
})(({ theme: { palette }, isMinimized, isActive, isBold }) => ({
	minWidth: 0,
	justifyContent: 'center',
	border: isMinimized ? `1px solid` : 'none',
	borderColor: isActive ? palette.primary.main : palette.background.default,
	borderRadius: '100%',
	background: isBold && isMinimized ? palette.primary.main : 'transparent',
	padding: isMinimized ? '1rem' : '0.5rem',
	marginInlineEnd: isMinimized ? '0' : '1rem',
	color:
		(!isMinimized && isBold) || isActive
			? palette.primary.main
			: isBold
			? palette.background.paper
			: palette.text.primary,
	transition: 'all 0.2s ease',
	'&: hover': {
		borderColor: isBold ? 'none' : palette.primary.main,
		transform: isBold ? 'scale(1.12)' : 'unset',
	},
}));
