import { Grid, Input, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SmallInput = ({ type, smallText, sx, onChange, value, maxWidth }) => {
	return (
		<Grid container direction={'column'} alignItems={'center'}>
			<Input
				id={smallText}
				value={value}
				type={type}
				onChange={onChange}
				sx={{
					border: 'unset',
					maxWidth: maxWidth || '40px',
					minHeight: 'unset',
					...sx,
				}}
			/>
			<Typography variant='small' color={'background.grey'}>
				{smallText}
			</Typography>
		</Grid>
	);
};

SmallInput.propTypes = {
	type: PropTypes.string,
	value: PropTypes.any,
	smallText: PropTypes.string,
	onChange: PropTypes.func,
	sx: PropTypes.object,
	maxWidth: PropTypes.string,
};
export default SmallInput;
