import { useQuery } from '@tanstack/react-query';

import { getFolders } from '../../files';

const useGetFolders = () => {
	const QUERY_KEY = ['getFolders'];

	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getFolders(),
	});
};

export default useGetFolders;
