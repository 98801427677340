import {
	Badge,
	ListItem,
	ListItemButton,
	ListItemText,
	Tooltip,
} from '@mui/material';
import { StyledAdminNavIcon } from '../../styles/navbars';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import palette from '../../styles/palette';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
const AdminNavItem = ({
	action,
	component,
	text,
	icon,
	isMinimized,
	onClick,
	isBold,
	flag,
}) => {
	const { pathname } = useLocation();

	const adminNavIcon = (
		<StyledAdminNavIcon
			isMinimized={isMinimized}
			isActive={pathname === action}
			isBold={isBold}
		>
			{icon}
		</StyledAdminNavIcon>
	);

	const IconContent = isMinimized ? (
		<Tooltip title={text} placement='right' arrow>
			{adminNavIcon}
		</Tooltip>
	) : (
		adminNavIcon
	);

	return (
		<ListItem
			sx={{
				p: isMinimized ? 0 : 1,
				fontSize: isBold && !isMinimized ? '60%' : '100%',
			}}
		>
			<ListItemButton
				LinkComponent={component}
				to={action}
				sx={{
					minHeight: 48,
					'&: hover': {
						background: 'transparent',
					},
				}}
				disableTouchRipple={true}
				onClick={() => {
					if (onClick) {
						onClick();
					}
				}}
			>
				{flag ? (
					<Badge
						badgeContent={
							<WarningAmberOutlinedIcon
								fontSize='12'
								sx={{ color: '#fff' }}
							/>
						}
						color='warning'
						sx={{
							'& .MuiBadge-badge ': { top: '10px', right: '5px' },
						}}
					>
						{IconContent}
					</Badge>
				) : (
					IconContent
				)}
				{text && (
					<ListItemText
						primary={text}
						sx={{
							width: isMinimized ? 0 : 'max-content',
							overflow: 'hidden',
							color: isBold ? palette.primary.main : 'inherit',
						}}
					/>
				)}
			</ListItemButton>
		</ListItem>
	);
};

AdminNavItem.propTypes = {
	isMinimized: PropTypes.bool.isRequired,
	action: PropTypes.string,
	onClick: PropTypes.func,
	component: PropTypes.object,
	text: PropTypes.string,
	icon: PropTypes.element.isRequired,
	isBold: PropTypes.bool,
	flag: PropTypes.bool,
};

export default AdminNavItem;
