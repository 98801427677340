import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import SmallInput from "./SmallInput";
import PropTypes from "prop-types";
import useClickOutside from "../../hooks/useClickOutside";
import palette from "../../styles/palette";
import { colorConvert } from "../../utils/calculations";
import ColorPickerMenu from "../Fields/ColorPickerMenu";

const TextColorPicker = ({ handleChange }) => {
  const [color, setColor] = useState(palette.text.primary);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const colorPickerRef = useRef(null);

  useClickOutside(colorPickerRef, () => {
    showColorPicker && setShowColorPicker(false);
  });

  useEffect(() => {
    handleChange(color);
  }, [color]);

  return (
    <Grid container alignItems={"end"} wrap="nowrap">
      <Typography variant="p" fontSize={"95%"} width={"100%"}>
        Text Color
      </Typography>
      <SmallInput
        value={color}
        onChange={({ target: { value } }) => {
          //need to make sure color is valid - i think i have a function that does this
          setColor(value);
        }}
        sx={{ maxWidth: "100px", marginInline: "auto 2vmin" }}
      />

      <Box
        sx={{
          bgcolor: color,
          width: "40px",
          aspectRatio: 1,
          borderRadius: "5px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      ></Box>
      <ColorPickerMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        color={colorConvert(color, "hex", "rgb")}
        setColor={(color) => {
          setColor("#" + colorConvert(color, "rgb", "hex"));
        }}
      />
    </Grid>
  );
};
TextColorPicker.propTypes = {
  handleChange: PropTypes.func,
};
export default TextColorPicker;
