import { useQuery } from '@tanstack/react-query';
import { checkTimeRemaining } from '../../users';

const useCheckRemainingTime = (userCred) => {
	return useQuery({
		queryKey: ['checkCode', userCred],
		queryFn: async () => {
			if (!userCred.email) return {};
			return await checkTimeRemaining(userCred.email).then((res) => {
				return res.data;
			});
		},
	});
};

export default useCheckRemainingTime;
