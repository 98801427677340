import { useEffect, useRef, useState } from 'react';

import ContentEditable from '../Inputs/ContentEditable';
import BasicDialog from '../Layouts/BasicDialog';
import PropTypes from 'prop-types';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import { useFileContext } from '../../contexts/FilesContext';
import { Grid, Typography } from '@mui/material';
import BasicMenuWrapper from '../Layouts/BasicMenuWrapper';
import CustomAutocomplete from '../Inputs/CustomAutocomplete';
import GroupToggleButtons from '../Inputs/GroupToggleButtons';
import { ASSET_TYPES } from '../../utils/enums';
import useCreateNewFolder from '../../api/hooks/files/useCreateNewFolder';

const FolderDialog = ({ isOpen, setIsOpen }) => {
	const { handleAlert } = useAlert();

	const {
		folders,
		createFolder,
		navigateToFolder,
		folderToEdit,
		currentFolder,
		brand,
	} = useFileContext();
	const [newFolder, setNewFolder] = useState(
		folderToEdit || {
			name: '',
			brand: brand,
			parentFolder: currentFolder,
			assetType: ASSET_TYPES.IMAGE,
		},
	);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const { handleCreateFolder } = useCreateNewFolder((data) => {
		createFolder(data?.data);
		navigateToFolder(data?.data?.id); //not working!!!
		handleClose();
	});

	const handleClose = () => {
		setIsOpen(false);
		setNewFolder({
			name: '',
			brand: null,
			parentFolder: null,
			assetType: ASSET_TYPES.IMAGE,
		});
		setSelectedBrand(null);
	};

	const handleSaveFolder = () => {
		if (newFolder?.name === null)
			return handleAlert(ALERT_STATUS.ERROR, 'Folder must have a name');

		if (newFolder?.brand === null)
			return handleAlert(
				ALERT_STATUS.ERROR,
				'Folder has to be associated to brand',
			);

		handleCreateFolder(newFolder);
	};

	const actions = [
		{
			actionHandler: handleClose,
			isMain: false,
		},
		{
			actionHandler: handleSaveFolder,
			isMain: true,
		},
	];

	useEffect(() => {
		if (selectedBrand === null)
			setNewFolder((prev) => ({
				...prev,
				parentFolder: null,
			}));
	}, [selectedBrand]);

	useEffect(() => {
		setNewFolder(folderToEdit);
	}, [folderToEdit]);

	useEffect(() => {
		if (brand) {
			setNewFolder((prev) => ({ ...prev, brand: brand }));
		}
		if (currentFolder) {
			setNewFolder((prev) => ({ ...prev, parentFolder: currentFolder }));
		}
	}, [brand, currentFolder]);

	return (
		<BasicDialog
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={`${
				brand && currentFolder
					? `${brand?.name} / ${currentFolder?.name} / `
					: ''
			} ${folderToEdit ? 'Edit Folder' : 'New Folder'}`}
			actions={actions}
			// isError={isError}
			handleClose={handleClose}
			maxWidth={700}
		>
			<Grid container direction={'column'} gap={2}>
				<ContentEditable
					type='text'
					label='Folder Name'
					canEdit={true}
					value={newFolder?.name || ''}
					setValue={({ target: { value } }) => {
						setNewFolder((prev) => ({
							...prev,
							name: value,
						}));
					}}
				/>
				{!currentFolder && (
					<Grid container gap={2}>
						<CustomAutocomplete
							options={
								folders &&
								folders?.flatMap((folder) => folder.brand)
							}
							label={'Select folder brand'}
							onSelect={(value) => {
								setNewFolder((prev) => ({
									...prev,
									brand: value,
								}));
								setSelectedBrand(value);
							}}
							disabledOptions={[]}
							titleField={'name'}
							icon={false}
							sx={{ flex: 1 }}
						/>
						<CustomAutocomplete
							options={
								selectedBrand
									? folders
											.filter(
												(f) =>
													f?.brand?.id ===
													selectedBrand?.id,
											)
											.flatMap((folder) => [
												...folder.folders,
											])
									: []
							}
							label={'Select parent folder'}
							onSelect={(value) => {
								setNewFolder((prev) => ({
									...prev,
									parentFolder: value,
								}));
							}}
							disabledOptions={[]}
							titleField={'name'}
							icon={false}
							sx={{ flex: 1 }}
						/>
					</Grid>
				)}
				<Typography>Asset Type</Typography>
				<GroupToggleButtons
					buttons={[...Object.keys(ASSET_TYPES)].map((key) => ({
						value: key,
						label: key,
					}))}
					selectAction={(value) => {
						setNewFolder((prev) => ({
							...prev,
							assetType: value,
						}));
					}}
					value={newFolder?.assetType}
				/>
			</Grid>
		</BasicDialog>
	);
};
FolderDialog.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
};
export default FolderDialog;
