import {
	Checkbox,
	FormControlLabel,
	IconButton,
	MenuItem,
	Tooltip,
} from '@mui/material';
import palette from '../../styles/palette';
import BasicMenuWrapper from '../Layouts/BasicMenuWrapper';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useState } from 'react';
import PropTypes from 'prop-types';

const CategoriesFilter = ({ currentCategories, setCategoriesToFilter }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	return (
		<>
			<Tooltip title='Categories' placement='top' arrow>
				<IconButton
					onClick={(event) => {
						setAnchorEl(event.currentTarget);
					}}
				>
					<FilterListOutlinedIcon
						sx={{
							color: palette.text.secondary,
						}}
					/>
				</IconButton>
			</Tooltip>
			<BasicMenuWrapper anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
				{currentCategories.map((category) => (
					<MenuItem key={category?.id} disableRipple>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										color: palette.primary.main,
									}}
									onChange={({ target: { checked } }) => {
										if (checked) {
											setCategoriesToFilter((prev) => [
												...prev,
												category?.id,
											]);
										} else {
											setCategoriesToFilter((prev) => [
												...prev.filter(
													(cat) =>
														cat !== category?.id,
												),
											]);
										}
									}}
								/>
							}
							label={category?.name}
						/>
					</MenuItem>
				))}
			</BasicMenuWrapper>
		</>
	);
};
CategoriesFilter.propTypes = {
	currentCategories: PropTypes.array,
	setCategoriesToFilter: PropTypes.func,
};
export default CategoriesFilter;
