import { Box } from '@mui/material';
import VerificationInput from 'react-verification-input';

import PropTypes from 'prop-types';
import { StyledAuthInput } from '../../styles/inputs';

const CustomVerificationInput = ({ verifyCode, email }) => {
	return (
		<StyledAuthInput>
			<VerificationInput
				onChange={(e) => {
					if (e.length < 6) return;
					verifyCode({ email, authCode: e });
				}}
				inputField={{}}
				placeholder={' '}
				length={6}
				validChars='0-9'
				removeDefaultStyles
				characters={{
					className: 'characters',
				}}
				character={{
					className: 'character',
					classNameInactive: 'character--inactive',
					classNameSelected: 'character--selected',
				}}
			/>
		</StyledAuthInput>
	);
};

CustomVerificationInput.propTypes = {
	verifyCode: PropTypes.func,
	email: PropTypes.string.isRequired,
};

export default CustomVerificationInput;
