import { useEffect, useState } from 'react';
import BlockActions from '../Inputs/BlockActions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { formatSpacingValues } from '../../utils/fields';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import { useFields } from '../../contexts/FieldsContext';

const toolbarOptions = [
	['bold', 'italic', 'underline'],
	['blockquote'],
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ header: [2, 3, false] }],
	[{ align: [] }],
];

const TextField = ({ margin, padding, value, index, obj }) => {
	const { isEditing, isCreating, setIsCreating, setIsEditing } =
		useBrandbooksContext();
	const {
		currentField,
		handleSaveField,
		handleEditField,
		setCurrentField,
		renderProp,
	} = useFields();

	const field = currentField?.index === index ? currentField : obj;

	return (
		<BlockActions
			index={index}
			isEditing={
				(isCreating && currentField?.index === index) ||
				(isEditing && currentField?.index === index)
			}
			saveAction={() => {
				handleSaveField(currentField);
				setIsCreating(false);
				setIsEditing(false);
			}}
			editAction={() => {
				handleEditField(obj);
				setIsEditing(true);
			}}
			isInColumn={field?.isInColumn}
			columnIndex={field?.columnIndex || null}
			field={obj}
		>
			<Box
				sx={{
					position: 'relative',
					zIndex: 1,
					margin: '2vmin',
					paddingBlock: '2vmin',
				}}
			>
				<Box
					sx={{
						padding: formatSpacingValues(field?.padding),
						margin: formatSpacingValues(field?.margin),
					}}
				>
					{(isEditing || isCreating) &&
					currentField?.index === index ? (
						<ReactQuill
							className='field'
							theme='snow'
							value={field?.value}
							onChange={(value) => {
								setCurrentField((prev) => ({ ...prev, value }));
							}}
							modules={{
								toolbar: toolbarOptions,
							}}
						/>
					) : (
						<Box sx={{ fontSize: '16px' }} color={'#00000080'}>
							<div
								dangerouslySetInnerHTML={{
									__html: field?.value,
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</BlockActions>
	);
};

TextField.propTypes = {
	index: PropTypes.number,
	margin: PropTypes.array,
	padding: PropTypes.array,
	value: PropTypes.string,
	obj: PropTypes.object,
};

export default TextField;
