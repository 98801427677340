import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGetBrandByClient from '../api/hooks/users/useGetClientBrand';
import { USER_ROLE } from '../utils/enums';
import BrandLoader from '../components/General/BrandLoader';
import { Grid } from '@mui/material';

export const ProtectedRoute = ({ children, allowedRoles }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, isAuthenticated } = useUser();
	const { brand } = useParams();

	const [loading, setLoading] = useState(true);
	const { data } = useGetBrandByClient(
		user?.id,
		isAuthenticated && user?.role === USER_ROLE.CLIENT,
	);

	useEffect(() => {
		if (!isAuthenticated) {
			navigate(`/login${brand ? '/' + brand : ''}`);
			setLoading(false);
		} else if (
			allowedRoles &&
			allowedRoles.length > 0 &&
			!allowedRoles?.includes(user?.role)
		) {
			if (brand) {
				navigate('/brandbook/' + brand);
			} else if (data?.data) {
				navigate('/brandbook/' + data?.data?.brand?.name);
			}
		} else {
			setLoading(false);
		}
	}, [
		isAuthenticated,
		navigate,
		allowedRoles,
		user?.role,
		location.pathname,
		data,
	]);

	return !isAuthenticated ? null : loading ? (
		<Grid
			container
			justifyContent={'center'}
			alignItems={'center'}
			minHeight={'100vh'}
		>
			<BrandLoader />
		</Grid>
	) : (
		children
	);
};

ProtectedRoute.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	allowedRoles: PropTypes.array,
};
