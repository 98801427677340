import {
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { StyledFolderCard } from '../../styles/layouts';
import PropTypes from 'prop-types';
import { useFileContext } from '../../contexts/FilesContext';
import FolderIcon from '@mui/icons-material/Folder';
import { capitalizeFirstLetter } from '../../utils/globals';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const FolderCard = ({
	folder,
	pendingDelete,
	setPendingDelete,
	setIsFolderEdit,
}) => {
	const { navigateToFolder, setFolderToEdit } = useFileContext();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<StyledFolderCard
			container
			alignItems={'center'}
			gap={2}
			sx={{ position: 'relative' }}
			pendingDelete={pendingDelete}
		>
			<FolderIcon
				color='secondary'
				sx={{ fontSize: 40 }}
				onClick={() => {
					navigateToFolder(folder?.id);
				}}
			/>
			<Typography
				onClick={() => {
					navigateToFolder(folder?.id);
				}}
			>
				{capitalizeFirstLetter(folder?.name)}
			</Typography>

			<IconButton aria-label='Folder actions' onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id={`folder-item-menu${folder?.id}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				elevation={0}
				sx={{
					'& .MuiPaper-root': {
						boxShadow: '0 3px 6px #25232342',
						borderRadius: '16px',
					},
				}}
				anchorOrigin={{
					vertical: 0,
					horizontal: 30,
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{/* <MenuItem
					onClick={() => {
						setIsFolderEdit(true);
						setFolderToEdit(folder);
						handleClose();
					}}
				>
					<ListItemIcon>
						<ModeEditOutlineOutlinedIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText>Edit folder</ListItemText>
				</MenuItem> */}
				<MenuItem
					onClick={() => {
						setPendingDelete(folder?.id);
						handleClose();
					}}
				>
					<ListItemIcon>
						<DeleteOutlinedIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>
		</StyledFolderCard>
	);
};
FolderCard.propTypes = {
	folder: PropTypes.object,
	pendingDelete: PropTypes.any,
	setPendingDelete: PropTypes.func,
	setIsFolderEdit: PropTypes.func,
};
export default FolderCard;
