// UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useVerifyUser from '../api/hooks/users/useVerifyUser';
import { ALERT_STATUS, useAlert } from './AlertContext';
import BrandLoader from '../components/General/BrandLoader';
import { Grid } from '@mui/material';
import useUserLogout from '../api/hooks/users/useUserLogout';
import CryptoJS from 'crypto-js';
// Create a user context
const UserContext = createContext({
	user: null,
	isAuthenticated: false,
	logout: () => {},
	login: (user) => {},
});

export const useUser = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLogout, setIsLogout] = useState(false);
	const { data, isError, error, isLoading, isFetching } = useVerifyUser();
	const { data: logoutData } = useUserLogout(isLogout);
	const { handleAlert } = useAlert();

	const logout = () => {
		setUser(null);
		setIsAuthenticated(false);
		setIsLogout(true);
		localStorage.removeItem('brandboss_auth');
	};
	const login = (user) => {
		if (!user) return;
		localStorage.setItem(
			'brandboss_auth',
			CryptoJS.AES.encrypt(JSON.stringify(user), 'secret-key').toString(),
		);
		setIsAuthenticated(true);
		setIsLogout(false);
		setUser(user);
	};

	const getUserFromStorage = () => {
		const hashedUser = localStorage.getItem('brandboss_auth');
		if (!hashedUser) return;
		const bytes = CryptoJS.AES.decrypt(hashedUser, 'secret-key');
		const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		login(user);
	};

	useEffect(() => {
		getUserFromStorage();
		if (isFetching) return;
		if (isError) {
			logout();
			return handleAlert(ALERT_STATUS.ERROR, error.message);
		}
		if (data == undefined) return logout();
		if (data?.data) login(data?.data);
	}, [isFetching, isError, data, error]);

	return (
		<UserContext.Provider
			value={{
				user,
				isAuthenticated,
				logout,
				login,
				setUser,
				isLoading,
			}}
		>
			{isLoading ? (
				<Grid
					container
					alignItems={'center'}
					justifyContent={'center'}
					height={'100vh'}
				>
					<BrandLoader />
				</Grid>
			) : (
				children
			)}
		</UserContext.Provider>
	);
};

UserContextProvider.propTypes = {
	children: PropTypes.any.isRequired,
};
