import { useCallback, useEffect, useRef, useState } from 'react';
import BlockActions from '../Inputs/BlockActions';
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Skeleton,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { formatSpacingValues } from '../../utils/fields';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import placeholderImage from '../../assets/images/placeholder.jpg';
import { handleCroppedImage } from '../../utils/cropImage';
import { getImageSource } from '../../utils/globals';
import { ASSET_TYPES } from '../../utils/enums';
import { useAlert } from '../../contexts/AlertContext';
import { FieldType, useFields } from '../../contexts/FieldsContext';

const CardField = ({
	margin,
	padding,
	value,
	index,
	setIsChoosingAsset,
	asset,
	title,
	obj,
}) => {
	const { isEditing, isCreating, setIsCreating, setIsEditing } =
		useBrandbooksContext();
	const { currentField, handleSaveField, handleEditField, setCurrentField } =
		useFields();
	const { handleAlert } = useAlert();

	const [loading, setLoading] = useState(false);

	const titleRef = useRef();
	const [editable, setEditable] = useState(null);
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);

	const field = currentField?.index === index ? currentField : obj;

	const handleSetCroppedImage = useCallback(async () => {
		if (field?.asset?.localPath || field?.asset?.externalLink) {
			const croppedImage = await handleCroppedImage(
				field,
				handleAlert,
				setLoading,
			);

			if (!croppedImage) return;
			setCroppedImageUrl(croppedImage);
		}
	}, [asset]);

	const handleInput = () => {
		const newText = titleRef.current.textContent;
		setCurrentField((prev) => ({
			...prev,
			title: newText,
		}));
		moveCursorToEnd();
	};
	const moveCursorToEnd = () => {
		if (titleRef.current) {
			const range = document.createRange();
			const sel = window.getSelection();
			range.selectNodeContents(titleRef.current);
			range.collapse(false); // Collapse to the end of the range
			sel.removeAllRanges();
			sel.addRange(range);
		}
	};

	useEffect(() => {
		if (field?.asset?.type === FieldType.COLOR) return;
		handleSetCroppedImage();
	}, [asset]);

	return (
		<BlockActions
			index={index}
			isEditing={
				(isCreating && currentField?.index === index) ||
				(isEditing && currentField?.index === index)
			}
			saveAction={() => {
				handleSaveField(currentField);
				setIsCreating(false);
				setIsEditing(false);
			}}
			editAction={() => {
				handleEditField(obj);
				setIsEditing(true);
			}}
			isInColumn={field?.isInColumn}
			columnIndex={field?.columnIndex || null}
			field={obj}
		>
			<Box
				sx={{
					position: 'relative',
					zIndex: 1,
					margin: '2vmin',
					paddingBlock: '2vmin',
					width: '100%',
				}}
			>
				<Card
					elevation={0}
					sx={{
						width: 'min(370px, 90%)',
						padding: formatSpacingValues(field?.padding),
						margin: formatSpacingValues(field?.margin),
						boxShadow: '0px 0px 30px 0px #0000000D',
						borderRadius: '16px',
					}}
				>
					<CardContent
						sx={{
							height: 230,
							p: 0,
							background:
								field?.asset?.type === ASSET_TYPES.COLOR
									? '#' + field?.asset?.localPath
									: 'transparent',
						}}
						onClick={() => {
							if (currentField?.index !== index) return;

							setIsChoosingAsset(true);
						}}
					>
						{loading &&
							field?.asset?.type !== ASSET_TYPES.COLOR && (
								<Skeleton
									variant='rectangular'
									width={'100%'}
									height={230}
									sx={{ bgcolor: 'grey.400' }}
								/>
							)}
						{!loading &&
							field?.asset?.type !== ASSET_TYPES.COLOR && (
								<CardMedia
									component={'img'}
									height='230'
									image={
										field?.asset
											? field?.asset?.mimetype &&
											  field?.asset?.mimetype?.includes(
													'gif',
											  )
												? getImageSource(field?.asset)
														?.src
												: croppedImageUrl?.replace(
														/\\/g,
														'/',
												  )
											: placeholderImage
									}
									alt={
										field?.asset
											? field?.asset?.name
											: 'placeholder'
									}
								/>
							)}
					</CardContent>

					<CardContent>
						<Typography
							sx={{ outline: 'none !important' }}
							ref={titleRef}
							onClick={() => {
								setEditable(titleRef?.currentElement);
							}}
							contentEditable={
								editable === titleRef?.currentElement &&
								currentField?.index === index
							}
							suppressContentEditableWarning
							onInput={handleInput}
						>
							{field?.title}
						</Typography>
					</CardContent>
				</Card>
			</Box>
		</BlockActions>
	);
};

CardField.propTypes = {
	index: PropTypes.number,
	margin: PropTypes.array,
	padding: PropTypes.array,
	value: PropTypes.string,
	title: PropTypes.string,
	asset: PropTypes.object,
	setIsChoosingAsset: PropTypes.func,
	obj: PropTypes.object,
};

export default CardField;
