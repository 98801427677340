import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './routes/Dashboard';
import ErrorPage from './routes/ErrorPage';
import Login from './routes/Login';
import BrandBook from './routes/Brandbook/BrandBook';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './styles/theme';
import AlertContextProvider from './contexts/AlertContext';
import { UserContextProvider } from './contexts/UserContext';
import { ProtectedRoute } from './routes/ProtectedRoute';

import Team from './routes/Dashboard/Team';
import { DataProvider } from './contexts/DataContext';
import Files from './routes/Dashboard/Files';
import Brands from './routes/Dashboard/Brands';
import { FileProvider } from './contexts/FilesContext';
import Brandbooks from './routes/Dashboard/Brandbooks';
import { BrandbooksProvider } from './contexts/BrandbooksContext';
import Users from './routes/Dashboard/Users';
import Settings from './routes/Dashboard/Settings';
import DashboardLayout from './components/Layouts/DashboardLayout';
import Legal from './routes/Legal';
import { DisplayProvider } from './contexts/DisplayContext';
import FieldsContextProvider from './contexts/FieldsContext';
import { USER_ROLE } from './utils/enums';
import { MutationCache, useQueryClient } from '@tanstack/react-query';

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ProtectedRoute allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}>
				<DashboardLayout />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: (
					<ProtectedRoute
						allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}
					>
						<Dashboard />
					</ProtectedRoute>
				),
			},
			{
				path: '/brands',
				element: (
					<ProtectedRoute
						allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}
					>
						<Brands />
					</ProtectedRoute>
				),
			},
			{
				path: '/team',
				element: (
					<ProtectedRoute
						allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}
					>
						<Team />
					</ProtectedRoute>
				),
			},
			{
				path: '/users',
				element: (
					<ProtectedRoute
						allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}
					>
						<Users />
					</ProtectedRoute>
				),
			},
			{
				path: '/files',
				element: (
					<ProtectedRoute
						allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}
					>
						<FileProvider>
							<Files />
						</FileProvider>
					</ProtectedRoute>
				),
			},
			{
				path: '/brandbooks',
				element: (
					<ProtectedRoute
						allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}
					>
						<DisplayProvider>
							<BrandbooksProvider>
								<FieldsContextProvider>
									<FileProvider>
										<Brandbooks />
									</FileProvider>
								</FieldsContextProvider>
							</BrandbooksProvider>
						</DisplayProvider>
					</ProtectedRoute>
				),
			},
			{
				path: '/settings',
				element: (
					<ProtectedRoute
						allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.TEAM]}
					>
						<Settings />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: '/terms-and-conditions',
		element: <Legal />,
	},
	{
		path: '/login/:brand?/:client?',
		element: <Login />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/brandbook/:brand/:client?',
		element: (
			<ProtectedRoute
				allowedRoles={[
					USER_ROLE.ADMIN,
					USER_ROLE.TEAM,
					USER_ROLE.CLIENT,
				]}
			>
				<DisplayProvider>
					{/* <BrandbooksProvider>
						<FieldsContextProvider> */}
					<BrandBook />
					{/* </FieldsContextProvider>
					</BrandbooksProvider> */}
				</DisplayProvider>
			</ProtectedRoute>
		),

		errorElement: <ErrorPage />,
	},
]);

function App() {
	return (
		<ThemeProvider theme={theme}>
			<UserContextProvider>
				<AlertContextProvider>
					<DataProvider>
						{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
						<CssBaseline />
						<RouterProvider router={router} />
					</DataProvider>
				</AlertContextProvider>
			</UserContextProvider>
		</ThemeProvider>
	);
}

export default App;
