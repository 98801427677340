import Cropper from 'react-easy-crop';

import PropTypes from 'prop-types';
import { useState } from 'react';
import 'cropperjs/dist/cropper.css';

const ImageCropper = ({
	aspect,
	imageUrl,
	onCropComplete,
	imgWidth,
	imgHeight,
	objectFit,
}) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);

	return (
		<Cropper
			crop={crop}
			zoom={zoom}
			aspect={aspect}
			onCropChange={setCrop}
			image={imageUrl}
			onCropComplete={onCropComplete}
			onZoomChange={setZoom}
			objectFit={objectFit || 'contain'}
		/>
	);
};
ImageCropper.propTypes = {
	aspect: PropTypes.number,
	imageUrl: PropTypes.string,
	onCropComplete: PropTypes.func,
	imgWidth: PropTypes.number,
	imgHeight: PropTypes.number,
	objectFit: PropTypes.string,
};
export default ImageCropper;
