import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';
import OverviewIcon from '../../assets/images/overview.png';
import AssetsIcon from '../../assets/images/assets.png';
import SupportIcon from '../../assets/images/support.png';
import useGetFoldersByBrand from '../../api/hooks/files/useGetFoldersByBrand';
import { capitalizeFirstLetter } from '../../utils/globals';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CountBadge } from '../../styles/layouts';
import { getTotalFilesInFolder } from '../../utils/calculations';

const BookNavBar = ({ brand }) => {
	const { data: folders } = useGetFoldersByBrand(brand?.id);
	let [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const ITEMS = [
		{
			title: 'Overview',
			link: 'overview',
			icon: OverviewIcon,
		},
		{
			title: 'Brand Assets',
			link: 'assets',
			icon: AssetsIcon,
		},
		{
			title: 'Support',
			link: 'support',
			icon: SupportIcon,
		},
	];

	return (
		<nav
			style={{
				width: '90%',
				marginInlineStart: 'auto',
				marginTop: '4vh',
			}}
		>
			<List>
				{ITEMS.map((item, index) => (
					<ListItem
						disablePadding
						key={index}
						sx={{
							width: '90%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'start',
							borderBottom:
								item.link === 'assets'
									? '1px solid #D9D9D9'
									: 'none',
							paddingBottom:
								item.link === 'assets' ? '4vmin' : '0',
						}}
					>
						<ListItemButton
							disableRipple
							sx={{
								width: '100%',
							}}
							onClick={() => {
								if (!item.link) return;
								const searchParams = new URLSearchParams();

								// Add search parameters
								searchParams.append('view', item.link);

								setSearchParams(searchParams);
							}}
						>
							<ListItemIcon
								sx={{ minWidth: 'unset', marginRight: 1 }}
							>
								<img src={item.icon} alt={item.title} />
							</ListItemIcon>
							<ListItemText primary={item.title} />
						</ListItemButton>
						{item.link === 'assets' && (
							<List
								component='ul'
								disablePadding
								sx={{
									borderLeft: '1px solid #D9D9D9',
									ml: 3.5,
									mt: 2,
									width: '90%',
								}}
							>
								{folders?.data &&
									folders.data
										.filter((item) => item?.Asset.length)
										.map((folder) => {
											const totalFiles =
												getTotalFilesInFolder(folder);

											return (
												<ListItemButton
													key={folder?.id}
													sx={{
														pl: 5,
													}}
													disableRipple
													onClick={() => {
														if (!item.link) return;
														const searchParams =
															new URLSearchParams();

														// Add search parameters
														searchParams.append(
															'view',
															'assets',
														);
														searchParams.append(
															'type',
															folder?.name.toLowerCase(),
														);

														setSearchParams(
															searchParams,
														);
													}}
												>
													<ListItemText
														primary={capitalizeFirstLetter(
															folder?.name,
														)}
														secondary={
															<CountBadge>
																{totalFiles}{' '}
																files
															</CountBadge>
														}
														sx={{
															display: 'flex',
															gap: 1,
															alignItems:
																'center',
															color: '#00000085',
														}}
													/>
												</ListItemButton>
											);
										})}
							</List>
						)}
					</ListItem>
				))}
			</List>
		</nav>
	);
};

BookNavBar.propTypes = {
	brand: PropTypes.object,
};
export default BookNavBar;
