import { useQuery } from '@tanstack/react-query';
import { getBrandByClient, getUsers } from '../../users';

const useGetBrandByClient = (clientId, enabled = true) => {
	const QUERY_KEY = ['getClientBrand', clientId];
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getBrandByClient(clientId),
		enabled,
	});
};

export default useGetBrandByClient;
