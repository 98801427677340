import { useMutation } from '@tanstack/react-query';

import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { deleteBrand } from '../../brands';

const useDeleteBrand = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleDeleteBrand, isPending } = useMutation({
		mutationFn: (brandId) => deleteBrand({ brandId }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleDeleteBrand, isPending };
};

export default useDeleteBrand;
