import { useMutation } from '@tanstack/react-query';
import { updatePassword } from '../../users';

import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';
import { useLogin } from '../../../contexts/LoginContext';

const useUpdatePassword = (handleSuccess) => {
	const { handleAlert } = useAlert();
	const { setIsLoading } = useLogin();

	const { mutate: updateNewPassword, isPending } = useMutation({
		mutationFn: ({ email, newPass, code }) =>
			updatePassword({ email, newPass, code }),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(
				ALERT_STATUS.ERROR,
				error.response.data.error || error.message,
			);
		},
	});

	return { updateNewPassword, isPending };
};

export default useUpdatePassword;
