import {
	fetchDelete,
	fetchGet,
	fetchPost,
	fetchPut,
	GLOBAL_URL,
} from './global';
export const createBrand = async (data) => {
	return await fetchPost(`${GLOBAL_URL}brands/createBrand`, data);
};
export const getBrands = async () => {
	return await fetchGet(`${GLOBAL_URL}brands/getBrands`);
};
export const getBrandClients = async (brand) => {
	return await fetchGet(
		`${GLOBAL_URL}brands/getBrandClients?brandId=${brand}`,
	);
};
export const updateBrand = async (data) => {
	return await fetchPut(`${GLOBAL_URL}brands/updateBrand`, data);
};

export const deleteBrand = async (data) => {
	return await fetchDelete(`${GLOBAL_URL}brands/deleteBrand`, data);
};

//CLIENTS//
export const createClient = async (data) => {
	return await fetchPost(`${GLOBAL_URL}clients/createClient`, data);
};

export const editClient = async (data) => {
	return await fetchPut(`${GLOBAL_URL}clients/editClient`, data);
};
export const resendCredentials = async () => {
	return await fetchPost(`${GLOBAL_URL}clients/resendCredentials`);
};
export const deleteClient = async (data) => {
	return await fetchDelete(`${GLOBAL_URL}clients/deleteClient`, data);
};
