import { Box, Grid, Typography } from '@mui/material';

import PropTypes from 'prop-types';

const ElementButton = ({ icon, title, action }) => {
	return (
		<Grid
			container
			// xs={3.5}
			maxWidth={'100px'}
			gap={0.5}
			direction={'column'}
			onClick={action}
			alignItems={'center'}
			sx={{
				bgcolor: 'background.default',
				borderRadius: '8px',
				// px: 1.5,
				py: 2,
				transition: 'all 0.2s ease-in-out',
				'&:hover': {
					cursor: 'pointer',
					transform: ' scale(1.075)',
				},
			}}
		>
			{icon}
			<Typography variant={'p'} fontSize={14}>
				{title}
			</Typography>
		</Grid>
	);
};

ElementButton.propTypes = {
	icon: PropTypes.element,
	title: PropTypes.string,
	action: PropTypes.func,
};

export default ElementButton;
