import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { createCategory } from '../../files';

const useCreateCategory = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleCreateCategory, isPending } = useMutation({
		mutationFn: (data) => createCategory(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleCreateCategory, isPending };
};

export default useCreateCategory;
