import { Box, ImageListItem, Skeleton } from '@mui/material';
import { ASSET_TYPES } from '../../utils/enums';
import palette from '../../styles/palette';
import PropTypes from 'prop-types';
// import useGetThumbnail from '../../hooks/useGetThumbnail';
import { getImageSource } from '../../utils/globals';
const ImageListAssetCard = ({ asset, selectedAsset, setSelectedAsset }) => {
	// const { thumbnail, isGeneratingThumb } = useGetThumbnail(asset, 145, 145);
	const imageSource =
		asset.type === ASSET_TYPES.DOC && asset?.File?.length > 0
			? getImageSource(
					asset?.File[0], // SWITCH TO THUMBNAIL
			  )
			: getImageSource(asset);
	return (
		<ImageListItem
			key={asset?.name}
			sx={{
				borderRadius: 1,
				overflow: 'hidden',
				cursor: 'pointer',
				border: '2px solid',
				borderColor:
					selectedAsset?.id === asset?.id
						? palette.info.main
						: 'transparent',
				'&: hover': {
					transition: 'all 0.25s ease',
					border: `2px solid ${palette.info.main}`,
				},
				background: palette.background.grey,
			}}
			onClick={() => {
				setSelectedAsset(asset);
			}}
		>
			{asset.type === ASSET_TYPES.COLOR ? (
				<Box
					sx={{
						backgroundColor: '#' + asset.localPath,
						width: '100%',
						height: '100%',
						borderRadius: 1,
						overflow: 'hidden',
					}}
				></Box>
			) : !imageSource ? (
				<Skeleton variant='rounded' width={145} />
			) : (
				<img {...imageSource} alt={asset?.name} loading='lazy' />
			)}
		</ImageListItem>
	);
};

ImageListAssetCard.propTypes = {
	asset: PropTypes.object,
	selectedAsset: PropTypes.object,
	setSelectedAsset: PropTypes.func,
};

export default ImageListAssetCard;
