import { useCallback, useEffect, useState } from 'react';

import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Fade,
	Grid,
	Skeleton,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { formatSpacingValues } from '../../utils/fields';

import placeholderImage from '../../assets/images/placeholder.jpg';
import { handleCroppedImage } from '../../utils/cropImage';
import { getImageSource } from '../../utils/globals';
import { ASSET_TYPES } from '../../utils/enums';
import { getTextColor } from '../../utils/calculations';
import { useAlert } from '../../contexts/AlertContext';

const CardDisplay = ({ field, lang }) => {
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	const [isHover, setIsHover] = useState(false);

	const [loading, setLoading] = useState(true);

	const { handleAlert } = useAlert();

	const handleGetCroppedImage = useCallback(async () => {
		setCroppedImageUrl(null);
		if (field?.asset?.localPath || field?.asset?.externalLink) {
			const croppedImage = await handleCroppedImage(
				field,
				handleAlert,
				setLoading,
			);

			if (!croppedImage) return;
			setCroppedImageUrl(croppedImage);
		}
	}, [field?.asset]);

	useEffect(() => {
		field && handleGetCroppedImage();
	}, [field]);
	return (
		<Card
			elevation={0}
			sx={{
				width: '370px',
				padding: formatSpacingValues(field?.padding),
				margin: formatSpacingValues(field?.margin),
				boxShadow: '0px 0px 30px 0px #0000000D',
				borderRadius: '16px',
			}}
			onMouseOver={() => {
				setIsHover(true);
			}}
			onMouseOut={() => {
				setIsHover(false);
			}}
		>
			<CardContent
				sx={{
					height: 230,
					p: 0,
					position: 'relative',
					backgroundColor:
						field?.asset?.type === ASSET_TYPES.COLOR
							? '#' + field?.asset?.localPath
							: 'transparent',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{loading && field?.asset?.type !== ASSET_TYPES.COLOR && (
					<Skeleton
						variant='rectangular'
						width={'100%'}
						height={230}
					/>
				)}
				{!loading && field?.asset?.type !== ASSET_TYPES.COLOR && (
					<CardMedia
						component='img'
						height='230'
						image={
							field?.asset
								? field?.asset?.mimetype &&
								  field?.asset?.mimetype?.includes('gif')
									? getImageSource(field?.asset)?.src
									: croppedImageUrl?.replace(/\\/g, '/')
								: placeholderImage
						}
						alt={field?.asset ? field?.asset?.name : 'placeholder'}
					/>
				)}
				{field?.brandPersonality &&
					field?.brandPersonality?.title &&
					!isHover && (
						<Typography
							position={'absolute'}
							color={
								getTextColor('#' + field?.asset?.localPath) ||
								'#fff'
							}
							fontWeight={700}
							fontSize={'2em'}
						>
							{field?.brandPersonality?.title}
						</Typography>
					)}
				{isHover &&
					!field?.brandPersonality &&
					field?.asset?.type === ASSET_TYPES.COLOR && (
						<Fade in={isHover}>
							<Grid
								container
								justifyContent={'center'}
								// alignItems={'center'}
								flexDirection={'column'}
								paddingInline={5}
								gap={1}
								sx={{
									position: 'absolute',
									inset: 0,
									background:
										field?.asset?.type === ASSET_TYPES.COLOR
											? ''
											: 'rgba(239, 238, 238, 0.9)',
									color:
										field?.asset?.type === ASSET_TYPES.COLOR
											? 'background.paper'
											: 'inherit',
								}}
								height='230'
							>
								{JSON.parse([field?.asset?.description]).map(
									(color, index) => (
										<Typography
											key={index}
											fontWeight={300}
											color={getTextColor(
												'#' + field?.asset?.localPath,
											)}
											display={'flex'}
											gap={1}
										>
											<p>{color?.name}</p>
											<p>{color?.value}</p>
										</Typography>
									),
								)}
							</Grid>
						</Fade>
					)}
				{isHover &&
					(field?.brandPersonality?.text ||
						(field?.hover?.text &&
							field?.hover?.text !== 'Simple')) && (
						<Fade in={isHover}>
							<Grid
								container
								justifyContent={'center'}
								alignItems={'center'}
								sx={{
									position: 'absolute',
									inset: 0,
									background:
										field?.asset?.type === ASSET_TYPES.COLOR
											? ''
											: 'rgba(239, 238, 238, 0.9)',
									color:
										field?.asset?.type === ASSET_TYPES.COLOR
											? 'background.paper'
											: 'inherit',
								}}
								height='230'
							>
								{field?.brandPersonality?.text ? (
									<Grid
										container
										flexDirection={'column'}
										gap={1}
										paddingInline={5}
									>
										<Typography
											color={
												field?.asset?.localPath?.includes(
													'public',
												) || !field?.asset?.localPath
													? 'rgba(74, 74, 74, 1)'
													: getTextColor(
															'#' +
																field?.asset
																	?.localPath,
													  )
											}
											fontWeight={700}
											fontSize={'1.4em'}
										>
											{field?.brandPersonality?.title}
										</Typography>
										<Typography
											color={
												field?.asset?.localPath?.includes(
													'public',
												) || !field?.asset?.localPath
													? 'rgba(74, 74, 74, 1)'
													: getTextColor(
															'#' +
																field?.asset
																	?.localPath,
													  )
											}
											fontWeight={300}
										>
											<div
												dangerouslySetInnerHTML={{
													__html: field
														?.brandPersonality
														?.text,
												}}
											/>
										</Typography>
									</Grid>
								) : (
									<Grid
										container
										flexDirection={'column'}
										gap={1}
										paddingInline={5}
										fontWeight={300}
										sx={{
											'& strong': {
												fontWeight: 700,
												fontSize: '1.4em',
											},
											'& h6': {
												fontWeight: 700,
												fontSize: '1.4em',
												margin: 0,
											},
										}}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: field?.hover?.text,
											}}
										/>
									</Grid>
								)}
							</Grid>
						</Fade>
					)}
			</CardContent>

			<CardContent>
				<Typography
					sx={{ outline: 'none !important', wordBreak: 'break-all' }}
				>
					{field?.title}
				</Typography>
			</CardContent>
		</Card>
	);
};

CardDisplay.propTypes = {
	field: PropTypes.object,
	lang: PropTypes.string,
};

export default CardDisplay;
