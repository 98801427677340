import { CircularProgress, Typography } from '@mui/material';
import GeneralButton from '../Inputs/GeneralButton';
import CustomInput from '../Inputs/CustomInput';
import { useEffect, useState } from 'react';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import PropTypes from 'prop-types';
import useSendHelp from '../../api/hooks/users/useSendHelp';

const StepHelp = ({ client }) => {
	const { handleAlert } = useAlert();

	const [mailSent, setMailSent] = useState(false);
	const [formData, setFormData] = useState({
		fromDetails: '',
		emailMsg: '',
	});

	const { handleSendHelp } = useSendHelp(() => {
		setMailSent(true);
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!formData.fromDetails)
			return handleAlert(ALERT_STATUS.ERROR, 'Missing name / company.');

		if (!formData.emailMsg)
			return handleAlert(ALERT_STATUS.ERROR, 'Missing message.');
		const dataToSend = {
			...formData,
		};
		if (client) dataToSend.clientEmail = client.email;
		handleSendHelp(dataToSend);
	};

	if (mailSent)
		return (
			<>
				<Typography variant='h1'>Thank you!</Typography>
				<Typography
					variant='p'
					mb={'auto !important'}
					align='center'
					fontWeight={500}
				>
					Your Message was sent and our team will get back to you
					shortly!
				</Typography>
				{/* add back to login + align everything better + change email template it looks bad */}
			</>
		);

	return (
		<>
			<Typography variant='h1'>Need Help?</Typography>
			<Typography
				variant='p'
				mb={'auto !important'}
				align='center'
				fontWeight={500}
			>
				Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem
				ipsum dolor sit.
			</Typography>
			<form onSubmit={handleSubmit}>
				<CustomInput
					label='Your name / company'
					inputType='text'
					name='fromDetails'
					value={formData.fromDetails}
					action={({ target: { value } }) => {
						setFormData((prev) => ({
							...prev,
							fromDetails: value,
						}));
					}}
				/>
				<CustomInput
					label='Please summarize your request *'
					inputType='text'
					multiline={true}
					rows={5}
					mt={2}
					name={'emailMsg'}
					value={formData.emailMsg}
					action={({ target: { value } }) => {
						setFormData((prev) => ({
							...prev,
							emailMsg: value,
						}));
					}}
				/>
				<GeneralButton mt={'24px'} type='submit'>
					Send
				</GeneralButton>
			</form>
			<Typography
				variant='p'
				mt={'auto !important'}
				align='center'
				fontWeight={500}
			>
				Lorem ipsum dolor sit.
			</Typography>
		</>
	);
};

StepHelp.propTypes = {
	client: PropTypes.object,
};
export default StepHelp;
