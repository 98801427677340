import { createContext, useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LANGUAGES } from '../utils/enums';
import useGetAllBrandBooks from '../api/hooks/brandbooks/useGetAllBrandBooks';
import { useLocation } from 'react-router-dom';

const BrandbooksContext = createContext();

export const useBrandbooksContext = () => useContext(BrandbooksContext);

export const BrandbooksProvider = ({ children }) => {
	const [isCreating, setIsCreating] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [currentBrand, setCurrentBrand] = useState(null);
	const [currentBrandBook, setCurrentBrandBook] = useState(null);
	const [bannerField, setBannerField] = useState(null);
	const [language, setLanguage] = useState(LANGUAGES.ENG);
	const { data, isLoading, refetch } = useGetAllBrandBooks();
	const [brandBooks, setBrandBooks] = useState([]);

	const handleEditBrandbook = (brandbook) => {
		setCurrentBrandBook(brandbook);
		setCurrentBrand(brandbook?.brand);
		setBannerField({
			Asset: brandbook?.bannerAsset,
			crop: brandbook?.bannerPixelCrop,
		});
		setLanguage(brandbook?.language);
	};

	const resetContext = () => {
		setCurrentBrand(null);
		setCurrentBrandBook(null);
		setBannerField(null);
		setLanguage(LANGUAGES.ENG);
		refetch();
	};

	useEffect(() => {
		if (data !== null) setBrandBooks(data?.data);
	}, [data]);

	const contextValue = useMemo(
		() => ({
			isCreating,
			setIsCreating,
			currentBrand,
			setCurrentBrand,
			isEditing,
			setIsEditing,
			bannerField,
			setBannerField,
			language,
			setLanguage,
			handleEditBrandbook,
			currentBrandBook,
			setCurrentBrandBook,
			resetContext,
			brandBooks,
			setBrandBooks,
			isLoading,
		}),
		[
			isCreating,
			currentBrand,
			isEditing,
			bannerField,
			language,
			currentBrandBook,
			brandBooks,
		],
	);

	return (
		<BrandbooksContext.Provider value={contextValue}>
			{children}
		</BrandbooksContext.Provider>
	);
};

BrandbooksProvider.propTypes = {
	children: PropTypes.element,
};
