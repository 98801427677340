import { useQuery } from '@tanstack/react-query';

import { adminSearch, getFields } from '../../brandbooks';
import { useEffect } from 'react';

const useGetSearchResults = (query) => {
	const QUERY_KEY = ['adminSearch', query];

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => adminSearch(query),
		enabled: query?.length >= 3,
	});

	useEffect(() => {
		// Watch for changes in folderId and refetch data if it changes
		if (query !== null && query.length >= 3) {
			refetch();
		}
	}, [query, refetch]);

	return { data, isLoading, isError, error };
};

export default useGetSearchResults;
