import {
	Box,
	Grid,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	lighten,
} from '@mui/material';
import BasicDialog from '../Layouts/BasicDialog';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import palette from '../../styles/palette';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import useUploadAssetFile from '../../api/hooks/files/useUploadAssetFile';
import useCreateAsset from '../../api/hooks/files/useCreateAsset';
import { useFileContext } from '../../contexts/FilesContext';
import useDeleteAssets from '../../api/hooks/files/useDeleteAssets';
import MultiUploader from '../Inputs/MultiUploader';

function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant='determinate' {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography
					variant='body2'
					color='text.secondary'
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

LinearProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
};

const MultipleUploadDialog = ({ isOpen, setIsOpen }) => {
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [deletedIndexes, setDeletedIndexes] = useState([]);
	const [progress, setProgress] = useState([]);
	const { currentFolder, assetType, updateFilesInFolder } = useFileContext();

	const handleUploadDone = (data) => {
		setUploadedFiles((prev) => [...prev, data.data]);
	};

	const handleUploadProgress = (progressEvent) => {
		const percentCompleted = Math.round(
			(progressEvent.loaded * 100) / progressEvent.total,
		);

		setProgress((prev) => [
			...prev.slice(0, currentIndex),
			percentCompleted,
			...prev.slice(currentIndex + 1),
		]);
	};

	const { handleUploadAssetFile, isPending } = useUploadAssetFile(
		handleUploadDone,
		handleUploadProgress,
	);

	const { handleCreateAsset } = useCreateAsset((data) => {
		setCurrentIndex((prev) => prev + 1);
		uploadNextFile(data);
	});

	const { handleDeleteAssets } = useDeleteAssets((data) => {
		if (data?.data && data?.data?.count > 0) {
			const newFilesToDisplay = [
				...uploadedFiles.filter(
					(file, index) => !deletedIndexes.includes(index),
				),
			];

			setUploadedFiles(newFilesToDisplay);
			setFilesToUpload(newFilesToDisplay);
			setDeletedIndexes([]);
		}
	});

	const uploadNextFile = async (data) => {
		const file = filesToUpload[currentIndex];
		const formData = new FormData();
		formData.append('assetId', data?.data?.id);
		formData.append('asset', file);
		await handleUploadAssetFile(formData);
	};

	const uploadFiles = async (acceptedFiles) => {
		for (const file of acceptedFiles) {
			const assetData = {
				asset: file,
				folderId: currentFolder?.id,
				type: assetType,
				name: file?.name,
			};
			await handleCreateAsset(assetData);
		}
	};

	const onDropAction = (acceptedFiles) => {
		setFilesToUpload((prev) => [...prev, ...acceptedFiles]);
		setProgress(Array.from({ length: acceptedFiles.length }, () => 0));
		uploadFiles(acceptedFiles);
	};

	const handleClose = () => {
		setIsOpen(false);
		setCurrentIndex(0);
		setFilesToUpload([]);
		setUploadedFiles([]);
		setDeletedIndexes([]);
		setProgress([]);
	};

	const actions = [
		{
			actionHandler: () => {
				//check if files have ids and if need to delete
				handleClose();
			},
			isMain: false,
		},
		{
			actionHandler: () => {
				updateFilesInFolder(uploadedFiles);
				handleClose();
			},
			isMain: true,
		},
	];

	return (
		<BasicDialog
			actions={actions}
			handleClose={handleClose}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={'Upload Files'}
			maxWidth={900}
		>
			<MultiUploader onDropAction={onDropAction} />

			{filesToUpload.length > 0 && (
				<List>
					{filesToUpload.map((file, index) => (
						<ListItem
							key={index}
							alignItems={'center'}
							secondaryAction={
								// add percentage
								<IconButton
									edge='end'
									aria-label='delete'
									disabled={
										uploadedFiles[index] === undefined
									}
									onClick={() => {
										setDeletedIndexes((prev) => [
											...prev,
											index,
										]);
										handleDeleteAssets({
											assets: [uploadedFiles[index]],
										});
									}}
								>
									<ClearOutlinedIcon />
								</IconButton>
							}
						>
							<ListItemIcon>
								<AttachFileOutlinedIcon />
							</ListItemIcon>
							<ListItemText
								primary={file?.name}
								secondary={
									<LinearProgressWithLabel
										value={progress[index]}
									/>
								}
							/>
						</ListItem>
					))}
				</List>
			)}
		</BasicDialog>
	);
};
MultipleUploadDialog.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
};
export default MultipleUploadDialog;
