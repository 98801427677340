import { Link, Typography } from '@mui/material';
import CustomInput from '../Inputs/CustomInput';
import GeneralButton from '../Inputs/GeneralButton';
import { useState } from 'react';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import useUpdatePassword from '../../api/hooks/users/useUpdatePassword';
import { generatePassword } from '../../utils/passwordGenerator';
import PropTypes from 'prop-types';

const StepNewPass = ({ email, handleNextStep }) => {
	const [newPass, setNewPass] = useState({ origin: '', copy: '' });
	const { handleAlert } = useAlert();

	const { updateNewPassword } = useUpdatePassword(({ data }) => {
		console.log(data);
		handleNextStep(data);
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!newPass.origin || !newPass.copy)
			return handleAlert(ALERT_STATUS.ERROR, 'Please Enter Password');
		if (newPass.origin !== newPass.copy)
			return handleAlert(
				ALERT_STATUS.ERROR,
				"Passwords values don't match",
			);
		updateNewPassword({ email, newPass: newPass.origin });
	};

	return (
		<>
			<Typography variant='h1'>Enter New Password</Typography>
			<Typography
				variant='p'
				mb={'auto !important'}
				align='center'
				fontWeight={500}
			>
				First time signed in users must update their password
			</Typography>
			<form onSubmit={handleSubmit}>
				<CustomInput
					label='New Password'
					inputType='password'
					name='password'
					value={newPass.origin}
					action={({ target: { value } }) => {
						setNewPass((prev) => ({
							...prev,
							origin: value,
						}));
					}}
				/>
				<CustomInput
					label='Confirm Password'
					inputType='password'
					name='passwordCopy'
					mt={2}
					value={newPass.copy}
					action={({ target: { value } }) => {
						setNewPass((prev) => ({
							...prev,
							copy: value,
						}));
					}}
				/>
				<GeneralButton mt={'24px'} type='submit'>
					Continue
				</GeneralButton>
			</form>

			<Link
				component='button'
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 1,
					marginTop: 'auto !important',
				}}
				onClick={() => {
					const generated = generatePassword(16);
					setNewPass({ origin: generated, copy: generated });
				}}
			>
				Password Generator
			</Link>
			<small>
				Make sure to copy and save the password in a secure location
			</small>
		</>
	);
};

StepNewPass.propTypes = {
	email: PropTypes.string.isRequired,
	handleNextStep: PropTypes.func.isRequired,
};
export default StepNewPass;
