import { useMutation } from "@tanstack/react-query";
import { createUser } from "../../users";

import { ALERT_STATUS, useAlert } from "../../../contexts/AlertContext";

const useCreateUser = (handleSuccess) => {
  const { handleAlert } = useAlert();

  const {
    mutate: handleCreateUser,
    isPending,
    isError,
  } = useMutation({
    mutationFn: (data) => createUser(data),
    onSuccess: handleSuccess,
    onError: (error) => {
      handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
    },
  });

  return { handleCreateUser, isPending, isError };
};

export default useCreateUser;
