import { CircularProgress, Grid, Typography } from '@mui/material';
import PageHeader from '../../components/Headers/PageHeader';
import GeneralButton from '../../components/Inputs/GeneralButton';
import { useEffect, useState } from 'react';
import useDropboxAuth from '../../api/hooks/general/useDropboxAuth';

const Settings = () => {
	const [getAuth, setGetAuth] = useState(false);
	const { data, isError, isFetching, error } = useDropboxAuth(getAuth);

	useEffect(() => {
		if (!isError && data) {
			window.open(data?.data?.authUrl, '_blank');
			setGetAuth(false);
		}
	}, [data, isError]);
	return (
		<Grid container wrap='nowrap' gap={5} mt={4}>
			<Grid container direction={'column'}>
				<PageHeader title={'Settings'} button={undefined} mt={5} />
				<Grid container gap={2} alignItems={'center'}>
					<Typography variant='h6'>Dropbox Authentication</Typography>
					<GeneralButton
						onClick={() => {
							setGetAuth(true);
						}}
					>
						{isFetching ? (
							<CircularProgress color='secondary' size={24} />
						) : (
							'Connect to Dropbox'
						)}
					</GeneralButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Settings;
