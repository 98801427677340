import { Avatar } from "@mui/material";
import PropTypes from "prop-types";

const avatarColors = [
  "#529027",
  "#6F83E4",
  "#855DBD",
  "#136066",
  "#5429FD",
  "#2F507D",
  "#332C72",
  "#A08CFD",
  "#EC6DE6",
  "#3F122F",
  "#274BA9",
];

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * avatarColors.length);
  return avatarColors[randomIndex];
};

const CustomAvatar = ({ image, name, size, sx }) => {
  const isURL = (str) => {
    return /^https?:\/\//.test(str);
  };
  return (
    <Avatar
      alt={name}
      src={image || ""}
      sx={{
        ...sx,
        width: size,
        height: size,
        bgcolor: getRandomColor(),
      }}
    />
  );
};

CustomAvatar.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default CustomAvatar;
