import PropTypes from 'prop-types';
import PageHeader from '../Headers/PageHeader';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import GeneralButton from '../Inputs/GeneralButton';
import { Grid, IconButton } from '@mui/material';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import { Link, useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import useCreateBrandBook from '../../api/hooks/brandbooks/useCreateBrandbook';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import { GENERAL_LINK_BRANDBOOK } from '../../utils/globals';
import useEditBrandBook from '../../api/hooks/brandbooks/useEditBrandBook';
import { DISPLAY, useDisplayContext } from '../../contexts/DisplayContext';
import { useFields } from '../../contexts/FieldsContext';

import { handleSetBrandBookFields } from '../../utils/fields';

const BrandbookLayout = ({ children, builderRef }) => {
	const { handleAlert } = useAlert();
	const {
		currentBrand,
		bannerField,
		language,
		currentBrandBook,
		handleEditBrandbook,
		resetContext,
		setIsCreating,
		setIsEditing,
		setBrandBooks,
	} = useBrandbooksContext();
	const {
		handleSaveField,
		deletedFields,
		updatedFields,
		newFields,
		currentField,
		resetFieldsContext,
		brandBookFields,
		setBrandBookFields,
	} = useFields();
	const { displayMode, handleChangeDisplay } = useDisplayContext();
	const navigate = useNavigate();
	const { handleCreateBrandbook } = useCreateBrandBook((data) => {
		if (data?.data) {
			handleAlert(ALERT_STATUS.SUCCESS, data.message);
		}
	});

	const { handleEditBrandBook } = useEditBrandBook((data) => {
		handleEditBrandbook(data.data);
		resetFieldsContext();
		setBrandBookFields(
			handleSetBrandBookFields(data?.data?.BrandbookFields),
		);
		setBrandBooks((prev) =>
			prev.map((b) => (b?.id === data.data?.id ? data?.data : b)),
		);
		if (data?.data) {
			handleAlert(ALERT_STATUS.SUCCESS, data.message);
		}
	});

	const AddBrandbook = (mt) => {
		return displayMode === DISPLAY.CARDS ? (
			<GeneralButton
				icon={<AutoStoriesOutlinedIcon />}
				mt={mt}
				onClick={() => {
					handleChangeDisplay();
				}}
			>
				New Brandbook
			</GeneralButton>
		) : null;
	};

	const handleGetUpdatedField = (id) => {
		return updatedFields.find((field) => field?.id === id);
	};

	const handleRemoveDeleted = (item) => {
		return !deletedFields.find((f) => f === item?.id);
	};

	const saveBrandBook = () => {
		currentField !== null && handleSaveField(currentField);
		setIsCreating(false);
		setIsEditing(false);

		const brandBookObj = {
			brandId: currentBrand?.id,
			assetId: bannerField?.Asset?.id,
			bannerPixelCrop: bannerField?.crop,
			language,
			deletedFields,
			updatedFields: brandBookFields
				.map((item) => {
					return handleGetUpdatedField(item?.id) || item;
				})
				.filter(handleRemoveDeleted),
			newFields,
		};

		if (currentBrandBook) {
			brandBookObj.id = currentBrandBook?.id;
			handleEditBrandBook(brandBookObj);
		} else {
			handleCreateBrandbook(brandBookObj);
		}
	};

	return (
		<Grid container direction={'column'}>
			<Grid
				container
				gap={1}
				// width={'100%'}
				wrap='nowrap'
			>
				<PageHeader
					title={
						<Grid
							container
							width={'fit-content'}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							{displayMode === DISPLAY.BUILDER && (
								<IconButton
									aria-label='back'
									onClick={() => {
										handleChangeDisplay();
										resetContext();
										resetFieldsContext();
										// invalidate brandbook query
									}}
								>
									<ArrowBackIosNewOutlinedIcon />
								</IconButton>
							)}
							Brandbooks
						</Grid>
					}
					button={AddBrandbook(0)}
					mt={5}
				/>
				{displayMode === DISPLAY.BUILDER && (
					<Grid
						container
						alignItems={'center'}
						justifyContent={'space-between'}
						width={'fit-content'}
						gap={3}
						wrap='nowrap'
						ref={builderRef}
					>
						<GeneralButton
							variant='text'
							icon={<RemoveRedEyeOutlinedIcon />}
							onClick={() => {
								if (
									updatedFields?.length ||
									newFields?.length ||
									deletedFields?.length
								)
									return handleAlert(
										ALERT_STATUS.WARNING,
										'You have unsaved changes. Please save brand book before previewing.',
									);
								navigate(
									`${
										GENERAL_LINK_BRANDBOOK +
										currentBrand?.name
									}`,
									{ state: { preview: true } },
								);
							}}
						>
							Preview
						</GeneralButton>
						<GeneralButton onClick={saveBrandBook}>
							Save
						</GeneralButton>
					</Grid>
				)}
			</Grid>
			{children}
		</Grid>
	);
};

BrandbookLayout.propTypes = {
	children: PropTypes.element,
	builderRef: PropTypes.element,
};

export default BrandbookLayout;
