import {
	fetchDelete,
	fetchGet,
	fetchPost,
	fetchPut,
	GLOBAL_URL,
} from './global';

export const addBrandBook = async (data) => {
	return await fetchPost(`${GLOBAL_URL}brandbooks/addBrandBook`, data);
};
export const sendLink = async (data) => {
	return await fetchPost(`${GLOBAL_URL}brandbooks/sendLink`, data);
};
export const editBrandBook = async (data) => {
	return await fetchPut(`${GLOBAL_URL}brandbooks/editBrandBook`, data);
};
export const handleUpdateStatus = async (data) => {
	return await fetchPut(`${GLOBAL_URL}brandbooks/updateStatus`, data);
};
export const getBrandbook = async (brandName) => {
	return await fetchGet(
		`${GLOBAL_URL}brandbooks/getBrandbook?brandName=${brandName}`,
	);
};
export const deleteBrandbook = async (data) => {
	return await fetchDelete(`${GLOBAL_URL}brandbooks/deleteBrandbook`, data);
};
export const getAllBrandbooks = async (filter) => {
	return await fetchGet(
		`${GLOBAL_URL}brandbooks/getAllBrandbooks?status=${filter}`,
	);
};
export const getFieldsByBrandbook = async (brandbookId) => {
	return await fetchGet(
		`${GLOBAL_URL}brandbooks/getFieldsByBrandbook?brandbookId=${brandbookId}`,
	);
};
export const getFields = async (brandbookId) => {
	return await fetchGet(
		`${GLOBAL_URL}brandbooks/getFields?brandbookId=${brandbookId}`,
	);
};
export const adminSearch = async (query) => {
	return await fetchGet(
		`${GLOBAL_URL}brandbooks/generalAdminSearch?search=${query}`,
	);
};

export const getDashboardData = async (data) => {
	return await fetchGet(`${GLOBAL_URL}brandbooks/getDashboardData`);
};
