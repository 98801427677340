import { useMemo, useCallback } from 'react';
import { FieldType } from '../contexts/FieldsContext';

const useFlatFields = (brandBookFields) => {
	const flatFields = useMemo(
		() => [
			...brandBookFields,
			...brandBookFields
				.filter((f) => f?.type === FieldType.COLUMNS)
				.flatMap((f) => f.value),
		],
		[brandBookFields],
	);

	const findField = useCallback(
		(index) => {
			return flatFields.find((field) => field?.index === index);
		},
		[flatFields],
	);

	const lastFieldIndex = (fieldsArray) => {
		return Math.max(
			...fieldsArray.map((field) => field?.index).filter((item) => item),
		);
	};

	const newFieldIndex = useCallback(
		(index) => {
			return updateFields([...brandBookFields], index);
		},
		[brandBookFields],
	);

	const updateFields = (fieldsArray, index) => {
		let newIndex = index + 1;
		return fieldsArray.map((field) => {
			const objToReturn = { ...field };
			if (field?.index < index) {
				return objToReturn;
			} else {
				objToReturn.index = newIndex;
				if (field?.type === FieldType.COLUMNS) {
					field?.value
						.filter((item) => item)
						.forEach((col, key) => {
							col.index = newIndex + key + 1;
							col.columnIndex = objToReturn.index;
							newIndex = newIndex + key + 1;
						});
				}
				newIndex += 1;
				return objToReturn;
			}
		});
	};

	return {
		flatFields,
		findField,
		lastFieldIndex,
		newFieldIndex,
		updateFields,
	};
};

export default useFlatFields;
