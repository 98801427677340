import { Box, Chip, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useGetSearchResults from '../../api/hooks/brandbooks/useGetSearchResults';
import { useCallback } from 'react';
import { capitalizeFirstLetter, getImageSource } from '../../utils/globals';
import { CountBadge } from '../../styles/layouts';
import BrandLoader from '../General/BrandLoader';
import palette from '../../styles/palette';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Link } from 'react-router-dom';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import CustomAvatar from '../Cards/CustomAvatar';

const Image = ({ asset }) => {
	return (
		<Box height={40} width={40} borderRadius={'100%'} overflow={'hidden'}>
			<img
				src={getImageSource(asset)?.src}
				style={{
					objectFit: 'cover',
					width: '100%',
					height: '100%',
				}}
			/>
		</Box>
	);
};

const SearchResults = ({ query, setQuery }) => {
	const { data, isLoading } = useGetSearchResults(query);

	const highlightQuery = (text) => {
		const parts = text?.split(new RegExp(`(${query})`, 'gi'));
		if (!parts || !parts.length) return null;
		return (
			<>
				{parts.map((part, index) =>
					part.toLowerCase() === query.toLowerCase() ? (
						<Typography
							variant='span'
							key={index}
							sx={{ bgcolor: palette.info.light }}
						>
							{part}
						</Typography>
					) : (
						part
					),
				)}
			</>
		);
	};

	const renderItem = (item, type) => {
		switch (type) {
			case 'users':
				return (
					<>
						<CustomAvatar image={item?.name} />
						<Typography>
							{highlightQuery(item?.name)}
							<Typography fontSize={10}>
								{highlightQuery(item?.email)}
							</Typography>
						</Typography>
					</>
				);
			case 'brands':
				return (
					<>
						<Image asset={item?.logo} />
						<Typography>{highlightQuery(item?.name)}</Typography>
					</>
				);
			case 'brandbooks':
				return (
					<>
						<Image asset={item?.bannerAsset} />
						<Typography>
							{highlightQuery(item?.brand?.name)}
						</Typography>
					</>
				);
			case 'assets':
				return (
					<>
						<Image asset={item} />
						<Typography>{highlightQuery(item?.name)}</Typography>
					</>
				);

			case 'categories':
				return (
					<>
						<Image asset={item?.asset} />
						<Typography
							display={'flex'}
							flexDirection={'column'}
							alignItems={'start'}
						>
							{highlightQuery(item?.asset?.name)}
							<Typography variant='small' fontSize={12}>
								{highlightQuery(item?.category?.name)}
							</Typography>
						</Typography>
					</>
				);
			case 'folders':
				return (
					<>
						<FolderCopyOutlinedIcon />
						<Typography>
							{highlightQuery(
								item?.brand.name +
									'/' +
									capitalizeFirstLetter(item?.name),
							)}
						</Typography>
					</>
				);

			default:
				break;
		}
	};

	const handleRenderList = useCallback(() => {
		if (!data?.data) return;
		return data?.data.length === 0 ? (
			<Box>No results found.</Box>
		) : (
			data?.data.map((item, index) => {
				return (
					<>
						<Grid
							container
							alignItems={'center'}
							mb={2}
							gap={2}
							mt={index && 2}
						>
							<Typography>
								{capitalizeFirstLetter(item?.tag)}
							</Typography>
							<CountBadge>{item?.count}</CountBadge>
						</Grid>

						<Grid
							container
							direction={'column'}
							gap={1}
							maxHeight={240}
							overflow={'scroll'}
							wrap='nowrap'
						>
							{item.data.map((row) => {
								return (
									<Grid
										component={Link}
										to={
											item?.tag === 'assets' ||
											item?.tag === 'folders' ||
											item?.tag === 'categories'
												? '/files'
												: item?.tag
										}
										state={{
											current: row,
											tag: item?.tag,
										}}
										container
										key={row.id}
										gap={2}
										alignItems={'center'}
										borderRadius={1}
										p={1}
										sx={{
											transition: 'all 0.25s ease',
											color: 'inherit',
											textDecoration: 'none',
											svg: {
												'&:last-of-type': {
													opacity: 0,
												},
											},
											'&:hover': {
												bgcolor: palette.info.veryLight,
												svg: {
													opacity: 1,
												},
											},
										}}
										onClick={() => {
											setQuery('');
										}}
									>
										{renderItem(row, item?.tag)}
										<ArrowForwardIosOutlinedIcon
											sx={{
												color: palette.info.light,
												marginInlineStart: 'auto',
											}}
										/>
									</Grid>
								);
							})}
						</Grid>
					</>
				);
			})
		);
	}, [data]);

	return (
		<Grid container justifyContent={'center'}>
			{isLoading ? <BrandLoader /> : handleRenderList()}
		</Grid>
	);
};

Image.propTypes = {
	asset: PropTypes.object,
};
SearchResults.propTypes = {
	query: PropTypes.string,
	setQuery: PropTypes.func,
};

export default SearchResults;
