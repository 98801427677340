import { useQuery } from '@tanstack/react-query';
import { getUsers } from '../../users';

const useGetUsers = (role, excludeIds = [], enabled = true) => {
	const QUERY_KEY = ['getUsers', role, excludeIds];
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getUsers(role, excludeIds),
		enabled,
	});
};

export default useGetUsers;
