import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
const Helper = ({ helperText, inset }) => {
	return (
		<Tooltip title={helperText} placement='bottom' arrow>
			<InfoOutlinedIcon
				color='#727272'
				sx={{ position: 'absolute', zIndex: 1, inset }}
			/>
		</Tooltip>
	);
};
Helper.propTypes = {
	helperText: PropTypes.string,
	inset: PropTypes.string,
};
export default Helper;
