import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { updateAsset } from '../../files';

const useUpdateAsset = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutate: handleUpdateAsset, isPending } = useMutation({
		mutationFn: (data) => updateAsset(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleUpdateAsset, isPending };
};

export default useUpdateAsset;
