import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const DataContext = createContext({
	data: [],
	setData: () => {},
	currentItem: null,
	setCurrentItem: () => {},
	setCurrentItemById: () => {},
	isEditing: false,
	setIsEditing: () => {},
	isCreating: false,
	setIsCreating: () => {},
	filteredData: () => {},
	updateData: (action, newData = {}) => {},
	handleFilter: () => {},
	handleCreate: () => {},
});

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
	const [data, setData] = useState([]);

	const [currentItem, setCurrentItem] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [filteredData, setFilteredData] = useState([]);

	// Update function to update the data
	const updateData = (action, newData = {}) => {
		if (action === 'add') {
			setData([...data, newData]);
		} else if (action === 'edit') {
			console.log(newData);
			const updatedData = data.map((item) =>
				item.id === newData.id ? newData : item,
			);
			setData(updatedData);
		} else if (action === 'delete') {
			const updatedData = data.filter((item) => item.id !== newData.id);
			setData(updatedData);
		}
	};

	const handleFilter = (filterCriteria) => {
		const filtered = data.filter(
			(item) => item.someProperty === filterCriteria,
		);
		setFilteredData(filtered);
	};

	const setCurrentItemById = (itemId) => {
		const item = data.find((item) => item.id === itemId);
		setCurrentItem(item);
	};
	const resetContext = () => {
		setData([]);
		setCurrentItem(null);
		setIsEditing(false);
		setIsCreating(false);
		setFilteredData([]);
	};
	const handleCreate = (newItem) => {
		setIsEditing(false);
		setIsCreating(true);
		setCurrentItem(newItem);
	};
	// Data context value
	const contextValue = {
		data,
		setData,
		isEditing,
		setIsEditing,
		isCreating,
		setIsCreating,
		filteredData,
		updateData,
		handleFilter,
		currentItem,
		setCurrentItem,
		setCurrentItemById,
		resetContext,
		handleCreate,
	};

	return (
		<DataContext.Provider value={contextValue}>
			{children}
		</DataContext.Provider>
	);
};

DataProvider.propTypes = {
	children: PropTypes.any.isRequired,
};
