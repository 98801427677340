import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useFileContext } from '../../contexts/FilesContext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { capitalizeFirstLetter } from '../../utils/globals';
import { useEffect } from 'react';

const BreadcrumbsFolders = () => {
	const {
		breadcrumbs,
		setBreadcrumbs,
		setCurrentFolder,
		folders,
		navigateToFolder,
	} = useFileContext();

	const handleBreadcrumbClick = (crumbId) => {
		const isBrand = folders.find((folder) => folder?.brand?.id === crumbId);
		isBrand ? setCurrentFolder(null) : navigateToFolder(crumbId);
	};

	return (
		<Breadcrumbs
			aria-label='breadcrumb'
			separator={<NavigateNextIcon fontSize='small' />}
		>
			{breadcrumbs?.length &&
				breadcrumbs.map((breadcrumb, index) =>
					index === breadcrumbs?.length - 1 ? (
						<Typography key={breadcrumb?.id}>
							{capitalizeFirstLetter(breadcrumb?.name)}
						</Typography>
					) : (
						<Link
							key={breadcrumb.id}
							color='inherit'
							onClick={() =>
								handleBreadcrumbClick(breadcrumb?.id)
							}
						>
							{capitalizeFirstLetter(breadcrumb?.name)}
						</Link>
					),
				)}
		</Breadcrumbs>
	);
};

export default BreadcrumbsFolders;
