import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { CustomAdminDrawer } from '../../styles/layouts';
import palette from '../../styles/palette';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import GeneralButton from '../Inputs/GeneralButton';
import PropTypes from 'prop-types';
import { useIsMutating } from '@tanstack/react-query';

const DrawerLayout = ({
	isOpen,
	handleCloseDrawer,
	title,
	children,
	handleSave,
	saveText,
}) => {
	const isMutating = useIsMutating();
	return (
		<CustomAdminDrawer
			anchor={'right'}
			open={isOpen}
			onClose={handleCloseDrawer}
		>
			<Grid
				container
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<Typography variant='h6' component='h6' flex={1}>
					{title}
				</Typography>
				<IconButton
					aria-label='delete'
					sx={{
						border: `1px solid ${palette.background.grey}`,
						borderRadius: '100%',
						p: 1,
					}}
					onClick={handleCloseDrawer}
				>
					<ClearOutlinedIcon />
				</IconButton>
			</Grid>

			{children}
			<Grid
				container
				sx={{ border: 'unset !important', mt: 'auto !important' }}
				gap={2}
				justifyContent={'end'}
			>
				<GeneralButton
					type={'button'}
					onClick={handleCloseDrawer}
					sx={{ background: palette.background.grey }}
				>
					Cancel
				</GeneralButton>
				{/* add condition that if no changes were nmade not able to click send */}
				<GeneralButton type={'button'} onClick={handleSave}>
					{isMutating ? (
						<CircularProgress color='secondary' size={24} />
					) : (
						saveText || 'Save'
					)}
				</GeneralButton>
			</Grid>
		</CustomAdminDrawer>
	);
};

DrawerLayout.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleCloseDrawer: PropTypes.func.isRequired,
	title: PropTypes.oneOf(PropTypes.string, PropTypes.element),
	children: PropTypes.oneOf(PropTypes.array, PropTypes.element),
	handleSave: PropTypes.func,
	saveText: PropTypes.string,
};
export default DrawerLayout;
