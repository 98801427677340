import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';

const ColorValues = ({ values }) => {
	const [isCopied, setIsCopied] = useState('');
	function createData(name, value) {
		return { name, value };
	}

	const rows = [...values.map((data) => createData(data.name, data.value))];

	return (
		<Table>
			<TableBody>
				{rows.map((row) => (
					<TableRow key={row?.name}>
						<TableCell
							component='th'
							scope='row'
							sx={{ padding: '10px' }}
						>
							{row?.name}
						</TableCell>
						<TableCell align='right' sx={{ padding: '10px' }}>
							<Grid
								container
								alignItems={'center'}
								justifyContent={'end'}
								gap={3}
							>
								<Typography>{row?.value}</Typography>
								{isCopied !== row?.name ? (
									<ContentCopyIcon
										sx={{ cursor: 'pointer' }}
										onClick={() => {
											navigator.clipboard.writeText(
												row?.value,
											);
											setIsCopied(row?.name);
											setTimeout(() => {
												setIsCopied('');
											}, 2000);
										}}
									/>
								) : (
									<Typography
										variant='small'
										color={'primary'}
									>
										Copied!
									</Typography>
								)}
							</Grid>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

ColorValues.propTypes = {
	values: PropTypes.array,
};

export default ColorValues;
