import {
	Box,
	Button,
	Chip,
	Collapse,
	Grid,
	Link,
	Step,
	StepButton,
	Stepper,
	Tab,
	Tabs,
	Typography,
	lighten,
} from '@mui/material';
import { useFileContext } from '../../contexts/FilesContext';
import { useEffect, useRef, useState } from 'react';
import ContentEditable from '../Inputs/ContentEditable';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import palette from '../../styles/palette';
import { TransitionGroup } from 'react-transition-group';
import CategoryDialog from '../Dialogs/CategoryDialog';
import CloseIcon from '@mui/icons-material/Close';
import useGetCategories from '../../api/hooks/files/useGetCategories';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import getCroppedImg, { readFileAsDataURL } from '../../utils/cropImage';
import { getImageSource, isImageFile } from '../../utils/globals';
import ReactQuill from 'react-quill';
import CropperDialog from '../Dialogs/CropperDialog';
import GeneralButton from '../Inputs/GeneralButton';
import { getCurrentThumbnailObj } from '../../utils/fields';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';

const steps = ['Details', 'Thumbnail', 'Tags', 'Files'];
// const steps = ['Details', 'Tags', 'Files'];

const toolbarOptions = [
	['bold', 'italic', 'underline'],
	['blockquote'],
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ header: [2, 3, false] }],
	[{ align: [] }],
];

const DrawerStepper = ({ excludeStep }) => {
	const { data: categories } = useGetCategories();
	const { setCurrentFile, currentFile, isEditing, isCreating, files } =
		useFileContext();
	const { handleAlert } = useAlert();
	const [activeStep, setActiveStep] = useState(0);
	const [allCategories, setAllCategories] = useState([]);
	const [selected, setSelected] = useState(
		new Set(
			currentFile?.CategoriesOnAssets?.length > 0
				? currentFile?.CategoriesOnAssets.map((cat) => cat?.categoryId)
				: [],
		),
	);
	const [isDialogOpen, setIsDialogOpen] = useState(null);
	const [relatedFiles, setRelatedFiles] = useState(
		currentFile?.relatedFiles || [],
	);
	const [thumbnail, setThumbnail] = useState({
		file: null,
		crop: {},
	});
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	const [isCropping, setIsCropping] = useState(false);

	const thumbRef = useRef(null);

	const isFileImage =
		(Object.hasOwn(currentFile, 'asset') &&
			currentFile?.asset?.type?.includes('image')) ||
		(currentFile?.mimetype && currentFile?.mimetype?.includes('image'));

	const onDrop = (acceptedFiles) => {
		const newFiles = [
			...relatedFiles,
			...acceptedFiles.map((file, index) => ({ ...file, index })),
		];
		setRelatedFiles((prev) => [...prev, ...acceptedFiles]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	const heightLimit = isEditing
		? {
				maxHeight: isEditing ? 200 : 500,
				height: '100%',
				overflow: isEditing ? 'scroll' : 'hidden',
		  }
		: {};

	function handleSelectionChanged(id) {
		const newSet = new Set(selected);
		if (newSet.has(id)) newSet.delete(id);
		else newSet.add(id);
		setSelected(newSet);
	}

	const handleCroppedImage = async () => {
		try {
			const urlFromFile = thumbnail?.file.externalLink
				? getImageSource(thumbnail?.file)?.src
				: thumbnail?.asset;

			const croppedImage = await getCroppedImg(
				urlFromFile,
				thumbnail?.crop,
			);
			setCroppedImageUrl(croppedImage);
		} catch (error) {
			handleAlert(
				ALERT_STATUS.ERROR,
				'There was an error cropping this image.',
			);
		}
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	useEffect(() => {
		if (categories !== null) setAllCategories(categories?.data);
	}, [categories]);

	useEffect(() => {
		setCurrentFile((prev) => ({
			...prev,
			CategoriesOnAssets: [...selected],
		}));
	}, [selected]);

	useEffect(() => {
		if (!thumbnail?.file || !thumbnail?.crop?.width) return;

		handleCroppedImage();
	}, [thumbnail]);

	useEffect(() => {
		if (
			currentFile?.asset &&
			!isImageFile(currentFile?.asset) &&
			!thumbnail.file
		) {
			setActiveStep(1);
		}
		if (
			currentFile?.asset &&
			(isImageFile(currentFile?.asset) ||
				currentFile?.asset?.localPath ||
				currentFile?.asset?.externalLink)
		) {
			return setThumbnail((prev) => ({
				...prev,
				file: currentFile?.asset,
			}));
		}
		if (isEditing && !currentFile?.asset) {
			const image = getCurrentThumbnailObj(currentFile);
			return setThumbnail((prev) => ({ crop: image.crop, file: image }));
		}
	}, [currentFile]);

	useEffect(() => {
		setCurrentFile((prev) => ({
			...prev,
			relatedFiles: [...relatedFiles],
		}));
	}, [relatedFiles]);

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={activeStep}
					onChange={(event, newValue) => {
						setActiveStep(newValue);
					}}
					aria-label='basic tabs example'
				>
					{steps.map((label, index) => (
						<Tab key={label} label={label} {...a11yProps(index)} />
					))}
				</Tabs>
			</Box>
			{activeStep === 0 && (
				<Box pr={3} display={'flex'} flexDirection={'column'} gap={2}>
					<ContentEditable
						label='Name'
						type={'text'}
						value={currentFile?.name || currentFile?.asset?.name}
						canEdit={true}
						setValue={({ target: { value } }) => {
							setCurrentFile((prev) => ({
								...prev,
								name: value,
							}));
						}}
					/>

					<Typography fontWeight={500} mt={1}>
						Excerpt
					</Typography>
					<ReactQuill
						style={{ marginBottom: 30, minHeight: '15vh' }}
						theme='snow'
						value={currentFile?.excerpt}
						onChange={(value) => {
							setCurrentFile((prev) => ({
								...prev,
								excerpt: value,
							}));
						}}
						modules={{
							toolbar: toolbarOptions,
						}}
					/>
					<Typography fontWeight={500} mt={7}>
						Description
					</Typography>
					<ReactQuill
						style={{ minHeight: '15vh' }}
						theme='snow'
						value={currentFile?.description}
						onChange={(value) => {
							setCurrentFile((prev) => ({
								...prev,
								description: value,
							}));
						}}
						modules={{
							toolbar: toolbarOptions,
						}}
					/>
				</Box>
			)}
			{/* THUMBNAIL */}
			{activeStep === 1 && (
				<Grid container direction={'column'} gap={1} mt={4}>
					<Grid
						container
						direction={'column'}
						gap={1}
						mb={2}
						overflow={'hidden'}
						height={230}
						width={370}
						borderRadius={4}
						borderBottom={'unset !important'}
						bgcolor={palette.background.brandBook}
						mx={'auto'}
						paddingBottom={'0 !important'}
					>
						{croppedImageUrl && (
							<img
								src={croppedImageUrl}
								alt=''
								style={{
									objectFit: 'cover',
									height: '100%',
									width: '100%',
								}}
							/>
						)}
					</Grid>

					<Grid container gap={2}>
						{isFileImage && (
							<GeneralButton
								variant={'outlined'}
								onClick={() => {
									if (Object.hasOwn(currentFile, 'asset')) {
										const reader = new FileReader();
										reader.onload = () => {
											setThumbnail((prev) => ({
												...prev,
												file: currentFile?.asset,
												asset: reader.result,
											}));
											setIsCropping(true);
										};
										reader.readAsDataURL(
											currentFile?.asset,
										);
									} else {
										setThumbnail((prev) => ({
											...prev,
											asset: getImageSource(currentFile)
												?.src,
										}));
										setIsCropping(true);
									}
								}}
							>
								Crop Image
							</GeneralButton>
						)}

						<input
							ref={thumbRef}
							type='file'
							style={{ display: 'none' }}
							onChange={({ target: { files } }) => {
								const reader = new FileReader();
								reader.onload = () => {
									setThumbnail((prev) => ({
										...prev,
										file: files[0],
										asset: reader.result,
									}));
									setIsCropping(true);
								};
								reader.readAsDataURL(files[0]);
							}}
							id={'thumbnail'}
							name={'thumbnail'}
						/>
						<label htmlFor={'thumbnail'}>
							<Button variant='simple' component='span'>
								{isFileImage
									? 'Use different Image'
									: 'Upload Thumbnail'}
							</Button>
						</label>
					</Grid>
				</Grid>
			)}
			{activeStep === 2 && (
				<>
					<Grid
						container
						gap={1}
						sx={{ overflow: 'scroll' }}
						mt={2}
						alignItems={'center'}
					>
						{allCategories.length > 0 &&
							allCategories.map((c) => (
								<Chip
									icon={
										selected.has(c.id) ? (
											<CheckCircleOutlinedIcon
												sx={{
													fill: palette.text.link,
												}}
											/>
										) : (
											<CircleOutlinedIcon
												sx={{
													fill: palette.background
														.grey,
												}}
											/>
										)
									}
									key={c.id}
									onClick={() => handleSelectionChanged(c.id)}
									sx={{ width: 'max-content' }}
									variant='outlined'
									label={c?.name}
								/>
							))}
						<Link
							component={'p'}
							onClick={() => {
								setIsDialogOpen(true);
							}}
						>
							+ Add New Tag
						</Link>
					</Grid>
				</>
			)}
			{activeStep === 3 && (
				<Grid container direction={'column'} gap={1} mt={4}>
					<Box
						{...getRootProps()}
						sx={{
							padding: '2vmin 4vmin',
							border: `2px dashed ${palette.background.grey}`,
							background: lighten(palette.background.grey, 0.7),
							color: palette.text.primary,
							textAlign: 'center',
						}}
					>
						<input {...getInputProps()} />
						{isDragActive ? (
							<p>Drop the files here ...</p>
						) : (
							<p>
								Drag and drop some files here, or click to
								select files
							</p>
						)}
					</Box>

					<TransitionGroup>
						{[...relatedFiles].map((row, index) => (
							<Collapse key={row.name}>
								<Grid
									container
									justifyContent={'space-between'}
									gap={1}
									wrap='nowrap'
									sx={{
										padding: '6px',
										borderBottom: '1px solid #efefef',
									}}
								>
									<InsertDriveFileIcon />
									<Typography mr={'auto'}>
										{row.name}
									</Typography>
									<CloseIcon
										onClick={() => {
											setRelatedFiles((prev) =>
												prev.filter(
													(item, key) =>
														key !== index,
												),
											);
										}}
										sx={{
											color: palette.warning.light,
											cursor: 'pointer',
											alignSelf: 'center',
										}}
									/>
								</Grid>
							</Collapse>
						))}
					</TransitionGroup>
				</Grid>
			)}

			{isDialogOpen && (
				<CategoryDialog
					isOpen={isDialogOpen}
					setIsOpen={setIsDialogOpen}
					setAllChips={setAllCategories}
				/>
			)}

			{isCropping && (
				<CropperDialog
					isOpen={isCropping}
					setIsOpen={setIsCropping}
					thumbnail={thumbnail}
					setThumbnail={setThumbnail}
				/>
			)}
		</>
	);
};

DrawerStepper.propTypes = {
	excludeStep: PropTypes.number,
};

export default DrawerStepper;
