import { useQuery } from '@tanstack/react-query';

import { getDashboardData } from '../../brandbooks';

const useGetDashboardData = (enabled = true) => {
	const QUERY_KEY = ['dashboardData'];
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getDashboardData(),
		enabled,
	});
};

export default useGetDashboardData;
