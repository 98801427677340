import { Box, Container } from '@mui/material';
import PropTypes from 'prop-types';
import { getImageSource } from '../../utils/globals';

const styles = {
	minHeight: '93.5vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundSize: 'cover',
	backgroundColor: 'background.default',
};

const CustomBg = ({ bgImage, children }) => {
	return (
		<Box
			sx={{
				...styles,
				backgroundImage: `url('${getImageSource(bgImage)?.src.replace(
					/\\/g,
					'/',
				)}')`,
			}}
		>
			<Container maxWidth='sm'>{children}</Container>
		</Box>
	);
};

CustomBg.propTypes = {
	children: PropTypes.any,
	bgImage: PropTypes.string,
};

export default CustomBg;
