import { fetchGet, fetchPost, fetchPut, GLOBAL_URL } from './global';

export const createUser = async (data) => {
	return await fetchPost(
		`${GLOBAL_URL}users/createUser`,
		data,
		'multipart/form-data',
	);
};
export const getUsers = async (role, excludeIds) => {
	return await fetchGet(
		`${GLOBAL_URL}users/getUsers?role=${role}&excludeIds=${excludeIds.join(
			',',
		)}`,
	);
};
export const updateUser = async (data) => {
	return await fetchPut(
		`${GLOBAL_URL}users/updateUser`,
		data,
		'multipart/form-data',
	);
};
export const userLogin = async (data) => {
	return await fetchPost(`${GLOBAL_URL}users/userLogin`, data);
};
export const userLogout = async (data) => {
	return await fetchGet(`${GLOBAL_URL}users/userLogout`);
};
export const verifyUser = async () => {
	return await fetchGet(`${GLOBAL_URL}users/verifyUser`);
};
export const verifyAuthCode = async (data) => {
	return await fetchPut(`${GLOBAL_URL}users/verifyAuthCode`, data);
};
export const resendCode = async (data) => {
	return await fetchPost(`${GLOBAL_URL}users/resendCode`, data);
};
export const checkTimeRemaining = async (email) => {
	return await fetchGet(
		`${GLOBAL_URL}users/checkTimeRemaining?email=${email}`,
	);
};
export const sendHelpEmail = async (data) => {
	return await fetchPost(`${GLOBAL_URL}users/sendHelpEmail`, data);
};
export const updatePassword = async (data) => {
	return await fetchPut(`${GLOBAL_URL}users/updatePassword`, data);
};
export const resetPassword = async (data) => {
	return await fetchPost(`${GLOBAL_URL}users/resetPassword`, data);
};

export const getBrandByClient = async (clientId) => {
	return await fetchGet(
		`${GLOBAL_URL}brands/getBrandByClient?clientId=${clientId}`,
	);
};
