import { useEffect } from "react";
import { useDataContext } from "../../contexts/DataContext";
import useGetUsers from "../../api/hooks/users/useGetUsers";
import { USER_ROLE } from "../../utils/enums";
import { useUser } from "../../contexts/UserContext";
import ManagementLayout from "../../components/Layouts/ManagementLayout";
import { Grid } from "@mui/material";
import BrandLoader from "../../components/General/BrandLoader";

const Users = () => {
  const { setData: setUsers } = useDataContext();
  const { user } = useUser();
  const {
    data: usersRes,
    isError,
    error,
    isLoading,
  } = useGetUsers(USER_ROLE.CLIENT, [user.id]);

  useEffect(() => {
    if (usersRes) return setUsers(usersRes?.data);
    if (error) return; // redirect to error page?? / display error

    // fetchUsers();
  }, [usersRes, error]);

  if (isLoading) {
    return (
      <Grid
        alignItems={"center"}
        justifyContent={"center"}
        container
        sx={{ height: "100%", width: "100%" }}
      >
        <BrandLoader />
      </Grid>
    );
  }
  return <ManagementLayout userType={USER_ROLE.CLIENT} />;
};

export default Users;
