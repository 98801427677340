import axios from 'axios';

// const env = 'DEVELOPMENT';
const env = 'PRODUCTION';
export const CLIENT_DOMAIN =
	env === 'DEVELOPMENT'
		? 'http://localhost:3000/'
		: 'https://brandboss.site/';
export const GLOBAL_URL =
	env === 'DEVELOPMENT'
		? 'http://localhost:3001/'
		: 'https://app.brandboss.site/';

//

export const fetchGet = async (url) => {
	const { data } = await axios.get(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
	});

	return data;
};

export const fetchPost = async (
	url,
	obj,
	content_type = 'application/json',
) => {
	const { data } = await axios.post(url, obj, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			'Access-Control-Allow-Origin': GLOBAL_URL,
		},
	});
	return data;
};

export const fetchPostFiles = async (url, obj, onUploadProgress) => {
	const { data } = await axios.post(url, obj, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': GLOBAL_URL,
		},
		onUploadProgress,
	});
	return data;
};

export const fetchPut = async (url, obj, content_type = 'application/json') => {
	const { data } = await axios.put(url, obj, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			'Access-Control-Allow-Origin': GLOBAL_URL,
		},
	});
	return data;
};

export const fetchDelete = async (
	url,
	obj,
	content_type = 'application/json',
) => {
	const { data } = await axios.delete(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			'Access-Control-Allow-Origin': GLOBAL_URL,
		},
		data: obj,
	});
	return data;
};
