import { useCallback, useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { formatSpacingValues } from '../../utils/fields';
import placeholderImage from '../../assets/images/placeholder.jpg';
import getCroppedImg, { handleCroppedImage } from '../../utils/cropImage';
import { getImageSource } from '../../utils/globals';
import { useAlert } from '../../contexts/AlertContext';

const ImageDisplay = ({ field }) => {
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);

	const [loading, setLoading] = useState(true);

	const { handleAlert } = useAlert();

	const handleGetCroppedImage = useCallback(async () => {
		setCroppedImageUrl(null);
		if (field?.asset?.localPath || field?.asset?.externalLink) {
			const croppedImage = await handleCroppedImage(
				field,
				handleAlert,
				setLoading,
			);

			if (!croppedImage) return;
			setCroppedImageUrl(croppedImage);
		}
	}, [field?.asset]);

	useEffect(() => {
		field && handleGetCroppedImage();
	}, [field]);

	return (
		<Box
			sx={{
				position: 'relative',
				zIndex: 1,
				height:
					field?.imageHeight === null
						? 'auto'
						: `${Math.round(field?.imageHeight) + '%'}`,
				width: `${Math.round(field?.imageWidth) + '%'}`,
				borderRadius: formatSpacingValues(field?.borderRadius || []),
				padding: formatSpacingValues(field?.padding || []),
				margin: formatSpacingValues(field?.margin || []),
				overflow: 'hidden',
			}}
		>
			{loading ? (
				<Skeleton
					variant='rectangular'
					width={'100%'}
					sx={{ aspectRatio: 1, height: 'unset' }}
				/>
			) : (
				<img
					src={
						field?.asset
							? field?.asset?.mimetype &&
							  field?.asset?.mimetype?.includes('gif')
								? getImageSource(field?.asset)?.src
								: croppedImageUrl
							: placeholderImage
					}
					alt={field?.asset?.name || 'placeholder'}
					style={{
						width: '100%',
						objectFit: 'cover',
						height: 'auto',
					}}
				/>
			)}
		</Box>
	);
};

ImageDisplay.propTypes = {
	field: PropTypes.object,
};

export default ImageDisplay;
