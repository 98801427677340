import { Typography } from '@mui/material';
import CustomInput from '../Inputs/CustomInput';
import GeneralButton from '../Inputs/GeneralButton';
import { useEffect, useState } from 'react';
import useForgotPassword from '../../api/hooks/users/useForgotPassword';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import useUpdatePassword from '../../api/hooks/users/useUpdatePassword';
import { FORM_STEPS, useLogin } from '../../contexts/LoginContext';

const StepForgotPass = () => {
	const { handleAlert } = useAlert();
	const { setCurrentStep, isEmailValid, setIsEmailValid, email, setEmail } =
		useLogin();

	const [code, setCode] = useState(null);
	const [newPass, setNewPass] = useState({ origin: '', copy: '' });

	const { handleForgotPassword } = useForgotPassword((data) => {
		if (data.data.success) {
			setIsEmailValid(true);
		}
	});

	const { updateNewPassword } = useUpdatePassword(() => {
		setCurrentStep(FORM_STEPS.LOGIN);
		setIsEmailValid(false);

		handleAlert(
			ALERT_STATUS.SUCCESS,
			'Password updated! Please login with new password.',
		);
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!email)
			return handleAlert(
				ALERT_STATUS.ERROR,
				'Please Enter Valid Email Address.',
			);

		if (!isEmailValid) return handleForgotPassword({ email });
		if (!code)
			return handleAlert(
				ALERT_STATUS.ERROR,
				'Please enter verification code.',
			);
		if (!newPass.origin || !newPass.copy)
			return handleAlert(ALERT_STATUS.ERROR, 'Please Enter Password');
		if (newPass.origin !== newPass.copy)
			return handleAlert(
				ALERT_STATUS.ERROR,
				"Passwords values don't match",
			);
		return updateNewPassword({ email, newPass: newPass.origin, code });
	};

	return (
		<>
			<Typography variant='h1'>Reset Password</Typography>
			<Typography
				variant='p'
				mb={'auto !important'}
				align='center'
				fontWeight={500}
			>
				{isEmailValid
					? `A verification code has been sent to ${email}.`
					: 'Please provide the email address that was used to create your account.'}
			</Typography>
			<form onSubmit={handleSubmit}>
				{isEmailValid ? (
					<>
						<CustomInput
							inputType='text'
							label='Reset Code'
							value={code}
							action={({ target: { value } }) => {
								setCode(value);
							}}
						/>
						<CustomInput
							label='New Password'
							inputType='password'
							mt={2}
							name='password'
							value={newPass.origin}
							action={({ target: { value } }) => {
								setNewPass((prev) => ({
									...prev,
									origin: value,
								}));
							}}
						/>
						<CustomInput
							label='Confirm Password'
							inputType='password'
							mt={2}
							name='passwordCopy'
							value={newPass.copy}
							action={({ target: { value } }) => {
								setNewPass((prev) => ({
									...prev,
									copy: value,
								}));
							}}
						/>
					</>
				) : (
					<CustomInput
						label='Your Email'
						inputType='email'
						value={email}
						action={({ target: { value } }) => {
							setEmail(value);
						}}
					/>
				)}

				<GeneralButton mt={'24px'} type='submit'>
					{isEmailValid ? 'Reset' : 'Continue'}
				</GeneralButton>
			</form>
			{!isEmailValid && (
				<Typography
					variant='p'
					align='center'
					fontWeight={500}
					mt={'auto !important'}
				>
					We will send you an email with a verification code that will
					allow you to reset your password.
				</Typography>
			)}
		</>
	);
};

export default StepForgotPass;
