import { useQuery } from '@tanstack/react-query';
import { getDropboxStatus } from '../../general';

const useGetDropboxStatus = (enabled = true) => {
	const QUERY_KEY = ['dbxStatus'];
	return useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getDropboxStatus(),
		enabled,
	});
};

export default useGetDropboxStatus;
