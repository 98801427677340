import { Button, Grid, IconButton, Snackbar } from '@mui/material';
import useGetAllBrandBooks from '../../api/hooks/brandbooks/useGetAllBrandBooks';
import { useEffect, useState } from 'react';

import { BRANDBOOK_STATUS } from '../../utils/enums';
import useDeleteBrandbook from '../../api/hooks/brandbooks/useDeleteBrandbook';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import palette from '../../styles/palette';
import BrandBookCard from '../Cards/BrandBookCard';
import BrandLoader from '../General/BrandLoader';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';

const BrandBooksDisplay = () => {
	const { brandBooks, isLoading, setBrandBooks } = useBrandbooksContext();
	const [pendingDelete, setPendingDelete] = useState(null);
	const PENDING_DURATION = 1000 * 30;

	const { handleDeleteBrandBook } = useDeleteBrandbook((data) => {
		setBrandBooks((prev) =>
			prev.filter((brandbook) => brandbook?.id !== data?.data?.id),
		);
		setPendingDelete(null);
	});

	if (isLoading)
		return (
			<Grid
				container
				justifyContent={'center'}
				alignItems={'center'}
				height={'50vh'}
			>
				<BrandLoader />
			</Grid>
		);

	return (
		<>
			<Grid container gap={3}>
				{brandBooks
					?.filter(
						(book) => book.status !== BRANDBOOK_STATUS.TEMPLATE,
					)
					?.map((brandbook) => (
						<Grid item xs={2} key={brandbook.id}>
							<BrandBookCard
								brandbook={brandbook}
								setPendingDelete={setPendingDelete}
							/>
						</Grid>
					))}
			</Grid>
			<Snackbar
				open={Boolean(pendingDelete)}
				autoHideDuration={PENDING_DURATION}
				onClose={() => {
					if (pendingDelete) {
						handleDeleteBrandBook({
							id: pendingDelete,
						});
					}
				}}
				message='You are deleting a brandbook. This action is irreversible'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				action={
					<>
						<Button
							size='small'
							color='primary'
							onClick={() => {
								setPendingDelete(null);
							}}
						>
							Undo
						</Button>
						<IconButton
							onClick={() => {
								handleDeleteBrandBook({
									id: pendingDelete,
								});
							}}
						>
							<CloseOutlinedIcon color='text.dark' />
						</IconButton>
					</>
				}
				sx={{
					'& .MuiPaper-root': {
						background: palette.warning.main,
						boxShadow: 'unset',
						color: '#fff',
					},
				}}
			/>
		</>
	);
};

export default BrandBooksDisplay;
