import PropTypes from 'prop-types';
import useGetFoldersByBrand from '../../api/hooks/files/useGetFoldersByBrand';
import { Grid, Typography } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import BrandLoader from '../General/BrandLoader';
import AssetsStrip from '../Layouts/AssetsStrip';

const SearchResultsBrandbook = ({
	brandId,
	searchTerm,
	dir,
	setCurrentAsset,
	setSearchParams,
}) => {
	const { data: folders } = useGetFoldersByBrand(brandId, searchTerm);

	const isLoading = useIsFetching({
		queryKey: ['getFolders', brandId, searchTerm],
		fetchStatus: 'fetching',
	});

	if (isLoading)
		return (
			<Grid container direction={'column'} gap={2} alignItems={'center'}>
				<BrandLoader />
			</Grid>
		);
	return (
		<Grid container direction={'column'} gap={2}>
			<Typography variant='h6'>Search results</Typography>
			{folders?.data.length === 0 ? (
				<Typography variant='p'>
					No results matching &apos;{searchTerm}&apos; were found.
					<br />
					Please try again.
				</Typography>
			) : (
				<Grid>
					{folders?.data.map((folder) => {
						return (
							<AssetsStrip
								key={folder?.id}
								setCurrentAsset={setCurrentAsset}
								lang={dir}
								folder={folder}
								filterFolder={''}
								setSearchParams={setSearchParams}
							/>
						);
					})}
				</Grid>
			)}
		</Grid>
	);
};

SearchResultsBrandbook.propTypes = {
	brandId: PropTypes.string,
	searchTerm: PropTypes.string,
	dir: PropTypes.string,
	setCurrentAsset: PropTypes.func,
	setSearchParams: PropTypes.func,
};

export default SearchResultsBrandbook;
