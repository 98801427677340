import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';

import { generalBox } from '../styles/layouts';
import { useUser } from '../contexts/UserContext';
import useGetDashboardData from '../api/hooks/general/useGetDashboardData';
import { useCallback } from 'react';
import { capitalizeFirstLetter, getImageSource } from '../utils/globals';
import CustomAvatar from '../components/Cards/CustomAvatar';
import { BRANDBOOK_STATUS } from '../utils/enums';
import palette from '../styles/palette';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ActionBtn from '../components/Cards/ActionBtn';
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
	const navigate = useNavigate();
	const { user } = useUser();

	const { data } = useGetDashboardData();

	const tableData = useCallback(() => {
		if (!data) return [];
		function createData(icon, brandName, lastUpdated, status, id) {
			return { icon, brandName, lastUpdated, status, id };
		}
		const rows = data?.data?.latestBrandbooks.map((item) =>
			createData(
				getImageSource(item?.brand?.logo)?.src,
				item?.brand?.name,
				item?.updatedAt,
				item?.status,
				item?.brand?.id,
			),
		);

		return rows;
	}, [data]);

	return (
		<Grid container direction={'column'} mt={6} gap={5}>
			<Typography variant='h6'>Welcome {user?.name} 😃</Typography>
			<Grid container gap={4} maxWidth={900}>
				<Grid item xs={12} container gap={5}>
					<Grid container gap={4}>
						<Grid
							item
							xs={3.6}
							sx={{
								...generalBox,
								aspectRatio: 1.5,
								textDecoration: 'none',
								color: 'inherit',
								'&:hover': {
									color: 'primary.main',
								},
							}}
							container
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							component={Link}
							to={'/brandbooks'}
						>
							<Typography variant='h4'>
								{data?.data?.totalBrandbooks}
							</Typography>
							<Typography variant='h6'>Brand Books</Typography>
						</Grid>
						<Grid
							item
							xs={3.6}
							sx={{
								...generalBox,
								textDecoration: 'none',
								color: 'inherit',
								'&:hover': {
									color: 'primary.main',
								},
							}}
							container
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							component={Link}
							to={'/files'}
						>
							<Typography variant='h4'>
								{data?.data?.totalAssets}
							</Typography>
							<Typography variant='h6'>Files</Typography>
						</Grid>
						<Grid
							item
							xs={3.6}
							sx={{
								...generalBox,
								textDecoration: 'none',
								color: 'inherit',
								'&:hover': {
									color: 'primary.main',
								},
							}}
							container
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							component={Link}
							to={'/brands'}
						>
							<Typography variant='h4'>
								{data?.data?.totalBrands}
							</Typography>
							<Typography variant='h6'>Brands</Typography>
						</Grid>
					</Grid>
					<Grid container gap={4}>
						<Grid
							item
							xs={11.5}
							sx={{ ...generalBox, padding: 3 }}
							width={'100%'}
							height={'fit-content'}
							container
						>
							<Grid
								container
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<Typography variant='h6'>
									Brand books history
								</Typography>

								<ActionBtn
									text='See all'
									action={() => {
										//ADD ACTION
										navigate('/brandbooks');
									}}
									sx={{
										color: palette.text.link,
										cursor: 'pointer',
									}}
								/>
							</Grid>
							<Table mt={3}>
								<TableBody>
									{tableData().map((row) => (
										<TableRow
											key={row.brandName}
											sx={{
												'td, th': {
													border: 0,
												},
											}}
										>
											<TableCell width={40}>
												<CustomAvatar
													image={row.icon}
												></CustomAvatar>
											</TableCell>
											<TableCell>
												<Typography
													fontWeight={500}
													component={Link}
													to={'/brandbooks'}
													state={{
														brandId: row?.id,
													}}
												>
													{row.brandName}
												</Typography>
											</TableCell>
											<TableCell align='center'>
												{new Date(
													row.lastUpdated,
												).toLocaleString('he-IL')}
											</TableCell>
											<TableCell align='center'>
												<Typography
													py={0.5}
													px={1.5}
													sx={{
														background:
															row.status ===
															BRANDBOOK_STATUS.PUBLISH
																? palette
																		.success
																		.light
																: palette.info
																		.veryLight,
													}}
													width={'fit-content'}
													borderRadius={2}
												>
													{capitalizeFirstLetter(
														row.status,
													)}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Dashboard;
