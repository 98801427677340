import { useLottie } from "lottie-react";
import loader from "../../assets/loader.json";
import { Box } from "@mui/material";

const BrandLoader = () => {
  const options = {
    animationData: loader,
    loop: true,
  };

  const { View } = useLottie(options);
  return <Box sx={{ maxWidth: "100px" }}>{View}</Box>;
};

export default BrandLoader;
