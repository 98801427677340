import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { HexColorInput } from 'react-colorful';
import ContentEditable from './ContentEditable';
import { useFileContext } from '../../contexts/FilesContext';
import palette from '../../styles/palette';
import { colorConvert } from '../../utils/calculations';
import ColorPickerMenu from '../Fields/ColorPickerMenu';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';

export const COLOR_MODE = {
	RGB: 'RGB',
	CMYK: 'CMYK',
	HEX: 'HEX',
};

const ColorPickerCustom = () => {
	const { setCurrentFile, currentFile, isEditing } = useFileContext();
	const [color, setColor] = useState(
		currentFile?.localPath
			? colorConvert('#' + currentFile?.localPath, 'hex', 'rgb')
			: { r: 255, g: 255, b: 255 },
	);
	const [pantoneColor, setPantoneColor] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [customContent, setCustomContent] = useState({});
	const [allowEdit, setAllowEdit] = useState(false);
	const [mode, setMode] = useState(COLOR_MODE.HEX);

	const createData = (name, value) => {
		return { name, value };
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const rows = currentFile?.createdAt
		? [...JSON.parse(currentFile?.description)]
		: [
				createData('RGB', Object.values(color).join(' ')),
				createData(
					'CMYK',
					Object.values(colorConvert(color, 'rgb', 'cmyk')).join(' '),
				),
				createData('HEX#', colorConvert(color, 'rgb', 'hex')),
		  ];

	useEffect(() => {
		setCurrentFile((prev) => ({
			...prev,
			description: JSON.stringify(rows),
			localPath: colorConvert(color, 'rgb', 'hex'),
		}));
		setPantoneColor('');
		setCustomContent({});
	}, [color]);

	useEffect(() => {
		if (Object.keys(customContent).length !== 0) {
			let newDescription = [];
			for (const colorUnit in customContent) {
				if (Object.hasOwnProperty.call(customContent, colorUnit)) {
					const value = customContent[colorUnit];
					newDescription = rows.map((row) =>
						row?.name === colorUnit ? { ...row, value } : row,
					);
				}
			}
			if (newDescription.length > 0)
				setCurrentFile((prev) => ({
					...prev,
					description: JSON.stringify(newDescription),
				}));
		}
	}, [customContent]);

	useEffect(() => {
		if (pantoneColor) {
			const prevRows = JSON.parse(currentFile.description || []);
			const pantoneIdx = prevRows.findIndex(
				(format) => format.name === 'Pantone',
			);
			if (pantoneIdx !== -1) {
				prevRows[pantoneIdx].value = pantoneColor;
			} else {
				prevRows.push({ name: 'Pantone', value: pantoneColor });
			}
			setCurrentFile((prev) => ({
				...prev,
				description: JSON.stringify(prevRows),
			}));
		}
	}, [pantoneColor]);

	return (
		<Grid
			container
			direction='column'
			gap={2}
			sx={{ border: 'unset !important' }}
		>
			<Box
				sx={{
					position: 'relative',
					border: `1px solid ${palette.background.grey}`,
					height: 160,
					borderRadius: '16px',
					background: '#' + colorConvert(color, 'rgb', 'hex'),
				}}
			></Box>
			<ColorPickerMenu
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				setColor={setColor}
				color={color}
			/>

			{!isEditing && (
				<Box
					sx={{
						position: 'relative',
						'& input': {
							border: 'none',
							borderBottom: '1px solid',
							minHeight: '48px',
							fontSize: '18px',
							outline: 'none !important',
						},
					}}
				>
					<HexColorInput
						prefixed
						color={colorConvert(color, 'rgb', 'hex')}
						onChange={(color) => {
							setColor(colorConvert(color, 'hex', 'rgb'));
						}}
						disabled={isEditing}
					/>
					<Grid
						position={'absolute'}
						height={32}
						width={32}
						container
						justifyContent={'center'}
						alignItems={'center'}
						borderRadius={'8px'}
						my={'auto'}
						zIndex={1}
						sx={{
							// background: '#' + colorConvert(color, 'rgb', 'hex'),
							right: '7px',
							border: `1px solid ${palette.background.grey}`,
							top: '50%',
							transform: ' translateY(-42%)',
							pointerEvents: isEditing ? 'none' : 'all',
						}}
						onClick={handleClick}
					>
						<ColorizeOutlinedIcon
							sx={{ mixBlendMode: 'difference' }}
						/>
					</Grid>
				</Box>
			)}
			<Table>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row?.name}>
							<TableCell
								component='th'
								scope='row'
								sx={{ padding: '6px' }}
							>
								{row?.name}
							</TableCell>
							<TableCell
								style={{ width: 160 }}
								align='right'
								sx={{
									padding: '6px',
									'& .MuiGrid-root': {
										paddingBottom: 'unset !important',
									},
								}}
							>
								{allowEdit ? (
									<ContentEditable
										type='text'
										canEdit={true}
										value={
											customContent[row?.name] ??
											row?.value
										}
										setValue={({ target: { value } }) => {
											setCustomContent((prev) => ({
												...prev,
												[`${row?.name}`]: value,
											}));
										}}
									/>
								) : (
									customContent[row?.name] ?? row?.value
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{!isEditing && (
				<Typography
					variant='small'
					fontSize={14}
					alignSelf={'end'}
					sx={{
						cursor: 'pointer',
						marginTop: '-1vmin',
						textDecoration: 'underline',
					}}
					onClick={() => {
						setAllowEdit(!allowEdit);
					}}
				>
					{!allowEdit ? 'Edit' : 'Done'}
				</Typography>
			)}
			<ContentEditable
				type='text'
				label='Name'
				canEdit={true}
				value={currentFile?.name}
				setValue={({ target: { value } }) => {
					setCurrentFile((prev) => ({ ...prev, name: value }));
				}}
			/>
			{
				<ContentEditable
					type='text'
					label='Pantone Code'
					canEdit={true}
					value={pantoneColor}
					setValue={({ target: { value } }) => {
						setPantoneColor(value);
					}}
				/>
			}
		</Grid>
	);
};

export default ColorPickerCustom;
