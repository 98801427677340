import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const NoResults = ({ userType, button, fontVariant = "h6" }) => {
  return (
    <Grid
      container
      direction={"column"}
      width={"100%"}
      mt={4}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant={fontVariant} fontWeight={300} textAlign={"center"}>
        It seems that there are no {userType} in your system yet.
        <br />
        Start by adding {userType} to manage them.
      </Typography>
      {button}
    </Grid>
  );
};
NoResults.propTypes = {
  button: PropTypes.oneOf(PropTypes.func, PropTypes.object),
  userType: PropTypes.string,
  fontVariant: PropTypes.string,
};
export default NoResults;
