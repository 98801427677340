import { Box, Grid, Typography } from '@mui/material';
import LogoWhite from '../../assets/images/logo-white.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<Box
			width={'100vw'}
			zIndex={2}
			component={'footer'}
			sx={{
				background: '#000',
				color: '#FFFFFF',
			}}
			py={2.5}
		>
			<Grid
				container
				justifyContent={'space-between'}
				alignItems={'center'}
				sx={{
					width: 'min(1600px, 95%)',
					marginInline: 'auto',
				}}
				wrap='nowrap'
			>
				<Grid container gap={2} flex={1}>
					<img src={LogoWhite} alt='Brandboss' />
					<Typography
						fontWeight={300}
						fontSize={13}
						component={'a'}
						href='https://www.shakedesign.co.il/'
						color={'#fff'}
						sx={{ textDecoration: 'unset' }}
					>
						By SHAKE DESIGN
					</Typography>
				</Grid>
				<Grid item alignItems={'end'}>
					<Typography
						fontWeight={300}
						fontSize={13}
						display={'flex'}
						gap={1}
					>
						<Link
							to={'/terms-and-conditions'}
							style={{ color: 'white' }}
						>
							Terms and privacy
						</Link>
						| All Rights Reserved © BrandBoss.site
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Footer;
