import styled from '@emotion/styled';
import palette from './palette';
import {
	Box,
	Card,
	Dialog,
	Drawer,
	Grid,
	ImageListItem,
	ListItem,
	darken,
} from '@mui/material';

export const centeredFlex = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

export const generalBox = {
	backgroundColor: palette.background.paper,
	borderRadius: '25px',
	padding: '1vmin',
};

export const circleBox = {
	...generalBox,
	...centeredFlex,
	aspectRatio: 1,
	borderRadius: '100%',
};

export const CustomAdminDrawer = styled(Drawer)(({ theme: { palette } }) => ({
	'& .MuiDrawer-paper': {
		height: '98%',
		marginBlock: 'auto',
		bottom: 0,
		right: '1vh',
		borderRadius: '20px',
		padding: '3vmin',
		gap: '1rem',
		maxWidth: '27vw',
	},
	'& .MuiGrid-root': {
		'&:not(:last-of-type)': {
			borderBottom: `1px solid ${palette.background.grey}`,
			paddingBottom: '1rem',
		},
	},
	'& .MuiInputBase-root': {
		border: `unset !important`,
	},
}));

export const CustomBasicDialog = styled(Dialog)(
	{
		shouldForwardProp: (prop) => prop !== 'maxWidth',
	},
	({ theme: { palette }, maxWidth }) => ({
		'& .MuiPaper-root': {
			boxShadow: 'none',
			borderRadius: '32px',
			padding: '1.5rem',
			overflowY: 'unset',
			width: maxWidth !== 600 ? '100%' : 'fit-content',
			maxWidth,
		},
	}),
);

export const StyledBrandCard = styled(Card)(({ theme: { palette } }) => ({
	width: '100%',
	boxShadow: 'none',
	borderRadius: '32px',
	position: 'relative',

	'& .MuiCardMedia-root': {
		position: 'relative',
		height: '160px',

		'& .MuiSvgIcon-root': {
			color: palette.background.paper,
			cursor: 'pointer',
			position: 'absolute',
			right: '4%',
			left: 'unset',
			top: '7%',
			zIndex: 1,
			'& path': {
				filter: `drop-shadow( 4px 4px 3px rgba(0, 0, 0, .7))`,
			},
		},
		'& .MuiAvatar-root': {
			position: 'absolute',
			left: '50%',
			bottom: '-50%',
			transform: 'translate(-50%,-50%)',
			border: `2px solid ${palette.background.grey}`,
		},
	},

	'& .MuiCardContent-root': {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		padding: '2rem',
		marginBlockStart: '0.5rem',
		'& .MuiLink-root': {
			color: palette.text.link,
			// textDecoration: 'none',

			// '& .MuiBox-root': {
			// 	// borderRadius: '100vw !important',
			// 	paddingInline: '1rem',
			// 	border: '1px solid',
			// 	borderColor: palette.primary.light,
			// 	transition: 'all 0.25s ease',
			// 	'&:hover': {
			// 		backgroundColor: palette.primary.light,
			// 	},
			// },
		},
	},
}));

export const StyledAssetCard = styled(ImageListItem)(
	{
		shouldForwardProp: (prop) =>
			prop !== 'bulkActions' ||
			prop !== 'isInList' ||
			prop !== 'needsUpload',
	},
	({ theme: { palette }, bulkActions, isInList, needsUpload }) => ({
		height: '150px !important',
		width: '150px ',
		borderRadius: '12px',

		position: 'relative',
		overflow: 'hidden',
		boxShadow: '0 2px 2px #80808063',
		margin: '8px',
		transition: 'all 0.25s ease',
		outline: needsUpload
			? `2px solid ${palette.warning.main}`
			: isInList
			? `2px solid ${palette.text.secondary}`
			: '2px solid transparent',
		'&:hover': {
			outline: ` 2px solid ${
				bulkActions ? palette.text.secondary : palette.primary.main
			}`,
		},
	}),
);
export const StyledFolderCard = styled(Grid)(
	{
		shouldForwardProp: (prop) => prop !== 'pendingDelete',
	},
	({ theme: { palette }, pendingDelete }) => ({
		background: darken(palette.background.grey, 0.05),
		width: 'fit-content',
		borderRadius: '8px',
		padding: '8px 16px',
		minWidth: '170px',
		cursor: 'pointer',
		border: '1px solid transparent',
		transition: 'all 0.25s ease',
		pointerEvents: pendingDelete ? 'none' : 'all',
		opacity: pendingDelete ? 0.65 : 1,
		'&:hover': {
			borderColor: palette.secondary.main,
		},
	}),
);

export const CustomBrandBox = styled(ListItem)(
	{
		shouldForwardProp: (prop) => prop !== 'isActive',
	},
	({ theme: { palette }, isActive }) => ({
		gap: 1,
		flexDirection: 'column',
		textAlign: 'center',
		maxWidth: '100px',
		border: '1px solid',
		borderColor: isActive ? 'rgba(255, 207, 40, 0.4)' : 'transparent',
		borderRadius: '8px',
		'&:hover': {
			cursor: 'pointer',
			'& .MuiListItemAvatar-root': {
				transform: 'scale(1.12)',
			},
		},
	}),
);

export const CountBadge = styled(Box)(({ theme: { palette }, isActive }) => ({
	paddingBlock: '2px',
	paddingInline: '8px',
	fontSize: 10,
	background: '#F5F5F5',
	borderRadius: '100vw',
	color: '#00000085',
}));
