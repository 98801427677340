import { Grid, Paper } from '@mui/material';
import DashboardSearch from '../Inputs/DashboardSearch';
import FoldersTree from '../Lists/FoldersTree';
import GeneralButton from '../Inputs/GeneralButton';
import { useFileContext } from '../../contexts/FilesContext';
import useGetFolders from '../../api/hooks/files/useGetFolders';
import { useEffect, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

const FolderSystem = ({ setIsFolderEdit }) => {
	const { folders, setFolders, filteredFolders } = useFileContext();

	const { data, isError, isLoading } = useGetFolders();
	const [foldersFilter, setFoldersFilter] = useState('');
	const { isOver, setNodeRef } = useDroppable({
		id: 'droppable',
	});
	const style = {
		color: isOver ? 'green' : undefined,
	};

	useEffect(() => {
		if (data !== null) setFolders(data?.data);
	}, [data]);

	return (
		<Paper
			elevation={0}
			sx={{
				mt: 3,
				p: 4,
				width: '100%',
				maxHeight: '70vh',
				borderRadius: '32px',
			}}
		>
			<Grid
				container
				sx={{
					height: '98%',
				}}
				justifyContent={'space-between'}
				direction={'column'}
				wrap='no-wrap'
			>
				<DashboardSearch
					id={'folder_search'}
					placeholder='Search in folders ... '
					searchAction={({ target: { value } }) => {
						setFoldersFilter(value);
					}}
				/>
				<div
					style={{
						...style,
						maxHeight: '75%',
						overflow: 'scroll',
						marginTop: '12px',
					}}
					ref={setNodeRef}
				>
					<FoldersTree filter={foldersFilter} folders={folders} />
				</div>
				<GeneralButton
					mt={'auto'}
					onClick={() => {
						setIsFolderEdit(true);
					}}
				>
					Add Folder
				</GeneralButton>
			</Grid>
		</Paper>
	);
};
FolderSystem.propTypes = {
	setIsFolderEdit: PropTypes.func,
};
export default FolderSystem;
