import { Stack, Typography, Link } from '@mui/material';
import CustomInput from '../Inputs/CustomInput';
import GeneralButton from '../Inputs/GeneralButton';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/logo.png';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import useUserLogin from '../../api/hooks/users/useUserLogin';
import { FORM_STEPS, useLogin } from '../../contexts/LoginContext';
import { useEffect } from 'react';

const StepLogin = ({ userCred, setUserCred }) => {
	const { handleAlert } = useAlert();
	const { setCurrentStep, email, setEmail, isEmailValid, setIsEmailValid } =
		useLogin();

	const successfulLogin = ({ data }) => {
		setUserCred((prev) => ({
			...prev,
			email: data.email,
		}));
		setEmail(data.email);
		setIsEmailValid(true);
		setCurrentStep(FORM_STEPS.AUTH_CODE);
	};

	const { handleLogin } = useUserLogin(successfulLogin);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!userCred.email)
			return handleAlert(
				ALERT_STATUS.ERROR,
				'Please enter a valid email address.',
			);
		if (!userCred.password)
			return handleAlert(ALERT_STATUS.ERROR, 'Please enter password.');

		handleLogin(userCred);
	};

	useEffect(() => {
		if (!email || !isEmailValid) return;
		setCurrentStep(FORM_STEPS.AUTH_CODE);
	}, [email, isEmailValid]);

	return (
		<>
			<img src={Logo} alt='Brand boss' />
			<Typography variant='h1'>Sign in to Brand Boss</Typography>
			{/* <Typography
				variant='p'
				mb={'auto !important'}
				align='center'
				fontWeight={500}
			>
				Lorem ipsum dolor sit, amet consectetur adipisicing elit.Lorem
				ipsum dolor sit.
			</Typography> */}
			<form onSubmit={handleSubmit} style={{ marginTop: 'auto' }}>
				<CustomInput
					inputType='email'
					label='Email address'
					name='email'
					value={userCred.email}
					action={({ target: { value } }) => {
						setUserCred((prev) => ({
							...prev,
							email: value,
						}));
					}}
				/>
				<CustomInput
					label='Password'
					inputType='password'
					mt={2}
					name='password'
					value={userCred.password}
					action={({ target: { value } }) => {
						setUserCred((prev) => ({
							...prev,
							password: value,
						}));
					}}
				/>
				<GeneralButton mt={'24px'} type='submit'>
					Sign In
				</GeneralButton>
			</form>
			<Stack
				direction={'row'}
				justifyContent={'end'}
				spacing={'24px'}
				mt={'auto !important'}
				width={'100%'}
			>
				<Link
					onClick={() => {
						//query for updating renewpass and sending it in email to client
						//then going to forgot pass auth
						setCurrentStep(FORM_STEPS.FORGOT_PASS);
					}}
					component='button'
				>
					Forgot your password?
				</Link>
				<Link
					onClick={() => {
						setCurrentStep(FORM_STEPS.HELP);
					}}
					component='button'
				>
					Can’t log in?
				</Link>
			</Stack>
		</>
	);
};

StepLogin.propTypes = {
	userCred: PropTypes.object.isRequired,
	setUserCred: PropTypes.func.isRequired,
};
export default StepLogin;
