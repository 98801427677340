import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { createAsset } from '../../files';

const useCreateAsset = (handleSuccess) => {
	const { handleAlert } = useAlert();

	const { mutateAsync: handleCreateAsset, isPending } = useMutation({
		mutationFn: (data) => createAsset(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error.response.data.error);
		},
	});

	return { handleCreateAsset, isPending };
};

export default useCreateAsset;
