import { red, blue, green, orange } from '@mui/material/colors';
import { darken, lighten } from '@mui/system';

const baseTextColor = '#4A4A4A';
// const baseBackgroundColor = '#FBF6EE';
const baseBackgroundColor = 'rgb(255,245,238)';
const basePrimaryColor = 'rgba(213, 198, 173, 1)';
const grey = 'rgb(190,190,190)';
const lightGrey = 'rgb(248, 250, 252)';

const palette = {
	background: {
		default: lighten(baseBackgroundColor, 0.4),
		paper: lighten(baseBackgroundColor, 1),
		light: darken(baseBackgroundColor, 0.05),
		grey: lighten(grey, 0.7),
		brandBook: lightGrey,
	},
	text: {
		primary: baseTextColor,
		secondary: darken(baseTextColor, 1),
		link: basePrimaryColor,
	},
	primary: {
		main: basePrimaryColor,
		light: lighten(basePrimaryColor, 0.5),

		dark: darken(basePrimaryColor, 0.05),
		contrastText: darken(baseTextColor, 1),
	},
	secondary: {
		main: lighten(baseTextColor, 0.5),
	},
	error: {
		main: red[500],
		light: red[200],
		dark: red[900],
	},
	success: {
		main: green[500],
		light: green[200],
		dark: green[900],
		veryLight: green[50],
	},
	info: {
		main: blue[500],
		light: blue[200],
		dark: blue[900],
		veryLight: blue[50],
	},
	warning: {
		main: orange[500],
		light: orange[200],
		dark: orange[900],
	},
};

export default palette;
