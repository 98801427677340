import PropTypes from 'prop-types';
import { useFileContext } from '../../contexts/FilesContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import {
	Grid,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/globals';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { ASSET_TYPES } from '../../utils/enums';

const CustomListItem = ({ folder, level = 0, hasChildren }) => {
	const { navigateToFolder, currentFolder } = useFileContext();

	return (
		<Grid
			container
			alignItems={'center'}
			onClick={() => {
				navigateToFolder(folder?.id);
			}}
			pl={level * 3}
		>
			<ListItemButton
				sx={{
					gap: 2,
					pl: 4,
					bgcolor:
						currentFolder?.id === folder?.id
							? 'primary.light'
							: 'transparent',
				}}
			>
				<ListItemIcon sx={{ minWidth: 'unset' }}>
					<FolderOutlinedIcon />
				</ListItemIcon>

				<ListItemText
					primary={capitalizeFirstLetter(folder?.name)}
					sx={{
						textTransform: 'capitalize',
					}}
				/>
				{folder?.Asset.some((a) => !a.dbxPath) &&
					folder?.assetType !== ASSET_TYPES.COLOR && (
						<WarningAmberOutlinedIcon color={'warning'} />
					)}
				{hasChildren && (
					<ListItemIcon sx={{ minWidth: 'unset' }}>
						<KeyboardArrowDownIcon />
					</ListItemIcon>
				)}
			</ListItemButton>
		</Grid>
	);
};

CustomListItem.propTypes = {
	folder: PropTypes.object,
	level: PropTypes.number,
	hasChildren: PropTypes.bool,
};

export default CustomListItem;
