import Footer from '../components/NavBars/Footer';
import { ReactComponent as LogoWhite } from '../assets/images/logo-white.svg';
import { ReactComponent as Clip } from '../assets/images/clip.svg';
import {
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import jsonData from '../assets/legal.json';
import { useEffect, useRef, useState } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { useNavigate } from 'react-router-dom';


const Legal = () => {
	const sectionRefs = useRef({});
	const scrollToEl = useRef(null);

	const scrollContainerRef = useRef(null);
	const navigate = useNavigate();
	const [activeSection, setActiveSection] = useState(null);


	const scrollToSection = (link) => {
		const section = sectionRefs.current[link];
		if (section) {
			section.scrollIntoView({ behavior: 'smooth' });
			setActiveSection(link);
		}
	};

	const isInViewport = (element) => {
		if (!element) return false;

		const rect = element.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <=
				(window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <=
				(window.innerWidth || document.documentElement.clientWidth)
		);
	};

	const handleScroll = () => {
		for (let key in sectionRefs.current) {
			const section = sectionRefs.current[key];
			console.log(section);
			if (isInViewport(section)) {
				setActiveSection(key);

				break;
			}
		}
	};

	useEffect(() => {
		const container = scrollContainerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
			return () => container.removeEventListener('scroll', handleScroll);
		}
	}, []);

	return (
		<>
			<Box
				sx={{
					height: '100vh',
					overflow: 'hidden',
					background: '#fff',
				}}
			>
				<Box>
					<Box
						sx={{ background: '#000' }}
						display={'flex'}
						justifyContent={'center'}
						py={4}
					>
						<LogoWhite style={{ height: 18, width: 'auto' }} />
					</Box>
					<Box
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}
						sx={{
							paddingInline: '10vmin 20vmin',
							border: '0.5px solid #999999',
						}}
						py={'10px'}
					>
						<Typography fontWeight={500}>
							LEGAL &gt; Standard Terms of Service
						</Typography>
						<Button
							component='a'
							variant={'outlined'}
							py={'10px'}
							disableRipple
							sx={{ borderColor: 'black' }}
							href={'/assets/legal-terms.pdf'}
							download={'legal-terms.pdf'}
						>
							<Clip style={{ marginInlineEnd: '2vmin' }} />{' '}
							Download as PDF
						</Button>
					</Box>
				</Box>

				<Grid
					ref={scrollContainerRef}
					container
					sx={{
						overflowY: 'scroll',
						position: 'relative',
						height: '85vh',
					}}
				>
					<Grid
						item
						xs={2.1}
						sx={{
							borderRight: '1px solid #84848454',
							position: 'sticky',
							top: 0,
							height: '100%',
						}}
						flexDirection={'column'}
					>
						{/* add if was logged in and in brandbook */}
						<Typography
							fontWeight={500}
							display={'flex'}
							alignItems={'center'}
							gap={2}
							py={2}
							sx={{
								paddingInlineStart: '6vmin',
								borderBottom: '0.5px solid #999999',
							}}
							mb={'1vmin'}

							onClick={() => {
								navigate(-1);
							}}

						>
							<ArrowBackOutlinedIcon />
							Back to Overview
						</Typography>
						<Stack
							direction={'column'}
							component={'ul'}
							sx={{
								listStyle: 'none',
								padding: 0,
								margin: 0,
								paddingInlineStart: '6vmin',
							}}
						>
							{jsonData.map((data, index) => (
								<Typography
									key={index}
									component={'li'}
									onClick={() => scrollToSection(data.link)}
									sx={{
										padding: '3vmin 2vmin 0.5vmin',
										borderBottom: '0.5px solid #D9D9D9',
										cursor: 'pointer',
										'&:hover': {
											background: 'rgba(0, 0, 0, 0.04)',
										},
									}}
									fontSize={14}
									color={'#1E1E1E'}
									fontWeight={
										activeSection === data.link
											? '600'
											: '400'
									}
								>
									{`${index + 1}. 
									${data.heading}`}
								</Typography>
							))}
						</Stack>
					</Grid>
					<Grid item xs={9.9}>
						<Stack
							direction={'column'}
							spacing={'2vmin'}
							width={'min(820px, 95%)'}
							marginInlineStart={'13vmin'}
							my={9}
						>
							<Typography
								variant='h5'
								fontWeight={500}
								fontSize={20}
								color={'#000'}
								ref={scrollToEl}
								sx={{ scrollMarginBlockStart: '5vmin' }}
							>
								Terms of Use - Brand Boss Platform
							</Typography>
							{jsonData.map((section, index) => (
								<Stack
									key={section.link}
									id={section.link}
									ref={(el) =>
										(sectionRefs.current[section.link] = el)
									} // Attach ref
									direction={'column'}
									spacing={'2vmin'}
									color={'#1E1E1E'}
									sx={{ scrollMarginBlockStart: '5vmin' }}
								>
									<Typography
										variant='h6'
										fontWeight={600}
										fontSize={16}
									>
										{`${index + 1}. 
									${section.heading}`}
									</Typography>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '2vmin',
										}}
										dangerouslySetInnerHTML={{
											__html: section.content,
										}}
									/>
								</Stack>
							))}
							<Grid
								container
								alignItems={'center'}
								gap={1}
								width={'fit-content'}
								sx={{ cursor: 'pointer' }}
								onClick={() => {
									scrollToEl.current.scrollIntoView({
										behavior: 'smooth',
									});
								}}
							>
								<IconButton
									disableRipple
									sx={{ border: '1px solid #4a4a4a' }}
								>
									<ArrowUpwardIcon />
								</IconButton>
								<Typography fontSize={24}>
									Back to top
								</Typography>
							</Grid>
						</Stack>
					</Grid>
				</Grid>
			</Box>
			<Footer />
		</>
	);
};

export default Legal;
