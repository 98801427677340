import {
	Button,
	CircularProgress,
	FormControlLabel,
	Grid,
	IconButton,
	ImageList,
	Paper,
	Snackbar,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import GeneralButton from '../Inputs/GeneralButton';
import DashboardSearch from '../Inputs/DashboardSearch';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import BreadcrumbsFolders from '../Lists/BreadcrumbsFolders';
import { useFileContext } from '../../contexts/FilesContext';
import AssetCard from '../Cards/AssetCard';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../utils/globals';
import palette from '../../styles/palette';
import useDeleteAssets from '../../api/hooks/files/useDeleteAssets';
import useGetCategories from '../../api/hooks/files/useGetCategories';
import CategoriesFilter from '../Lists/CategoriesFilter';
import { ASSET_TYPES } from '../../utils/enums';
import { useIsMutating } from '@tanstack/react-query';
import FolderCard from '../Cards/FolderCard';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import useDeleteFolder from '../../api/hooks/files/useDeleteFolder';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const FilesDisplay = ({
	setIsDrawerOpen,
	setIsMultipleUpload,
	setIsFolderEdit,
}) => {
	const {
		brand,
		files,
		currentFolder,
		createAsset,
		setFiles,
		assetType,
		folders,
		deleteFolder,
	} = useFileContext();
	const [bulkActions, setBulkActions] = useState(false);
	const [hideHiddenFiles, setHideHiddenFiles] = useState(true);
	const [pendingDelete, setPendingDelete] = useState(null);
	const [selected, setSelected] = useState([]);
	const [assetsFilter, setAssetsFilter] = useState('');
	const { data: categories } = useGetCategories();
	const [currentCategories, setCurrentCategories] = useState([]);
	const [categoriesToFilter, setCategoriesToFilter] = useState([]);
	const [subFolders, setSubFolders] = useState(currentFolder?.children || []);
	const PENDING_DURATION = 1000 * 30;
	const isMutating = useIsMutating();

	const handleAfterDelete = (data) => {
		if (data.data.count === selected.length) {
			setFiles((prev) => [
				...prev.filter(
					(file) => !selected.find((item) => item.id === file.id),
				),
			]);
			setSelected([]);
			setBulkActions(false);
		}
	};

	const { handleDeleteAssets } = useDeleteAssets(handleAfterDelete);

	const handleDeletedFolder = (data) => {
		deleteFolder(data?.data);
		setPendingDelete(null);
	};

	const { handleDeleteFolder } = useDeleteFolder(handleDeletedFolder);

	const handleCategoriesToDisplay = () => {
		if (!categories.data) return;
		const categoryIds = [
			...new Set(
				files?.flatMap((file) =>
					file?.CategoriesOnAssets?.map((cat) => cat?.categoryId),
				),
			),
		];
		setCurrentCategories(
			categories.data.filter((cat) => categoryIds?.includes(cat?.id)),
		);
	};

	useEffect(() => {
		files && categories && handleCategoriesToDisplay();
		currentFolder && setSubFolders(currentFolder?.children || []);
	}, [currentFolder, files, categories, folders]);

	return (
		<Paper
			elevation={0}
			sx={{
				mt: 3,
				width: '100%',
				borderRadius: '32px',
				p: 3,
				position: 'relative',
				height: '75vh',
			}}
		>
			{(currentFolder || brand) && (
				<>
					<Grid
						container
						justifyContent={'space-between'}
						gap={1}
						alignItems={'center'}
					>
						{brand && <BreadcrumbsFolders />}

						{currentFolder && (
							<DashboardSearch
								id={'file_search'}
								placeholder='Search in files ... '
								sx={{ marginInline: 'auto' }}
								searchAction={({ target: { value } }) => {
									setAssetsFilter(value);
								}}
							/>
						)}
						{currentCategories.length > 0 && (
							<CategoriesFilter
								currentCategories={currentCategories}
								setCategoriesToFilter={setCategoriesToFilter}
							/>
						)}
						{currentFolder && files?.length > 0 && (
							<Grid
								container
								alignItems={'center'}
								width={'fit-content'}
								mr={2}
							>
								<IconButton
									onClick={() => {
										setBulkActions(!bulkActions);
										setSelected([]);
									}}
								>
									{bulkActions ? (
										<CheckBoxIcon
											sx={{
												color: palette.text.secondary,
											}}
										/>
									) : (
										<CheckBoxOutlineBlankIcon
											sx={{
												color: palette.text.secondary,
											}}
										/>
									)}
								</IconButton>
								<Typography
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										setBulkActions(!bulkActions);
										setSelected([]);
									}}
								>
									Select All
								</Typography>
							</Grid>
						)}
						{assetType !== ASSET_TYPES.COLOR && currentFolder && (
							<GeneralButton
								ml={'auto'}
								icon={<FileUploadOutlinedIcon />}
								onClick={() => {
									setIsMultipleUpload(true);
								}}
								variant='outlined'
								sx={{ minHeight: 44 }}
							>
								Multiple Upload
							</GeneralButton>
						)}
						{currentFolder && (
							<GeneralButton
								ml={'auto'}
								icon={<FileUploadOutlinedIcon />}
								onClick={() => {
									createAsset();
									setIsDrawerOpen(true);
								}}
							>
								{currentFolder?.name
									? `Add to ${capitalizeFirstLetter(
											currentFolder?.name,
									  )}`
									: 'Add assets'}
							</GeneralButton>
						)}
					</Grid>

					<Grid
						container
						direction={'column'}
						gap={3}
						mt={2}
						wrap={'nowrap'}
						overflow={'auto'}
						alignItems={files?.length ? 'start' : 'center'}
						sx={{
							bgcolor: 'background.grey',
							height: '88%',
							borderRadius: '16px',
							paddingInline: '16px',
						}}
					>
						{brand && !currentFolder && (
							<Grid container mt={2} gap={1}>
								<Typography fontWeight={500}>
									Folders
								</Typography>
								<Grid
									container
									sx={{ height: 'fit-content' }}
									gap={3}
								>
									{folders
										.find(
											(folder) =>
												folder?.brand?.id === brand?.id,
										)
										?.folders?.map((folder) => (
											<FolderCard
												setIsFolderEdit={
													setIsFolderEdit
												}
												setPendingDelete={
													setPendingDelete
												}
												pendingDelete={pendingDelete}
												folder={folder}
												key={folder?.id}
											/>
										))}
								</Grid>
							</Grid>
						)}
						{currentFolder && subFolders?.length > 0 && (
							<Grid container mt={2} gap={1}>
								<Typography fontWeight={500}>
									Folders
								</Typography>
								<Grid
									container
									sx={{ height: 'fit-content' }}
									gap={3}
								>
									{subFolders.map((sub) => (
										<FolderCard
											setIsFolderEdit={setIsFolderEdit}
											setPendingDelete={setPendingDelete}
											pendingDelete={pendingDelete}
											folder={sub}
											key={sub?.id}
										/>
									))}
								</Grid>
							</Grid>
						)}
						{files?.length > 0 && (
							<Grid container mt={2} gap={0.5}>
								<Typography
									fontWeight={500}
									display={'flex'}
									justifyContent={'space-between'}
									alignItems={'center'}
									width={'100%'}
								>
									Files
									<FormControlLabel
										control={
											<Switch
												checked={hideHiddenFiles}
												onChange={(e) => {
													setHideHiddenFiles(
														e.target.checked,
													);
												}}
											/>
										}
										label='Hide hidden assets'
									/>
								</Typography>
								<ImageList
									cols={7}
									sx={{ height: 'fit-content' }}
								>
									{files?.length > 0 &&
										files
											.filter((file) =>
												hideHiddenFiles
													? !file.hiddenFile
													: true,
											)
											.filter((file) =>
												file?.name
													.toLowerCase()
													.includes(assetsFilter),
											)
											.filter((file) => {
												if (
													categoriesToFilter.length ===
													0
												)
													return true;
												return file.CategoriesOnAssets.find(
													(cat) =>
														categoriesToFilter?.includes(
															cat?.categoryId,
														),
												);
											})
											.map((file) => (
												<AssetCard
													key={file.id}
													asset={file}
													bulkActions={bulkActions}
													setSelected={setSelected}
													isInList={selected.find(
														(s) => s.id === file.id,
													)}
												/>
											))}
								</ImageList>
							</Grid>
						)}

						{currentFolder &&
							subFolders?.length === 0 &&
							files?.length === 0 && (
								<Typography mt={4} textAlign={'center'}>
									No assets exist in this folder.
								</Typography>
							)}
					</Grid>
				</>
			)}

			{!currentFolder && !brand && (
				<Typography variant='h6' textAlign={'center'} mt={6}>
					Select a folder to start uploading
				</Typography>
			)}
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={bulkActions}
				onClose={() => {}}
				message={selected?.length + ' files selected.'}
				action={
					<>
						<Button
							size='small'
							color='primary'
							onClick={() => {
								setBulkActions(false);
								setSelected([]);
							}}
						>
							Cancel
						</Button>
						<Button
							color='primary'
							size='small'
							onClick={() => {
								handleDeleteAssets({ assets: selected });
							}}
						>
							{isMutating ? (
								<CircularProgress size={16} />
							) : (
								'Delete'
							)}
						</Button>
					</>
				}
				sx={{
					bottom: '29px !important',
					position: 'absolute',
					'& .MuiPaper-root': {
						background: palette.primary.main,
						boxShadow: 'unset',
					},
				}}
			/>
			<Snackbar
				open={Boolean(pendingDelete)}
				autoHideDuration={PENDING_DURATION}
				onClose={() => {
					if (pendingDelete) {
						handleDeleteFolder({ folderId: pendingDelete });
					}
				}}
				message='You are deleting a folder and all its content.'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				action={
					<>
						<Button
							size='small'
							color='primary'
							onClick={() => {
								setPendingDelete(null);
							}}
						>
							Undo
						</Button>
						<IconButton
							onClick={() => {
								handleDeleteFolder({
									folderId: pendingDelete,
								});
							}}
						>
							<CloseOutlinedIcon color='text.dark' />
						</IconButton>
					</>
				}
				sx={{
					'& .MuiPaper-root': {
						background: palette.info.light,
						boxShadow: 'unset',
						color: palette.text.primary,
					},
				}}
			/>
		</Paper>
	);
};
FilesDisplay.propTypes = {
	setIsDrawerOpen: PropTypes.func.isRequired,
	setIsMultipleUpload: PropTypes.func.isRequired,
	setIsFolderEdit: PropTypes.func.isRequired,
};
export default FilesDisplay;
