import { Box, lighten } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import palette from '../../styles/palette';

const MultiUploader = ({ onDropAction }) => {
	const onDrop = (acceptedFiles) => {
		onDropAction(acceptedFiles);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});
	return (
		<Box
			{...getRootProps()}
			sx={{
				padding: '2vmin 4vmin',
				border: `2px dashed ${palette.background.grey}`,
				background: lighten(palette.background.grey, 0.7),
				color: palette.text.primary,
				textAlign: 'center',
			}}
		>
			<input {...getInputProps()} />
			{isDragActive ? (
				<p>Drop the files here ...</p>
			) : (
				<p>Drag and drop some files here, or click to select files</p>
			)}
		</Box>
	);
};
MultiUploader.propTypes = {
	onDropAction: PropTypes.func,
};
export default MultiUploader;
